const ConvMedida = ({ unidad }) => {
    switch (unidad) {
        case 't':
            return 'Tonelada';
        case 'kg':
            return 'Kilogramos';
        case 'g':
            return 'Gramos';
        case 'tc':
            return 'Tonelada corta';
        case 'tl':
            return 'Tonelada Larga';
        case 'lb':
            return 'Libras';
        case 'oz':
            return 'Onzas';
        case 'p':
            return 'Palé o Paleta';
        case 'con':
            return 'Container o Contenedor';
        case 'q':
            return 'Quintal';
        default:
            return 'Unidad no reconocida';
    }
}
export default ConvMedida;