// Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

function Report_technician_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);
    const { pagFlotilla, userID } = useUser();

    const [filters, setFilters] = useState({
        numero: "",
        folio: "",
        titulo: "",
        creador: "",
        vehiculo: "",
        fechaCreacion: "",
        estatus: "",
        fechaCreacion2: "",
        estatus2: ""
    });

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value
        });
    };

    const filteredDocuments = documentss.filter(doc => {
        return (
            doc.folio.includes(filters.folio) &&
            doc.titulo.includes(filters.titulo) &&
            doc.usuario.includes(filters.creador) &&
            doc.vehiculo.includes(filters.vehiculo) &&
            doc.fecha_generadaA.includes(filters.fechaCreacion) &&
            doc.estatusA.includes(filters.estatus) &&
            doc.fecha_generadaB.includes(filters.fechaCreacion2) &&
            doc.estatusB.includes(filters.estatus2)
        );
    });


    const loadData = () => {
        fetch(Api + "reportes.php?consultarest=" + userID)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setDocumentss(responseData.data);
            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
    }, []);

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Reportes")) {

        if (!loadedData) {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora del estado actual</h2>
                    <div className="card">
                        <div className="card-header">
                            {existsInPagFlotilla(pagFlotilla, 1, "Reportes") && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/tecnico/crear"}>
                                    Agrega un nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes del estado actual</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "numero")} placeholder='Filtro de numero' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "folio")} placeholder='Filtro de folio' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "creador")} placeholder='Filtro de creador' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "vehiculo")} placeholder='Filtro vehicular' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus")} placeholder='Filtro de estatus' /></th>
                                            <th></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion2")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus2")} placeholder='Filtro de estatus' /></th>
                                        </tr>
                                        <tr>
                                            <th>Número</th>
                                            <th>Folio</th>
                                            <th>Creador</th>
                                            <th>Vehículo</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Ida</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Vuelta</th>
                                        </tr>
                                    </thead>
                                    {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (
                                        <tbody>
                                            {filteredDocuments.map((documentss, index) => (
                                                <tr key={documentss.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{documentss.folio}</td>
                                                    <td>{documentss.usuario}</td>
                                                    <td>{documentss.vehiculo}</td>
                                                    <td>{documentss.fecha_generadaA}</td>
                                                    <td>{documentss.estatusA}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info text-white" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'A'}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                    <td>{documentss.fecha_generadaB}</td>
                                                    <td>{documentss.estatusB}</td>
                                                    <td>
                                                        {documentss.folio + 'B' === documentss.id_reporteB ? (
                                                            <div className="btn-group w-100" role="group" aria-label="">
                                                                <Link className="btn btn-info w-100" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'B'}>Consultar</Link>
                                                            </div>
                                                        ) : (

                                                            <div className="btn-group w-100" role="group" aria-label="">
                                                                <Link className="btn btn-success text-white w-100" to={"/flotilla/reporte/tecnico/crear/" + documentss.folio + 'B'}>
                                                                    Agregar
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora del estado actual</h2>
                    <div className="card">
                        <div className="card-header">
                            {existsInPagFlotilla(pagFlotilla, 1, "Reportes") && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/tecnico/crear"}>
                                    Agrega un nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes del estado actual</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "numero")} placeholder='Filtro de numero' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "folio")} placeholder='Filtro de folio' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "creador")} placeholder='Filtro de creador' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "vehiculo")} placeholder='Filtro vehicular' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus")} placeholder='Filtro de estatus' /></th>
                                            <th></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion2")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus2")} placeholder='Filtro de estatus' /></th>
                                        </tr>
                                        <tr>
                                            <th>Número</th>
                                            <th>Folio</th>
                                            <th>Creador</th>
                                            <th>Vehículo</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Ida</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Vuelta</th>
                                        </tr>
                                    </thead>
                                    {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (
                                        <tbody>
                                            {filteredDocuments.map((documentss, index) => (
                                                <tr key={documentss.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{documentss.folio}</td>
                                                    <td>{documentss.usuario}</td>
                                                    <td>{documentss.vehiculo}</td>
                                                    <td>{documentss.fecha_generadaA}</td>
                                                    <td>{documentss.estatusA}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info text-white" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'A'}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                    <td>{documentss.fecha_generadaB}</td>
                                                    <td>{documentss.estatusB}</td>
                                                    <td>
                                                        {documentss.folio + 'B' === documentss.id_reporteB ? (
                                                            <div className="btn-group w-100" role="group" aria-label="">
                                                                <Link className="btn btn-info w-100" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'B'}>Consultar</Link>
                                                            </div>
                                                        ) : (

                                                            <div className="btn-group w-100" role="group" aria-label="">
                                                                <Link className="btn btn-success text-white w-100" to={"/flotilla/reporte/tecnico/crear/" + documentss.folio + 'B'}>
                                                                    Agregar
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu Reporte Actual del Estado del Vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Report_technician_flotilla;