import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Take the comission Jesus
// I'm really appreciate programming with my old Computer Eduardo
// Yeah, you'd buy a new computer can help you more Sandoval
// I'll want to do it, but my pockets are empty :'( Montoya
/* I'm Mexican, my first language is Spanish, so y realized many comments in Spanish
   please, you should have to translate the comments in your native language.
*/
reportWebVitals();
