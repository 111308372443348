//Librarys
import React from 'react'
import { Carousel } from 'react-bootstrap'

//images
import firstImg from '@/images/lowboy.jpg';
import SecondImg from '@/images/rack.jpg';
import ThirdImg from '@/images/race.jpg';
import FourtImg from '@/images/update.jpg';
import { Link } from 'react-router-dom';

// Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

const Body_central = () => {
    const { pagFlotilla } = useUser();

    return (
        <>
            <Carousel>
                {existsInPagFlotilla(pagFlotilla, 3, "Flotilla") &&
                    <Carousel.Item interval={3000}>
                        <Link to={'/flotilla'}>
                            <img
                                className="d-block w-100 carouselIMG"
                                src={firstImg}
                                alt="Ingrese a la página flotilla vehicular"
                            />
                            <Carousel.Caption>
                                <h3>Flotilla vehicular</h3>
                                <p>A través de la aplicación web “Flotilla vehicular” podrá usted monitorear,
                                    crear reportes, exportar información a formatos PDF y EXCEL y demás opciones.
                                </p>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                }
                {existsInPagFlotilla(pagFlotilla, 3, "Inventario") &&
                    <Carousel.Item interval={3000} >
                        <Link to={'/'} className='disabled'>
                            <img
                                className="d-block w-100 carouselIMG"
                                src={SecondImg}
                                alt="Ingrese a la página inventario"
                            />
                            <Carousel.Caption>
                                <h3>Inventario</h3>
                                <p>Desea conocer su stock y el estado de cada objeto, fácilmente lo puede hacer
                                    mediante está aplicación, reciba todas las bondades a través de su aparato
                                    inteligente desde la palma de su mano o desde su escritorio.
                                </p>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                }
                <Carousel.Item interval={3000}>
                    <Link to={'https://racecarreteras.odoo.com'}>
                        <img
                            className="d-block w-100 carouselIMG"
                            src={ThirdImg}
                            alt="Ingresa a la página oficial de Race Carreteras S.A. de C.V."
                        />
                        <Carousel.Caption>
                            <h3>Race Carreteras S.A. de C.V.</h3>
                            <p>Conoce más acerca de nosotros tan solo dando clic en la imagen. Podrás conocer
                                nuestra misión y objetivo, además de poder contactar con nosotros. ¡Anímate!
                            </p>
                        </Carousel.Caption>
                    </Link>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <img
                        className="d-block w-100 carouselIMG"
                        src={FourtImg}
                        alt="Próximas actualizaciones"
                    />
                    <Carousel.Caption>
                        <h3>Próximas actualizaciones</h3>
                        <p>Mantente al tanto de las próximas actualizaciones que estaremos realizando con el
                            fin de poder ofrecerte más servicios
                        </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default Body_central