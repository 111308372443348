// Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';
import AnyTable from '@/components/Flotilla/components/ProcUser/AnyTable';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

// Images
import img_usuario from '@images/usuario.png';

function List_users_flotilla() {

    //Variables externas
    const { pagFlotilla, userID, jerarquia } = useUser();
    //Variables internas
    const [loadedData, setLoadedData] = useState(null);
    const [users, setUsers] = useState([null]);
    const titulo = ['Foto', 'Nombre', 'Empresa', 'Acciones']; // Define los títulos de las columnas aquí
    const columnFunctions = [
        // Puedes añadir más funciones si tienes más columnas
        (item) => <td className='text-center'>
            {item.img ?
                (
                    <img
                        src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/img/" + item.img}
                        className="img-fluid rounded}"
                        alt=""
                        style={{ width: 'auto', height: '5vh' }}
                    />
                ) : (
                    <img
                        src={img_usuario}
                        className="img-fluid rounded}"
                        alt=""
                        style={{ width: 'auto', height: '5vh' }}
                    />
                )
            }
        </td>,
        (item) => <td>{item.usuario}</td>,
        (item) => <td>{item.emp_nombre}</td>,
        (item) => (
            <td>
                {item.pase != 'Root' && (<div className="btn-group" role="group" aria-label="">
                    {(existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios") || item.pase == 'Root') &&
                        (<Link className="btn btn-warning" to={"/flotilla/editar-usuario/" + item.id_usuario}>Editar privilegios</Link>)
                    }
                    {
                        (existsInPagFlotilla(pagFlotilla, 4, "Control de usuarios") || item.pase == 'Root') &&
                        (<button
                            className="btn btn-danger"
                            onClick={(id) => eliminateData(item.id_usuario)}
                        >Eliminar usuario</button>)
                    }
                </div>)}
            </td>
        ),
    ];

    //Metodos
    const eliminateData = (id) => {
        // Preguntar al usuario si realmente desea eliminar el vehículo
        const confirmar = window.confirm("¿Realmente desea eliminar al usuario?");
        if (confirmar) {
            // Si el usuario hizo clic en "Aceptar"
            console.log(id);

            fetch(Api + "/usuarios.php?borrar=" + id)
                .then(response => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.success === 1) {
                        console.log(responseData);
                        window.alert('Usuario eliminado.');
                        window.location.reload();
                    } else {
                        window.alert('Error.\nEl usuario no ha podido ser eliminado.');
                    }

                })
                .catch(console.log)

        } else {
            // Si el usuario hizo clic en "Cancelar" (No se hace nada)
        }
    }

    const loadData = () => {
        fetch(Api + "usuarios.php?listct")
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setUsers(responseData);
                setLoadedData(true);
            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
    }, []);


    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 1, "Control de usuarios") || jerarquia == 'Root' ||
        existsInPagFlotilla(pagFlotilla, 2, "Control de usuarios") ||
        existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios") ||
        existsInPagFlotilla(pagFlotilla, 4, "Control de usuarios")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla />
                    <Loading_papu />
                </div>
            );
        } else {
            return (
                <div className='container'>
                    <Nav_bar_flotilla />
                    <h2>Control de usuarios</h2>
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success text-white" to={"/flotilla/crear-nuevo-usuario"}>
                                Agrega un nuevo usuario
                            </Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista de usuarios</h4>
                            {/* Table of all users */}
                            {loadedData && (<AnyTable lt={titulo} lc={users} col={columnFunctions} />)}
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Control de usuarios</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default List_users_flotilla;