//Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import InputInfoUser from '@/components/Flotilla/components/ProcUser/InputInfoUser';
import InputPrivUser from '@/components/Flotilla/components/ProcUser/InputPrivUser';
import InputDirec from '@/components/Flotilla/components/PerfilUser/InputDirec';
import InputImage from '@/components/Flotilla/components/PerfilUser/InputImage';
import ListDocument from '@/components/Flotilla/components/PerfilUser/ListDocument';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

function User_edit_flotilla() {
    //Variables externas
    const { userID } = useUser();

    //Variables internas
    const [menu, setMenu] = useState('Imagen');
    const [loadedData, setLoadedData] = useState([false, false, false]);
    const [estado, setEstado] = useState(true);
    const [info, setInfo] = useState([]);
    const [priv, setPriv] = useState([]);
    const [datos, setDatos] = useState([]);
    const [datos2, setDatos2] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [prueba, setPrueba] = useState(null);

    const loadData = () => {
        fetch(Api + "usuarios.php/?listemp")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setInfo(responseData.primera_consulta);
                setPriv(responseData.segunda_consulta);
                setLoadedData(prevLoadedData => ([true, prevLoadedData[1], prevLoadedData[2]]));
            })
            .catch(console.log)
    };

    const loadData2 = () => {
        fetch(Api + "usuarios.php/?doc_import=" + userID)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setDatos(responseData.tercera_consulta[0]);
                setDatos2(responseData.cuarta_consulta);
                setLoadedData(prevLoadedData => ([prevLoadedData[0], true, prevLoadedData[2]]));
            })
            .catch(console.log)
    };

    const loadData3 = () => {
        fetch(Api + "usuarios.php?id_usuario=" + userID + "&perfil=" + menu)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setPrueba(responseData.perfil);
                setLoadedData(prevLoadedData => ([prevLoadedData[0], prevLoadedData[1], true]));
            })
            .catch(console.log);
    };

    useEffect(() => {
        const fetchData = async () => {
            await loadData();
            await loadData2();
            await loadData3();
            setInputValue(null);
        };
        fetchData();
    }, [menu]);

    // Metodos CallBack
    // Manejador de cambio para InputInfoUser
    const handleInfoChange = (value) => {
        setInputValue(value);
    };

    // Advertencia
    const forgottenData = (value) => {
        // Preguntar al usuario si desea cambiar de ventana pero los cambios seran perdidos por no actualizar
        if (inputValue) {
            const confirmar = window.confirm("Los cambios no han sido actualizados, ¿Desea cambiar de ventana?");
            if (confirmar) {
                // Si el usuario hizo clic en "Aceptar"
                setMenu(value);
                
            } else {
                // Si el usuario hizo clic en "Cancelar" (No se hace nada)
            }
        } else {
            setMenu(value);
        }
    }

    // Componente NavTab
    const NavTab = ({ titulo }) => (
        <li className="nav-item">
            <a
                className={menu === titulo ? "nav-link active" : "nav-link"}
                onClick={() => forgottenData(titulo)} // Asegurándose de que 'titulo' se pasa correctamente
            >
                {titulo}
            </a>
        </li>
    );

    const sendData = (e) => {
        // Checkers
        e.preventDefault();
        console.log(inputValue); // Ahora inputValue contiene el valor del input del componente hijo
    
        if (inputValue) {
            //Variable que contendrá toda la información
            var formData = new FormData();
            //Información que contendra la verificación de que componente enviará sus datos
            formData.append("componente", menu);
            formData.append("userID", userID);
            switch (menu) {
                case 'Imagen':
                    // Imagen
                    formData.append("imagen", inputValue);
                    break;
                case 'Información general':
                    // Información
                    if (inputValue.empresa == 'otros') {
                        if (inputValue.nombreEmpresa) {
                            // Valor del cuadro de texto nombre empresa - Selección 'Escriba la empresa'
                            formData.append("empresanueva", "0");
                            formData.append("empresa", inputValue.nombreEmpresa);
                        } else {
                            window.alert('Error.\nNo se ha podido cargar la empresa');
                            return;
                        }
                    } else {
                        // Valor al seleccionar alguna empresa existente
                        formData.append("empresanueva", "1");
                        formData.append("empresa", inputValue.empresa);
                    }
                    formData.append("apat", inputValue.apellidoPaterno);
                    formData.append("amat", inputValue.apellidoMaterno);
                    formData.append("apodo", inputValue.apodo);
                    formData.append("depa", inputValue.depa);
                    formData.append("email", inputValue.email);
                    formData.append("nombre", inputValue.nombre);
                    formData.append("pass", inputValue.pass);
                    formData.append("pue", inputValue.pue);
                    formData.append("oriEmpresa", datos.id_empresa);
                    formData.append("oriPuesto", datos.id_pue);
                    formData.append("oriDepa", datos.id_depa);
                    break;
                case 'Dirección':
                    // Dirección
                    if (datos.id_emptelefono) {
                        if (inputValue.nombreEmpresa) {
                            // Valor del cuadro de texto nombre empresa - Selección 'Escriba la empresa'
                            formData.append("valtel", datos.id_emptelefono);
                            formData.append("tel", inputValue.tel);
                        } else {
                            window.alert('Error.\nNo se ha podido cargar la empresa');
                            return;
                        }
                    } else {
                        // Valor al seleccionar alguna empresa existente
                        formData.append("valtel", 'p');
                        formData.append("tel", inputValue.tel);
                    }                    
                    formData.append("calle", inputValue.calle); 
                    formData.append("cod", inputValue.cod);  
                    formData.append("next", inputValue.num_exterior);
                    formData.append("nint", inputValue.num_interior);
                    formData.append("estado", inputValue.estado);
                    formData.append("mun", inputValue.municipio);
                    formData.append("pais", inputValue.pais);
                    formData.append("oritel", prueba[0].tel);
                    formData.append("id_direccion", prueba[0].id_direccion);
                    break;
                default:
                    window.alert('Error.\nVentana no disponible.');
                    return;
            }
            // Proceso Fetch
            //Actualización de datos API REST
            fetch(Api + "/usuarios.php?actperfil=1", {
                method: "POST",
                body: formData,
            }).then(response => response.json())
                .then((responseDataArray) => {

                    console.log(responseDataArray);

                    let successMessages = '';
                    let errorMessages = '';

                    // Iterar sobre cada objeto en la respuesta
                    responseDataArray.forEach((responseItem) => {
                        if (responseItem.success == 1) {
                            successMessages += responseItem.message + '\n';
                        } else if (responseItem.success == 0) {
                            errorMessages += responseItem.message + '\n';
                        }
                    });

                    // Mostrar mensajes
                    if (successMessages) {
                        window.alert(successMessages);
                        setInputValue(null);
                        window.location.reload();
                    }
                    if (errorMessages) {
                        window.alert('Lo lamentamos, no se ha podido realizar algunas operaciones:\n' + errorMessages);
                    }
                }).catch(console.log);
        } else {
            window.alert('No se ha podido actualizar su información.\nPor favor, modifique algún campo.');
        }
    };
    

    if (loadedData[0] && loadedData[1] && loadedData[2]) {
        return (
            <div className='container-fluid'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <h2>Perfil de usuario</h2>
                <div className="card">
                    <form onSubmit={sendData}>
                        <div className="card-header">
                            <ul className="nav nav-tabs nav-stacked">
                                <NavTab titulo="Imagen" />
                                <NavTab titulo="Información general" />
                                <NavTab titulo="Dirección" />
                                <NavTab titulo="Privilegios" />
                                <NavTab titulo="Documentos personales" />
                            </ul>
                        </div>
                        {
                            // Información del usuario y empresa
                            menu === 'Información general' && (
                                <InputInfoUser disable={estado} onInputChange={handleInfoChange} data={info} view={datos} account={true}/>
                            )
                        }
                        {
                            // Información referente a los privilegios del usuario
                            menu === 'Privilegios' && (
                                <InputPrivUser disable={true} onInputChange={handleInfoChange} data={priv} view={datos2} />
                            )
                        }
                        {
                            // Información referente a los dirección del usuario
                            menu === 'Dirección' && (
                                <InputDirec disable={estado} onInputChange={handleInfoChange} view={prueba[0]} />
                            )
                        }
                        {
                            // Información referente a la imagen del perfil del usuario
                            menu === 'Imagen' && (
                                <InputImage disable={estado} onInputChange={handleInfoChange} view={prueba[0]} />
                            )
                        }
                        {
                            // Información referente a los privilegios del usuario
                            menu === 'Documentos personales' && (
                                <ListDocument view={prueba} />
                            )
                        }
                        <div className="card-footer text-muted text-center">
                            {estado ? (
                                <div className="btn-group" role="group" aria-label="">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEstado(false);
                                        }}
                                        type="button"
                                        className="btn btn-info">
                                        Modificar información del usuario
                                    </button>
                                    <Link className="btn btn-danger text-white" to="/flotilla">Regresar</Link>
                                </div>
                            ) : (
                                <div className="btn-group" role="group" aria-label="">
                                    <button type="submit"
                                        className="btn btn-warning">
                                        Actualizar información del usuario
                                    </button>
                                    <button
                                        onClick={() => setEstado(true)}
                                        type="button"
                                        className="btn btn-danger">
                                        Cancelar
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <Loading_papu></Loading_papu>
            </div>
        );
    }
}

export default User_edit_flotilla;