//librarys
import React, { useEffect, useState } from 'react';

//Styles
import '@/styles/LoginStyles.css';

//Images
import logo from '@/images/racecarreteras logo.png';
import fondo from '@/images/login.jpeg';

//Services
import Api from "@/services/api";
import { useUser } from '@/services/UserContext';
const LoginUser = () => {
    //Variables externas
    const {
        userID,
        setUserID,
        userName,
        setUserName,
        jerarquia,
        setJerarquia
    } = useUser();
    //Variables internas
    const [variable, setVariable] = useState({
        user: '',
        pass: ''
    });

    useEffect(() => {
        // Redirige si todas las variables de estado necesarias están establecidas
        if (userID && userName && jerarquia) {
            window.location.href = "/";
        }
    }, [userID, userName, jerarquia]);


    const handleChange = (e) => {
        // Actualizar solo el campo correspondiente en el objeto 'variable'
        const newVariable = { ...variable, [e.target.name]: e.target.value };
        setVariable(newVariable);
        //onInputChange(newVariable); // Enviar el objeto actualizado al padre
    };

    const sendData = (e) => {

        e.preventDefault();
        console.log(variable); // Ahora inputValue contiene el valor del input del componente hijo

        // Checkers
        if (variable) {
            // Verificación del contenido de nuestra lista
            if (!variable.pass) {
                window.alert("Contraseña vacia.\n Complete el campo requerido");
                return;
            }
            if (!variable.user) {
                window.alert("Contraseña vacia.\n Complete el campo requerido");
                return;
            }

            //Variable que contendrá toda la información
            var formData = new FormData();
            formData.append("contra", variable.pass);
            formData.append("usuario", variable.user);

            //Proceso Fetch
            fetch(Api + "/usuarios.php?user=1", {
                method: "POST",
                body: formData,
            })
            .then(response => response.json())
                .then((responseDataArray) => {

                    console.log(responseDataArray);

                    let successMessages = '';
                    let errorMessages = '';
                    let dataInfo = null;

                    // Iterar sobre cada objeto en la respuesta
                    responseDataArray.forEach((responseItem) => {
                        if (responseItem.success == 1) {
                            successMessages += responseItem.message + '\n';
                        } else if (responseItem.success == 0) {
                            errorMessages += responseItem.message + '\n';
                        } else if (responseItem.success == 2) {
                            dataInfo = responseItem.message;
                        }
                    });

                    // Mostrar mensajes
                    if (successMessages) {
                        window.alert(successMessages);
                        // Variables de sesión

                    }
                    if (errorMessages) {
                        window.alert('Lo lamentamos, no se ha podido realizar algunas operaciones:\n' + errorMessages);
                    }
                    if (dataInfo) {
                        setUserID(dataInfo.id_usuario);
                        setUserName(dataInfo.usuario);
                        setJerarquia(dataInfo.jerarquia);
                    }
                })
                .catch(error => {
                    // Manejar los errores de la red y los errores que lancemos manualmente
                    window.alert('Se ha sucitado un error dentro del Servidor.\nIntentelo de nuevo porfavor');
                    // window.alert(error.message);
                });
        }



    };

    if (!userID) {

        const year = new Date().getFullYear();
        return (
            <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
                <div className="card card0 border-0">
                    <div className="row d-flex">
                        <div className="col-lg-6">
                            <div className="card1 pb-5">
                                <div className="row">
                                    <img src={logo} style={{ height: '20vh', width: 'auto', paddingLeft: "10vh", paddingTop: "5vh" }} />
                                </div>
                                <div className="row px-3 justify-content-center mt-4 mb-5 border-line w-100 h-auto">
                                    <img src={fondo} className="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={sendData}>
                                <div className="card2 card border-0 px-4 py-5">

                                    <div className="row px-3 mb-4">
                                        <div className="line"></div>
                                        <small className="or text-center">INICIO DE SESIÓN</small>
                                        <div className="line"></div>
                                    </div>
                                    <div className="row px-3">
                                        <label className="mb-1"><h6 className="mb-0 text-sm">Correo electrónico y usuario</h6></label>
                                        <input
                                            className="mb-4" type="text"
                                            name="user" id="user"
                                            value={variable.user}
                                            onChange={handleChange}
                                            placeholder="Ingresa tu usuario o correo electrónico." />
                                    </div>
                                    <div className="row px-3">
                                        <label className="mb-1"><h6 className="mb-0 text-sm">Contraseña</h6></label>
                                        <input
                                            type="password"
                                            name="pass" id="pass"
                                            value={variable.pass}
                                            onChange={handleChange}
                                            placeholder="Ingrese su contraseña." />
                                    </div>
                                    <div className="row px-3 mb-4">
                                        <a href="#" className="ml-auto mb-0 text-sm">¿Olvidó su contraseña?</a>
                                    </div>
                                    <div className="row mb-3 px-3">
                                        <button type="submit" className="btn btn-blue text-center" >Ingresar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="bg-blue py-4">
                        <div className="row px-3">
                            <small className="ml-4 ml-sm-5 mb-2">Copyright &copy; {year}. TODOS LOS DERECHOS RESERVADOS.</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        window.location.href = "/";
    }
}

export default LoginUser;