import React, { useEffect } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import carro from "@/images/coche.png"; // Asumiendo que es la imagen por defecto
import Api from "@/services/api";
import { Link } from 'react-router-dom';

const Table_users = ({ lista }) => {
    useEffect(() => {
        console.log(lista);
    }, []);

    return (
        <Container>
            {lista.map((item, index) => (
                <Link
                    to={"/flotilla/reporte/tecnico/" + item.pagina}
                    key={index}
                    className="text-decoration-none"
                    style={{ color: 'inherit' }}
                >
                    <Card className="mb-3 card-hover">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={4} lg={3} className="mb-3 mb-md-0">
                                    <Card.Img
                                        src={Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT_EVI + item.imagen || carro}
                                        className="img-fluid w-100"
                                        alt="Vehículo"
                                    />
                                </Col>
                                <Col xs={12} md={8} lg={9}>
                                    <Card.Title>{item.vehiculo}</Card.Title>
                                    <Card.Text>
                                        <strong>Usuario(s): </strong>{item.usuarios}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Lugare(s): </strong>{item.lugares}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Fecha: </strong>{item.fecha}
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Link>
            ))}
        </Container>
    );
}

export default Table_users;
