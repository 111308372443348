import React from 'react';

import Car_Bar_Flotilla from '../components/Car_Bar_Flotilla';
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';

class Calculator_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <div className='row'>
                    <div className='col'>
                        API Google mAPS
                    </div>
                    <div className='col'>

                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h3>Vehículo</h3>
                        <Car_Bar_Flotilla></Car_Bar_Flotilla>
                        <h3>Datos vehiculares</h3>
                    </div>
                </div>
                <div className='row row-cols-3'>

                    <div className='col'>
                        <h4>Marca</h4>
                        <p>Nissan</p>
                    </div>
                    <div className='col'>
                        <h4>Modelo</h4>
                        <p>Frontier</p>
                    </div>
                    <div className='col'>
                        <h4>Año</h4>
                        <p>2014</p>
                    </div>
                    <div className='col'>
                        <h4>Combustible actual</h4>
                        <p>30 l</p>
                    </div>
                    <div className='col'>
                        <h4>Promedio: kilómetros por litro</h4>
                        <p>11 Km</p>
                    </div>
                    <div className='col'>
                        <h4>Odómetro actual</h4>
                        <p>11,250 Km</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h3>Promedio de valores obtenidos por los cálculos</h3>
                    </div>
                </div>
                <div className='row row-cols-3'>
                    <div className='col'>
                        <h4>Odómetro</h4>
                        <p>11,257 Km</p>
                    </div>
                    <div className='col'>
                        <h4>Combustible a gastar</h4>
                        <p>0.7 l</p>
                    </div>
                    <div className='col'>
                        <h4>Combustible que necesita</h4>
                        <p>0 l</p>
                    </div>
                    <div className='col'>
                        <h4>Kilómetros a recorrer</h4>
                        <p>7 Km</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Calculator_flotilla;