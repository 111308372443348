import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from "date-fns/locale/es";


import Car_Bar_Flotilla from './Car_Bar_Flotilla';
import Type_calendar from './Type_Calendar';
registerLocale("es", es);

class Form_calendar extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    startDate: null,
    finalDate: null,
    // Otros estados para campos de formulario
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleFinalDateChange = (date) => {
    this.setState({ finalDate: date });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Lógica para enviar los datos del formulario
  };

  render() {
    const { startDate, finalDate } = this.state;

    return (
      <div className="form-group">
        <h3>Agrega un nuevo evento</h3>

        <form onSubmit={this.handleSubmit}>
          <label htmlFor="">Titulo evento</label>
          <input type="text" className="form-control" placeholder="Escribe un titulo" aria-describedby="helpId" />

          <label htmlFor="">Fecha de inicio</label>
          <DatePicker
            selected={startDate}
            onChange={this.handleStartDateChange}
            className="form-control"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Seleccione una fecha y hora"
            locale='es'
          />

          <label htmlFor="">Fecha de termino</label>
          <DatePicker
            selected={finalDate}
            onChange={this.handleFinalDateChange}
            className="form-control"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Seleccione una fecha y hora"
            locale='es'
          />
          <br></br>
          <label htmlFor="">Asunto del evento</label>
          <Type_calendar></Type_calendar>

          <label htmlFor="">Descripción del evento</label>
          <textarea className="form-control" rows="3" placeholder='Escribe una descripción o motivo del evento.' />

          <label htmlFor="">Vehículo</label>
          <Car_Bar_Flotilla />

          <button type="submit" className="btn btn-primary">
            Agregar evento
          </button>
        </form>
      </div>
    );
  }
}

export default Form_calendar;
