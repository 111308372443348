import React, { Component } from 'react';

class CentralText extends Component {
    

    render() {
        return (
                <div className="foo">
                    <span className="letter" data-letter="R">R</span>
                    <span className="letter" data-letter="A">A</span>
                    <span className="letter" data-letter="C">C</span>
                    <span className="letter" data-letter="E">E</span>
                    <br/>
                    <span className="letter" data-letter="C">C</span>
                    <span className="letter" data-letter="A">A</span>
                    <span className="letter" data-letter="R">R</span>
                    <span className="letter" data-letter="R">R</span>
                    <span className="letter" data-letter="E">E</span>
                    <span className="letter" data-letter="T">T</span>
                    <span className="letter" data-letter="E">E</span>
                    <span className="letter" data-letter="R">R</span>
                    <span className="letter" data-letter="A">A</span>
                    <span className="letter" data-letter="S">S</span>
                </div>
        );
    }
}

export default CentralText;
