//Librarys
import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";

//Components
import Api from "@/services/api";

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const Excel_fallas= ({ id, userID }) => {

    const [loadedData, setLoadedData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [primeraConsulta, setPrimeraConsulta] = useState(null);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [usuario, setUsuario] = useState([]);


    const obtenerFechaActual = () => {
        const fecha = new Date(); // obtiene la fecha actual
        const dia = String(fecha.getDate()).padStart(2, '0'); // obtiene el día y agrega un 0 al inicio si es menor que 10
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // obtiene el mes (0-11, por eso se suma 1) y agrega un 0 al inicio si es menor que 10
        const ano = fecha.getFullYear(); // obtiene el año
        return `${dia}/${mes}/${ano}`; // retorna en el formato deseado
    }

    const [fechaActual, setFechaActual] = useState(obtenerFechaActual());

    useEffect(() => {
        console.log(id);

        fetch(Api + "reportes.php?repconfio=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta);
                setSegundaConsulta(responseData.segunda_consulta);
            })
            .catch(console.log);
    }, []);

    const handleDownload = async () => {
        setLoading(true);
        const libro = XLSX.utils.book_new();

        // Agrega la primera consulta
        const hoja1 = XLSX.utils.json_to_sheet(primeraConsulta);
        XLSX.utils.book_append_sheet(libro, hoja1, "INFORMACIÓN");

        // Agrega la segunda consulta
        const hoja2 = XLSX.utils.json_to_sheet(segundaConsulta);
        XLSX.utils.book_append_sheet(libro, hoja2, "FALLAS, INCIDENCIAS");

        await new Promise(resolve => setTimeout(resolve, 1000)); // Espera 1 segundo

        XLSX.writeFile(libro, "Reporte fallas, incidencias u ocurrencias " + id + " " + fechaActual + ".xlsx");
        setLoading(false);
        window.alert('Archivo descargado');
    };


    if (!loadedData) {
        return (
            <p>Información exportándose a archivo XLSX.</p>
        );
    } else {
        return (
            <div>
                {loading ? (
                    <p>Descargando archivo...</p>
                ) : (
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={handleDownload}
                    >Descargar archivo
                    </button>
                )}
            </div>
        );
    }
};


export default Excel_fallas;
