//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

//Components
import AnyTable from '@/components/Flotilla/components/ProcUser/AnyTable';

//Services
import { useUser } from '@/services/UserContext';

const InputPrivUser = ({ disable, onInputChange, data, view }) => {
    // Variables externas
    const { userID } = useUser();
    // Variables internas
    const [loadedData, setLoadedData] = useState(false);
    const [selectedActions, setSelectedActions] = useState([]);
    const [updatedActions, setUpdatedActions] = useState([]);

    const titulo = ['id', 'Modulo o ventana', 'Insertar', 'Modificar', 'Consultar', 'Eliminar']; // Define los títulos de las columnas aquí
    const columnFunctions = [
        // Puedes añadir más funciones si tienes más columnas
        (item) => <td>{item.id_primodulos}</td>,
        (item) => <td>{item.pri_modulo}</td>,
        (item) => (
            <td>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input w-25"
                        type="checkbox"
                        role="switch"
                        {...(disable ? { checked: checkIfConditionMet(view, item.id_primodulos, '1') } : {})}
                        id={`insertar-${item.id_primodulos}`}
                        name={item.id_primodulos}
                        onChange={handleChange}
                        disabled={disable}
                    />
                </div>
            </td>
        ),
        (item) => (
            <td>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input w-25"
                        type="checkbox"
                        role="switch"
                        {...(disable ? { checked: checkIfConditionMet(view, item.id_primodulos, '2') } : {})}
                        id={`modificar-${item.id_primodulos}`}
                        name={item.id_primodulos}
                        onChange={handleChange}
                        disabled={disable}
                    />
                </div>
            </td>
        ),
        (item) => (
            <td>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input w-25"
                        type="checkbox"
                        role="switch"
                        {...(disable ? { checked: checkIfConditionMet(view, item.id_primodulos, '3') } : {})}
                        id={`consultar-${item.id_primodulos}`}
                        name={item.id_primodulos}
                        onChange={handleChange}
                        disabled={disable}
                    />
                </div>
            </td>
        ),
        (item) => (
            <td>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input w-25"
                        type="checkbox"
                        role="switch"
                        {...(disable ? { checked: checkIfConditionMet(view, item.id_primodulos, '4') } : {})}
                        id={`eliminar-${item.id_primodulos}`}
                        name={item.id_primodulos}
                        onChange={handleChange}
                        disabled={disable}
                    />
                </div>
            </td>
        ),
    ];

    /*const handleSwitchChange = (moduleId, actionType, isChecked) => {
        setSelectedActions((prevSelectedActions) => {
            const actionIdMap = { 'insertar': '1', 'modificar': '2', 'consultar': '3', 'eliminar': '4' };
            const actionId = actionIdMap[actionType];
            const estado = isChecked ? '1' : '0';

            // Encuentra si la acción ya está en la lista
            const actionIndex = prevSelectedActions.findIndex(
                (action) => action.id_modulo === moduleId && action.id_accion === actionId
            );

            let updatedActions;
            if (actionIndex > -1) {
                // Clona la lista de acciones para no mutar el estado directamente
                updatedActions = [...prevSelectedActions];
                // Actualiza el estado de la acción existente
                updatedActions[actionIndex] = {
                    ...updatedActions[actionIndex],
                    estado: estado,
                };
            } else {
                // Añade la nueva acción con el estado
                updatedActions = prevSelectedActions.concat({
                    id_modulo: moduleId,
                    id_accion: actionId,
                    id_usuario: userID,
                    estado: estado,
                });
            }

            // Imprime y envía los cambios
            console.log('Acciones actualizadas:', updatedActions);
            onInputChange(updatedActions);

            return updatedActions;
        });
    };
    */

    const handleSwitchChange = (moduleId, actionType, isChecked) => {
        const actionIdMap = { 'insertar': '1', 'modificar': '2', 'consultar': '3', 'eliminar': '4' };
        const actionId = actionIdMap[actionType];
        const estado = isChecked ? '1' : '0';
    
        setSelectedActions((prevSelectedActions) => {
            // Encuentra si la acción ya está en la lista
            const actionIndex = prevSelectedActions.findIndex(
                (action) => action.id_modulo === moduleId && action.id_accion === actionId
            );
    
            let newActions;
            if (actionIndex > -1) {
                // Clona la lista de acciones para no mutar el estado directamente
                newActions = [...prevSelectedActions];
                // Actualiza el estado de la acción existente
                newActions[actionIndex] = {
                    ...newActions[actionIndex],
                    estado: estado,
                };
            } else {
                // Añade la nueva acción con el estado
                newActions = prevSelectedActions.concat({
                    id_modulo: moduleId,
                    id_accion: actionId,
                    id_usuario: userID,
                    estado: estado,
                });
            }
    
            // Imprime y envía los cambios
            console.log('Acciones actualizadas:', newActions);
            onInputChange(newActions);
    
            return newActions;
        });
    };
    

    const handleChange = (e) => {
        // Se asume que el id del input tiene el formato "acción-id_del_módulo" (ej. "insertar-1")
        const [actionType, moduleId] = e.target.id.split('-');
        const isChecked = e.target.checked;

        handleSwitchChange(moduleId, actionType, isChecked);
    };

    const checkIfConditionMet = (data, moduleId, actionId) => {
        return data.some(item =>
            item.privilegios_modulos_id_primodulos === moduleId.toString() &&
            item.provilegios_provilegios_acciones_id_priacciones === actionId.toString()
        );
    };


    useEffect(() => {
        if (view) {
            // Transforma los datos de 'view' a la estructura que necesitas para 'updatedActions'
            const actionsFromView = view.map(v => ({
                id_modulo: v.privilegios_modulos_id_primodulos,
                id_accion: v.provilegios_provilegios_acciones_id_priacciones,
                id_usuario: v.provilegios_usuario_id_usuario,
                // Asumiendo que necesitas un campo 'estado', puedes definirlo aquí
                estado: '1' // o el valor que corresponda
            }));

            setUpdatedActions(actionsFromView);
            
            setLoadedData(true);

        } else {
            setLoadedData(true);
        }
    }, [view]); // Un array vacío como segundo argumento asegura que esto se ejecute solo al montar el componente


    return (
        <div className="card-body">
            <Row>
                <Col>
                    <AnyTable lt={titulo} lc={data} col={columnFunctions} />
                </Col>
            </Row>
        </div>
    );
};
export default InputPrivUser;