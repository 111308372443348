//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Collapse_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Collapse_bar_flotilla';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";

function Select_bar_flotilla() {

    const [vehicless, setVehicless] = useState([]);
    const [vahiclesViews, setVahiclesViews] = useState(false);
    const { pagFlotilla } = useUser();
    const [categoria, setCategoria] = useState(null);

    useEffect(() => {
        fetch(Api + "barra-navegacion.php")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setVehicless(responseData);
            })
            .catch(console.log)
    }, []);

    return (
        <Container fluid className='third-Bar' >
            <Row>
                <Col sm={2}>

                    <span className='bar-Category'>
                        Vehículo
                    </span>
                    <br />

                    {(existsInPagFlotilla(pagFlotilla, 1, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 2, "Vehiculo") ||
                        existsInPagFlotilla(pagFlotilla, 4, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) && (
                            <>
                                {vehicless.map((vehicle) => (
                                    <span
                                        className='bar-Subcategory super-Bar'
                                        onMouseOver={() => {
                                            setVahiclesViews(false);
                                            setVahiclesViews(true);
                                            setCategoria(vehicle.categoria);
                                        }}
                                        onClick={() => setCategoria(vehicle.categoria)}
                                        key={vehicle.categoria}
                                    >
                                        {vehicle.categoria}
                                        <br />
                                    </span>
                                ))}
                            </>
                        )}

                    <br />
                    {(existsInPagFlotilla(pagFlotilla, 1, "Vehiculo")) &&
                        <Link to={"/flotilla/procedimiento"} className='bar-Subcategory prcie'>
                            <span className='bar-Category bar-Subcategory prcie' >
                                Procedimiento
                            </span>
                        </Link>}
                </Col>
                <Col sm>
                    {!vahiclesViews && <Row>
                        <Row>

                            {(existsInPagFlotilla(pagFlotilla, 1, "Calendario") || existsInPagFlotilla(pagFlotilla, 3, "Calendario")) &&
                                <Col>
                                    <span className='bar-Category'>
                                        Calendario
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/calendario"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Consultar evento
                                        </span>
                                    </Link>
                                    <br />
                                    <Link to={"/Flotilla/calendario"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Agregar evento
                                        </span>
                                    </Link>
                                </Col>}

                            {(existsInPagFlotilla(pagFlotilla, 1, "Control de usuarios") || existsInPagFlotilla(pagFlotilla, 2, "Control de usuarios") ||
                                existsInPagFlotilla(pagFlotilla, 4, "Control de usuarios") || existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios")) &&
                                <Col>
                                    <span className='bar-Category' >
                                        Control de usuarios
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/control-de-usuarios"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Consultar usuario
                                        </span>
                                    </Link>
                                    <br />
                                    <Link to={"/flotilla/crear-nuevo-usuario"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Agregar nuevo usuario
                                        </span>
                                    </Link>
                                </Col>}
                            {(existsInPagFlotilla(pagFlotilla, 1, "Reportes") || existsInPagFlotilla(pagFlotilla, 2, "Reportes") ||
                                existsInPagFlotilla(pagFlotilla, 3, "Reportes")) &&
                                <Col>
                                    <span className='bar-Category'>
                                        Reporte
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/reporte"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Consultar reportes
                                        </span>
                                    </Link>
                                    <br />
                                </Col>}
                        </Row>
                        <Row>
                            <br></br>
                        </Row>
                        <Row>
                            {(existsInPagFlotilla(pagFlotilla, 1, "Graficos") || existsInPagFlotilla(pagFlotilla, 3, "Graficos")) &&
                                <Col>
                                    <span className='bar-Category'>
                                        Gráficos
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/graficos"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Generar gráfico
                                        </span>
                                    </Link>
                                    <br />
                                </Col>}
                            {/*(existsInPagFlotilla(pagFlotilla, 3, "Calculadora")) &&
                                <Col>
                                    <span className='bar-Category' >
                                        Calculadora
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/calculadora"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Distancia
                                        </span>
                                    </Link>
                                    <br />
                                    <Link to={"/flotilla/calculadora"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Combustible
                                        </span>
                                    </Link>
                                </Col>*/}
                            {(existsInPagFlotilla(pagFlotilla, 1, "Reportes") || existsInPagFlotilla(pagFlotilla, 2, "Reportes") ||
                                existsInPagFlotilla(pagFlotilla, 4, "Reportes") || existsInPagFlotilla(pagFlotilla, 3, "Reportes")) &&
                                <Col>
                                    <span className='bar-Category'>
                                        Bitacora de mantenimiento
                                    </span>
                                    <br />
                                    <Link to={"/flotilla/bitacora-de-mantenimiento"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Consultar bitacora
                                        </span>
                                    </Link>
                                    <br />
                                    <Link to={"/flotilla/reporte/mantenimiento"} className='bar-Subcategory'>
                                        <span className='bar-Subcategory'>
                                            Crear un nuevo reporte de mantenimiento
                                        </span>
                                    </Link>
                                </Col>
                            }
                        </Row>
                    </Row>}
                    <Container
                        onMouseOver={() => {setVahiclesViews(true)}}
                        onMouseOut={() => setVahiclesViews(false)}>
                        {vahiclesViews && (
                            <Collapse_bar_flotilla categoria={categoria} />)}
                    </Container>
                </Col>
            </Row>
        </Container>
    );

}

export default Select_bar_flotilla;