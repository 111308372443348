// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from "@/components/general/Loading_papu";
import PDF from '@/components/Flotilla/components/ReporteFallas/PDF_fallas';
import Excel from '@/components/Flotilla/components/ReporteFallas/Excel_fallas';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

// Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';
import { Link, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
function Fails_view_flotilla() {

    const [verPDF, setVerPDF] = React.useState(false);
    const [verExcel, setVerExcel] = React.useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState(null);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [estatusReporte, setEstatusReporte] = useState(null);
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    const [firma_ver, setFirmaVer] = useState(null);
    const [firma_vo, setFirmaVo] = useState(null);
    const sigCanvas = useRef({});
    const sigCanvas2 = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [isVisible, setVisible] = useState(true);

    const [usuario, setUsuario] = useState([]);


    // Función asincrona Firma verificado
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_verif" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVer(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirmaVer(null);
    };

    // Función asincrona Firma visto bueno
    const saveSignature2 = () => {
        if (!sigCanvas2.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas2.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_vo" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVo(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature2 = () => {
        sigCanvas2.current.clear();
        setFirmaVo(null);
    };

    useEffect(() => {
        //console.log(id);

        fetch(Api + "reportes.php?repconfio=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
            })
            .catch(console.log);

        fetch(Api + "usuarios.php/?listaid=" + userID)
            .then(response3 => response3.json())
            .then((responseData3) => {
                // console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)
    }, []);

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag }) => (
        <Row>
            <Col sm={2}><label htmlFor="">{tg}</label></Col>
            <Col sm={4}>
                <p className="card-text d-inline">
                    {primeraConsulta ? primeraConsulta[ag] : ""}
                </p>
            </Col>
            <Col sm={2}><label htmlFor=""></label></Col>
            <Col sm>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={primeraConsulta.condicion === tv}
                    />
                    <p className="card-text d-inline">
                        &nbsp;{tv}
                    </p>
                </div>
            </Col>
        </Row>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name1, name2, name3 }) => (
        <tr>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name1}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={segundaConsulta.some(item => item.ref_suceso_fallas === name1)}
                        />
                    </div>
                </Row>
            </td>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name2}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={segundaConsulta.some(item => item.ref_suceso_fallas === name2)}
                        />
                    </div>
                </Row>
            </td>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name3}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={segundaConsulta.some(item => item.ref_suceso_fallas === name3)}
                        />
                    </div>
                </Row>
            </td>
        </tr>
    );

    const sendData = (e) => {

        e.preventDefault();

        console.log(userID);
        console.log(primeraConsulta.folio);
        console.log(primeraConsulta.id);
        console.log(firma_ver);
        console.log(firma_vo);
        console.log(estatusReporte);


        if (
            userID &&
            primeraConsulta && primeraConsulta.folio &&
            primeraConsulta && primeraConsulta.id
        ) {
            // verificar parametros necesarios
            if (btnCheck) {
                // Firma Verificado
                if (firma_vo == null && firma_ver == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {
                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("id", primeraConsulta.id);
                    formData.append("folio", primeraConsulta.folio);
                    formData.append("firma_ver", firma_ver);
                    formData.append("firma_vo", firma_vo);

                    fetch(Api + "/reportes.php?firmareporfio=1", {
                        method: "POST",
                        body: formData,
                    }).then(response => response.json())

                        .then((responseData) => {
                            console.log(responseData);
                            if (responseData[0].success === 1) {
                                window.alert('Firma actualizada.');
                            } else {
                                window.alert('Su firma no ha podido ser actualizada');
                            }
                        }).catch(console.log);
                }
            } else if (
                (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null && primeraConsulta.estatus_reporte != 'Concluido'))
                ||
                (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null && primeraConsulta.estatus_reporte != 'Concluido'))
            ) {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
            // Estado del reporte
            if (primeraConsulta.id_vo === userID) {
                if (estatusReporte != null) {
                    if (estatusReporte != 'Pendiente') {
                        var formData2 = new FormData();
                        formData2.append("userID", userID);
                        formData2.append("id", primeraConsulta.id);
                        formData2.append("folio", primeraConsulta.folio);
                        formData2.append("estatus", estatusReporte);
                        /*
                            Termina por acá y despues sigue con PDF y EXCEL
                        */
                        fetch(Api + "/reportes.php?repostatus=1", {
                            method: "POST",
                            body: formData2,
                        }).then(response2 => response2.json())
                            .then((responseData2) => {
                                console.log(responseData2);
                                if (responseData2[0].success === 1) {
                                    window.alert('Estatus actualizado del reporte.');
                                } else {
                                    window.alert('El estatus del reporte o ha podido ser actualizado.');
                                }
                            }).catch(console.log);
                    }
                }
            }
            window.location.reload();
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
    };

    const { pagFlotilla, userID } = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];


    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }
    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        {CRUDUser[2] === 3 && (<div className="card">
                            <div className="card-header">
                                <h4>{primeraConsulta.vehiculo} | FOLIO: {primeraConsulta && primeraConsulta.folio ? primeraConsulta.folio : "Cargando..."}</h4>
                            </div>
                            <div className="card-body">
                                <Row>
                                    <Col sm>
                                        {primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido" &&
                                            (<div className="form-group">
                                                <label htmlFor="">Estatus del reporte</label>
                                                <select className={`custom-select form-control ${primeraConsulta.estatus_reporte == '' ? "is-invalid" : ""}`}
                                                    required onChange={e => setEstatusReporte(e.target.value)}
                                                >
                                                    <option value={null} >Seleccione el estado del reporte</option>
                                                    <optgroup label="Estado del reporte">
                                                        <option value="Concluido">Concluido</option>
                                                        <option value="Pendiente">Pendiente</option>
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    * Seleccione el estado del reporte
                                                </small>
                                            </div>)}
                                    </Col>
                                    <Col sm={4}>
                                        <div className="form-group">
                                            <label htmlFor="" >Folio:</label>
                                            <input type="text"
                                                className={`form-control ${primeraConsulta.folio == '' ? "is-invalid" : ""}`}
                                                value={primeraConsulta.folio}
                                                placeholder="Escriba el folio del documento"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="">Fecha de modificación</label>
                                            <p className="card-text">{primeraConsulta.Fecha_generada}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <h3>DATOS GENERALES</h3>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={2}><label htmlFor="">TRANSPORTE:</label></Col>
                                    <Col sm={4}>
                                        <div className="form-group d-flex align-items-center">
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Vehículo</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta &&
                                                        (primeraConsulta.transporte === "Compacto" || primeraConsulta.transporte === "Motocicleta"
                                                            || primeraConsulta.transporte === "Pick-Up" || primeraConsulta.transporte === "SUV"
                                                            || primeraConsulta.transporte === "Sedan" || primeraConsulta.transporte === "Vehículo ligero"
                                                            || primeraConsulta.transporte === "Pick-up")}
                                                />
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Maquinaria o vehículo pesado</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta &&
                                                        (primeraConsulta.transporte === "Vehículo pesado" || primeraConsulta.transporte === "Camión"
                                                            || primeraConsulta.transporte === "Furgoneta" || primeraConsulta.transporte === "Plataforma"
                                                            || primeraConsulta.transporte === "Remolque")}
                                                />
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">&nbsp;Otros</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta && (primeraConsulta.transporte === "Otros" || primeraConsulta.transporte === "otros")}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={2}><label htmlFor="">USUARIO:</label></Col>
                                    <Col>
                                        <p className="card-text d-inline">
                                            {primeraConsulta ? primeraConsulta.usuario : ""}</p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={2}><label htmlFor="">MODELO:</label></Col>
                                    <Col sm={4}>
                                        <p className="card-text d-inline">
                                            {primeraConsulta ? primeraConsulta.vehiculo : ""}
                                        </p>
                                    </Col>
                                    <Col sm={2}><label htmlFor="">TIPO DE CONDICIÓN:</label></Col>
                                    <Col sm>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked={primeraConsulta.condicion === 'Normal'}
                                            />
                                            <p className="card-text d-inline">
                                                &nbsp;Normal
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <DatosGV tg="PLACAS:" tv="Regular" ag="placas" />
                                <DatosGV tg="NIV:" tv="Medio Regular" ag="niv" />
                                <DatosGV tg="ODÓMETRO, KM:" tv="Mala" ag="odometro_actual" />
                                <DatosGV tg="NÚM, ECONÓMICO:" tv="Desastrozo" ag="neconomico" />
                                <br />
                                <Row>
                                    <div className="table-responsive">
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>TIPO DE FALLA, INCIDENTE U OCURRENCIA</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <InputCheck2 name1="Luces Rotas" name2="Choque" name3="Multa" />
                                                <InputCheck2 name1="Neumáticos Desinflados" name2="Transporte no Arranca" name3="Robo de Transporte" />
                                                <InputCheck2 name1="Batería Muerta" name2="Falla de Bomba de Aceite" name3="Mala Lubricación" />
                                                <InputCheck2 name1="Sobrecalentamiento" name2="Falla de Sistema Eléctrico" name3="Ruidos Extraños" />
                                                <InputCheck2 name1="Frenos Defectuosos" name2="Problemas con la Gasolina" name3="Falla de Dirección" />
                                                <InputCheck2 name1="Fugas" name2="Falla de Transmisión" name3="Otros" />
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div class="form-group">
                                                            <label htmlFor="">DESCRIPCIÓN</label>
                                                            <textarea
                                                                rows="3"
                                                                className={`form-control`}
                                                                value={primeraConsulta ? primeraConsulta.descripcion : ""}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <h5 className="card-title">EVIDENCIA DEL ESTADO DEL VEHÍCULO</h5>
                                    <img
                                        src={primeraConsulta.evidencia ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                            + "/evi/" + primeraConsulta.evidencia : ""}
                                        class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                                        alt=""
                                    />
                                </Row>
                                <br />
                                <Row>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Ejecutado:</label>
                                            <img
                                                src={primeraConsulta.firma_soli ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                                    + "/fir/" + primeraConsulta.firma_soli : ""}
                                                class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} w-100"
                                                style={{ height: "12vw" }}
                                                alt=""
                                            />
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.usuario}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Verificado:</label>
                                            {
                                                (primeraConsulta.id_verificado === userID && ((primeraConsulta.firma_verificada == null || primeraConsulta.firma_verificada == "") && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature}>Guardar Firma</button>
                                                                <button onClick={clearSignature}>Borrar Firma</button>
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={primeraConsulta.firma_verificada ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                                                    + "/fir/" + primeraConsulta.firma_verificada : ""}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_verificado}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div
                                            className="mb-3 vo"
                                        >
                                            <label for="formFile" className="form-label">Visto bueno</label>
                                            {
                                                (primeraConsulta.id_vo === userID && ((primeraConsulta.firma_vo == null || primeraConsulta.firma_vo == "") && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas2}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature2}>Guardar Firma</button>
                                                                <button onClick={clearSignature2}>Borrar Firma</button>
                                                            </div>
                                                        </>

                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={primeraConsulta.firma_vo ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                                                    + "/fir/" + primeraConsulta.firma_vo : ""}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_vo}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {verPDF ? (
                                        <PDFViewer style={{ width: "100%", height: "90vh" }}>
                                            <PDF id={id} user={"" + userID + ""} />
                                        </PDFViewer>
                                    ) : null}
                                </Row>
                            </div>
                            <div className="card-footer text-muted">
                                <Row>
                                    <Col>
                                        <h5>Acciones</h5>
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {
                                                (primeraConsulta.id_verificado === userID || primeraConsulta.id_vo === userID) &&
                                                    (primeraConsulta.estatus_reporte != 'Concluido') &&
                                                    (
                                                        (
                                                            (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null || primeraConsulta.firma_verificada == ""))
                                                            ||
                                                            (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null || primeraConsulta.firma_vo == ""))
                                                        ) ||
                                                        (primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido")
                                                    )
                                                    ? (
                                                        <button type="button"
                                                            className="btn btn-warning btn-block"
                                                            onClick={(e) => {
                                                                sendData(e); // Pasa el evento como argumento
                                                            }}
                                                        >Confirmar</button>
                                                    )
                                                    :
                                                    null // Cuando el lienzo aparece, no mostramos nada en lugar del botón
                                            }
                                            <Link
                                                className="btn btn-danger btn-block text-white"
                                                to={"/flotilla/reporte/fallas-incidencias-ocurrencias"}
                                            >Regresar</Link>
                                        </div>

                                    </Col>
                                    <Col>
                                        {isVisible && (<h5>Exportación</h5>)}
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {isVisible && (<button type="button"
                                                className="btn btn-primary btn-block"
                                                onClick={() => {
                                                    setVerPDF(!verPDF);
                                                }}
                                            >PDF</button>)}

                                            {isVisible && (
                                                verExcel ? (
                                                    <Excel id={id} />
                                                ) : null
                                            )}

                                            {isVisible && (
                                                !verExcel && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-block"
                                                        onClick={() => {
                                                            setVerExcel(!verExcel);
                                                        }}
                                                    >EXCEL</button>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>)}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte de fallas, incidencias u ocurrencias</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Fails_view_flotilla;