//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

//Services
import Api from "@/services/api";

//Components
import Loading_papu from "@/components/general/Loading_papu";

const InputDirec = ({ disable, onInputChange, view }) => {
    /*
        * disable: true = Visualización | false = Modificación
        * onInputChange: callBack - Información que enviá al componente padre
        * id: ID del usuario
    */
    // Inicializar 'variable' como un objeto con las claves para cada input
    const [mostrarCampoEmpresa, setMostrarCampoEmpresa] = useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [paesmu, setPaesmu] = useState([]);
    //const [view, setView] = useState(null);

    const [variable, setVariable] = useState({
        calle: '',
        num_interior: '',
        num_exterior: '',
        cod: '',
        tel: '',
        municipio: '',
        estado: '',
        pais: ''
    });

    //Preparación para la dirección
    const paisesUnicos = [...new Set(paesmu.map(item => item.pais))];
    const estadosPorPais = variable.pais ? paesmu.filter(item => item.pais === variable.pais) : [];
    const municipiosPorEstado = variable.estado ? paesmu.filter(item => item.estado === variable.estado) : [];

    // Filtramos los estados para que cada uno aparezca una sola vez
    const estadosUnicos = [...new Set(estadosPorPais.map(item => item.estado))];

    const handleChange = (e) => {
        // Actualizar solo el campo correspondiente en el objeto 'variable'
        const newVariable = { ...variable, [e.target.name]: e.target.value };
        setVariable(newVariable);
        onInputChange(newVariable); // Enviar el objeto actualizado al padre
        const { name, value } = e.target;

        if (name === 'empresa') {
            setMostrarCampoEmpresa(value === 'otros');
        }
        onInputChange(newVariable);
    };

    const paises = () => {
        return fetch(Api + "usuarios.php/?paisestadomuni")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setPaesmu(responseData);
            })
            .catch(console.log)
    };


    useEffect(() => {
        paises();
        if (view) {
            setVariable({
                calle: view.calle || '',
                num_interior: view.ninter || '',
                num_exterior: view.nexte || '',
                cod: view.zip || '',
                tel: view.tel || '',
                municipio: view.est_nombre_municipio || '',
                estado: view.est_nombre || '',
                pais: view.pai_nombre || ''
            });
    
            setLoadedData(true);
        }else {
            setLoadedData(true);
        }
    }, [view]);

    if (loadedData) {

        return (
            <div className="card-body">
                <Row>
                    <Col>
                        <label htmlFor={variable.nombre} >Dirección:</label>
                        <div className="input-group">
                            <span className="input-group-text w-25"></span>
                            <input
                                type="text"
                                className={`form-control w-25 ${!disable && (!variable.calle ? "is-invalid w-25" : "")}`}
                                value={variable.calle}
                                name="calle"
                                id="calle"
                                autoComplete='off'
                                required
                                placeholder="Calle"
                                minLength={1}
                                maxLength={45}
                                onChange={handleChange}
                                readOnly={disable}
                            />
                            <input
                                type="text"
                                className="form-control w-25"
                                value={variable.num_interior}
                                name="num_interior"
                                id="num_interior"
                                autoComplete='off'
                                required
                                placeholder="Numero interior"
                                minLength={1}
                                maxLength={4}
                                onChange={handleChange}
                                readOnly={disable}
                            />
                            <input
                                type="text"
                                className="form-control w-25"
                                value={variable.num_exterior}
                                name="num_exterior"
                                id="num_exterior"
                                autoComplete='off'
                                placeholder="Número exterior"
                                minLength={1}
                                maxLength={4}
                                onChange={handleChange}
                                readOnly={disable}
                            />
                        </div>
                        {!disable ? <small id="helpId" className="form-text text-muted">*Ingrese la dirección que reside</small> : <br />}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor={variable.tel} >Código postal: </label>
                            <input
                                type="text"
                                className={"form-control"}
                                value={variable.cod}
                                name="cod"
                                autoComplete='off'
                                id="cod"
                                placeholder="Código postal"
                                minLength={1}
                                maxLength={8}
                                onChange={handleChange}
                                readOnly={disable}
                            />
                            {!disable ? <small id="helpId" className="form-text text-muted">Código postal</small> : <br />}
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <label htmlFor={variable.tel} >Telefono móvil: </label>
                            <input
                                type="text"
                                className={`form-control ${!disable && (!variable.tel ? "is-invalid " : "")}`}
                                value={variable.tel}
                                name="tel"
                                autoComplete='off'
                                id="tel"
                                placeholder="Número de telefono"
                                minLength={1}
                                maxLength={10}
                                onChange={handleChange}
                                readOnly={disable}
                                required
                            />
                            {!disable ? <small id="helpId" className="form-text text-muted">Número de telefono</small> : <br />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="btn-group">
                            {variable.pais === "México" && variable.estado && (
                                <select
                                    className="btn btn-outline-secondary dropdown-toggle w-100"
                                    name='municipio'
                                    id='municipio'
                                    value={variable.municipio}
                                    onChange={handleChange}
                                >
                                    <option value="">Municipio o Delegación</option>
                                    {municipiosPorEstado.map((item, index) => (
                                        <option key={index} value={item.municipio}>
                                            {item.municipio}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {variable.pais && (
                                <select
                                    className="btn btn-outline-secondary dropdown-toggle w-100"
                                    value={variable.estado}
                                    name='estado'
                                    id='estado'
                                    required
                                    onChange={handleChange}
                                >
                                    <option value="">Estado o Provincia</option>
                                    {estadosUnicos.map((estadoActual, index) => (
                                        <option key={index} value={estadoActual}>
                                            {estadoActual}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <select
                                className="btn btn-outline-secondary dropdown-toggle w-100"
                                value={variable.pais}
                                name='pais'
                                id='pais'
                                required
                                onChange={handleChange}  // Agregar esta línea
                            >
                                <option value="">País</option>
                                {paisesUnicos.map((pais, index) => (
                                    <option key={index} value={pais}>
                                        {pais}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Col>
                </Row>
            </div>

        );
    } else {
        return (
            <div className='container'>
                <Loading_papu></Loading_papu>
            </div>
        );
    }
};
export default InputDirec;