//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

//Services
import Api from "@/services/api";
import { useUser } from '@/services/UserContext';

//Components
import Loading_papu from "@/components/general/Loading_papu";

//Image
import img from '@images/usuario.png';

const InputImage = ({ disable, onInputChange, view }) => {
    /*
        * disable: true = Visualización | false = Modificación
        * onInputChange: callBack - Información que enviá al componente padre
        * id: ID del usuario
    */
    // Inicializar 'variable' como un objeto con las claves para cada input
    const [loadedData, setLoadedData] = useState(false);
    const [variable, setVariable] = useState(null);
    const [liga, setLiga] = useState(null);
    const { userID } = useUser();

    //Componente imagen Input
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setVariable(file); // Aquí guardamos el archivo directamente
            onInputChange(file);
        }
    };

    useEffect(() => {
        if (view) {
            setLiga(view.img);
            setLoadedData(true);
        } else {
            setLoadedData(true);
        }
    }, [view]);

    const InputImage = ({ nomVar }) => {
        return (
            <div className='text-center'>
                {
                    nomVar ?
                        <img
                            src={URL.createObjectURL(nomVar)} // Creamos una URL temporal para mostrar la imagen
                            className="img-fluid rounded"
                            alt="Imagen cargada"
                        />
                        :
                        liga ?
                            (
                                <img
                                    src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/img/" + liga}
                                    className="img-fluid rounded"
                                    alt="Imagen cargada"
                                />
                            ) :
                            (
                                <img
                                    src={img}
                                    className="img-fluid rounded"
                                    alt="No se ha podido cargar su imagen"
                                />
                            )
                }

                {!disable && (
                    <input
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                        name="img"
                        id="img"
                        accept="image/*"
                        className={`form-control ${variable == null || variable == '' ? "is-invalid" : ""}`}
                    />
                )}
            </div>
        );
    };

    if (loadedData) {

        return (
            <div className="card-body">
                <Row>
                    <Col>
                        <InputImage nomVar={variable} />
                    </Col>
                </Row>

            </div>

        );
    } else {
        return (
            <div className='container'>
                <Loading_papu></Loading_papu>
            </div>
        );
    }
};
export default InputImage;