//Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";

function Vehicles_list_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);
    const { pagFlotilla } = useUser();


    const eliminateData = (id) => {
        // Preguntar al usuario si realmente desea eliminar el vehículo
        const confirmar = window.confirm("¿Realmente desea eliminar el vehículo?");
        if (confirmar) {
            // Si el usuario hizo clic en "Aceptar"
            console.log(id);
            fetch(Api + "/automovil-flotilla.php?borrar=" + id)
                .then(response => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    window.location.reload();
                })
                .catch(console.log)
        } else {
            // Si el usuario hizo clic en "Cancelar" (No se hace nada)
        }
    }

    useEffect(() => {
        fetch(Api + "/tabla-procedimientos-flotilla.php")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setDocumentss(responseData);
                setLoadedData(true);
            })
            .catch(console.log)
    }, []);

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    if (existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) {
        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Procedimiento</h2>
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success text-white" to={"/flotilla/procedimiento/agregar-vehiculo"}>
                                Agrega un nuevo vehículo
                            </Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista de vehículos</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Número <br />
                                                económico</th>
                                            <th>Vehículo</th>
                                            <th>Placas</th>
                                            <th>NIV</th>
                                            <th>Categoría</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documentss.map(
                                            (documentss) => (
                                                <tr key={documentss.id}>
                                                    <td>{documentss.neconomico}</td>
                                                    <td>{documentss.vehiculo}</td>
                                                    <td>{documentss.placas}</td>
                                                    <td>{documentss.niv}</td>
                                                    <td>{documentss.categoria}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info"
                                                                to={"/flotilla/procedimiento/consultar/" + documentss.id}
                                                            >Consultar</Link>
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={(id) => eliminateData(documentss.id)}
                                                            >Eliminar</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Usted no tiene permitido estar en esta página</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Vehicles_list_flotilla;