import React, { createContext, useState, useContext } from 'react';
import { useEffect } from 'react';
import Api from "@/services/api";


// Crea el contexto del usuario
const UserContext = createContext();

// Crea un componente proveedor que gestionará el estado del usuario
export const UserProvider = ({ children }) => {
    // Información del usuario
    const [userName, setUserName] = useState(localStorage.getItem('userName') || null);
    const [userID, setUserID] = useState(localStorage.getItem('userID') || null);
    const [jerarquia, setJerarquia] = useState(localStorage.getItem('jerarquia') || null);
    // Plataforma activa o inactiva
    const [flotilla, setFlotilla] = useState(false);
    const [inventario, setInventario] = useState(false);
    // Paginas disponibles junto a sus privilegios (CRUD)
    const [pagFlotilla, setPagFlotilla] = useState([]);
    const [pagInventario, setPagInventario] = useState([[1, "Inventario"], [2, "Inventario"]]);

    useEffect(() => {
        // userID
        if (userID) {
            localStorage.setItem('userID', userID);
        } else {
            localStorage.removeItem('userID');
        }
        // userName
        if (userName) {
            localStorage.setItem('userName', userName);
        } else {
            localStorage.removeItem('userName');
        }
        // jerarquia
        if (jerarquia) {
            localStorage.setItem('jerarquia', jerarquia);
        } else {
            localStorage.removeItem('jerarquia');
        }
        // Plataforma Flotilla
        fetch(Api + "usuarios.php/?modulos=" + userID)
            .then(response2 => response2.json())
            .then((responseData2) => {
                //console.log(responseData2);
                const transformedData = responseData2.map(item => {
                    let actionNumber;

                    switch (item.acciones) {
                        case "Insertar":
                            actionNumber = 1;
                            break;
                        case "Modificar":
                            actionNumber = 2;
                            break;
                        case "Consultar":
                            actionNumber = 3;
                            break;
                        case "Eliminar":
                            actionNumber = 4;
                            break;
                        default:
                            actionNumber = 0; // en caso de que haya alguna acción desconocida
                    }

                    return [actionNumber, item.paginas];
                });

                setPagFlotilla(transformedData);
            })
            .catch(console.log);
            // Plataforma Inventario
    }, [userID, jerarquia, userName]);


    return (
        <UserContext.Provider value={{
            userName, setUserName, userID, setUserID, jerarquia, setJerarquia,
            flotilla, setFlotilla, inventario, setInventario,
            pagFlotilla, setPagFlotilla, pagInventario, setPagInventario
        }}>
            {children}
        </UserContext.Provider>
    );
};

// Crea un hook personalizado para acceder al contexto del usuario
export const useUser = () => {
    return useContext(UserContext);
};
