//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import MapComponent from '@/components/Flotilla/components/ViajesUbi/MapComponent';
import Table_users from "@/components/Flotilla/components/Index/Table_users";
//import Table_vehicles_flotilla from "@Components/Flotilla/components/Index/Table_vehicles_flotilla";
import BtnMenu from "@/components/Flotilla/components/Index/BtnMenu";

//Images
import carro from "@/images/coche.png";
import fallas from "@/images/fallas.png";
import reporte from "@/images/reportes.png";
import privilegios from "@/images/privilegios.png";
import calendario from "@/images/calendar-days-color.png";
import historial from "@/images/historial.png";
import estado from "@/images/estado-actual.png";
import usuario from "@/images/usuario.png";
import futuras from "@/images/404621.png";

function Central_flotilla() {
    //Variables externas
    const { pagFlotilla } = useUser();
    //Variables internas
    const [loadedData, setLoadedData] = useState(false);
    const [dataProcesada, setDataProcesada] = useState(null);
    let id = '2';

    //Coordenadas EPSG:3857
    const [markers, setMarkers] = useState([]);
    const [coordenadas, setCoordenadas] = useState(markers);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(Api + "automovil-flotilla.php?coordenadas=0");
                const responseData = await response.json();
                //console.log(responseData);
                // Procesando datos para agrupar por id_vehiculo
                const agrupado = {};
                responseData.segunda_consulta.forEach(item => {
                    const vehiculoId = item.id_vehiculo;
                    if (!agrupado[vehiculoId]) {
                        agrupado[vehiculoId] = {
                            usuarios: new Set(), // Usar un Set para evitar usuarios duplicados
                            vehiculo: item.vehiculo,
                            imagen: item.imagen || carro, // Si imagen es nula, usar 'carro'
                            lugares: [],
                            fecha: item.fecha_seguimiento,
                            pagina: item.id_lugares
                        };
                    }
                    agrupado[vehiculoId].usuarios.add(item.usuario);
                    agrupado[vehiculoId].lugares.push(item.lugares);
                });

                const datosProcesados = Object.values(agrupado).map(item => ({
                    ...item,
                    usuarios: Array.from(item.usuarios).join(", "), // Convertir Set a string
                    lugares: item.lugares.join(' - ')
                }));

                setDataProcesada(datosProcesados);
                // Procesando datos para 'ubicaciones'
                const ubicacionesActualizadas = responseData.segunda_consulta.map(item => item.lugares);

                const coordsPromises = ubicacionesActualizadas.map(async ubicacion => {
                    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${ubicacion}`);
                    const data = await response.json();
                    if (data[0]) {
                        return fromLonLat([parseFloat(data[0].lon), parseFloat(data[0].lat)], 'EPSG:3857');
                    }
                    return null;
                });

                const newCoords = await Promise.all(coordsPromises);
                console.log(newCoords);
                const filteredCoords = newCoords.filter(coord => coord !== null);

                setCoordenadas(filteredCoords);

            } catch (error) {
                // Manejar el error y mostrar un mensaje de alerta
                console.error('Error en la solicitud:', error);
                window.alert('Servicio de mapa no disponible por el momento');
            }
        };

        setLoadedData(true);
        fetchData();

    }, [id]);


    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        }
        else {
            return (
                <>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className="container">
                        <Row>
                            <MapComponent markers={coordenadas} />
                        </Row>
                        <br></br>
                        <h2>Vehículos disponibles</h2>
                        {dataProcesada && (<Table_users lista={dataProcesada} />)}
                        {/*<>
                        <h2>Proximas fechas</h2>
                        <Table_vehicles_flotilla />
                    </>*/
                        }
                        <br></br>
                        <Row xs={3} md={5} className="g-4">

                            {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (

                                <Col>
                                    <BtnMenu t={"Reportes"} link={"/flotilla/reporte"} img={reporte} />
                                </Col>
                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "Mantenimiento") && (

                                <Col>
                                    <BtnMenu t={"Bitacora de Mantenimiento"} link={"/flotilla/bitacora-de-mantenimiento"} img={carro} />
                                </Col>

                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "tecnico") && (

                                <Col>
                                    <BtnMenu t={"Reporte de Uso o Estado Actual"} link={"/flotilla/reporte/tecnico"} img={estado} />
                                </Col>

                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "fallas") && (

                                <Col>
                                    <BtnMenu t={"Bitacora de Fallas, Incidencias u Ocurrencias"} link={"/flotilla/reporte/fallas-incidencias-ocurrencias"} img={fallas} />
                                </Col>

                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios") && (

                                <Col>
                                    <BtnMenu t={"Control de usuarios"} link={"/flotilla/control-de-usuarios"} img={historial} />
                                </Col>

                            )}

                            <Col>
                                <BtnMenu t={"Perfil de usuario"} link={"/flotilla/perfil"} img={usuario} />
                            </Col>

                            {existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios") && (

                                <Col>
                                    <BtnMenu t={"Privilegios de usuario"} link={"/flotilla/privilegios"} img={privilegios} />
                                </Col>

                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "Calendario") && (

                                <Col>
                                    <BtnMenu t={"Calendario"} link={"/flotilla/calendario"} img={calendario} />
                                </Col>

                            )}
                            {existsInPagFlotilla(pagFlotilla, 3, "Graficos") && (

                                <Col>
                                    <BtnMenu t={"Gráficos"} link={"/flotilla/"} img={futuras} />
                                </Col>

                            )}
                        </Row>

                    </div >
                </>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Se esta cargando la página principal de la flotilla vehicular.</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Central_flotilla;
