//Librarys
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

//Images
import img from '@/images/pngimg.com - box_PNG40.png';

function Documents_new_flotilla() {
    // External variables
    const { pagFlotilla, userID } = useUser();
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    // Internal variables
    const [loadedData, setLoadedData] = useState(true);
    const [tipo, setTipo] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [fecha_exp, setFecha_exp] = useState(null);
    const [fecha_ven, setFecha_ven] = useState(null);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    const [respuesta, setRespuesta] = useState("true");
    const [link, setLink] = useState(null);
    const [liga, setLiga] = useState(null);
    const [fileType, setFileType] = useState(null);

    //Componente imagen Input
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLink(file); // Guardamos el archivo
            setFileType(file.type); // Guardamos el tipo de archivo
        }
    };

    const renderFile = () => {
        if (fileType.includes("image")) {
            return <img src={URL.createObjectURL(link)} className="img-fluid rounded" alt="Imagen cargada" />;
        } else if (fileType === "application/pdf") {
            return <iframe src={URL.createObjectURL(link)} className="pdf-viewer" title="PDF cargado"></iframe>;
        }
    };

    // Componente Checkbox
    const Option = ({ option }) => (
        <Col>
            <div className="form-group">
                <label htmlFor="">Suba su documento al servidor</label>
                <br />
                {
                    link ? renderFile() :
                        <div className='text-center'>
                            <img src={img} className="img-fluid rounded w-50" alt="No se ha podido cargar su imagen" />
                        </div>
                }
                <input
                    type="file"
                    onChange={handleFileChange}
                    name="link"
                    id="link"
                    accept="image/*,application/pdf"
                    className={`form-control ${!link ? "is-invalid" : ""}`}
                />
            </div>
        </Col>
    );

    const sendData = (e) => {
        e.preventDefault();

        console.log(userID);
        console.log(id);
        console.log(nombre);
        console.log(tipo)
        console.log(fecha_exp);
        console.log(fecha_ven);
        console.log(respuesta);
        console.log(link);
        console.log(liga);
        console.log(fileType);

        if (
            userID &&
            id &&
            nombre &&
            tipo &&
            respuesta
        ) {
            var formData = new FormData();

            if (respuesta == 'true') {
                //Validación para liga (documento externo)
                if (liga != null) {
                    formData.append("liga", liga);
                } else {
                    window.alert('Escriba la liga correspondiente.');
                    return;
                }
            } else {
                //Validación para archivos (img o pdf)
                if (link != null) {
                    formData.append("liga", link);
                } else {
                    window.alert('Escriba la liga correspondiente.');
                    return;
                }
            }
            // Seguimos ahora cargando los datos en la variables
            console.log("check this one");
            formData.append("userID", userID);
            formData.append("id_vehiculo", id);
            formData.append("nombre", nombre);
            formData.append("tipo", tipo);
            if (fecha_exp) { formData.append("fecha_exp", fecha_exp); }
            if (fecha_ven) {formData.append("fecha_ven", fecha_ven); }
            formData.append("respuesta", respuesta);
            //Procedimiento para enviar datos al API REST

            fetch(Api + "/automovil-flotilla.php?insdoc=1", {
                method: "POST",
                body: formData,
            }).then(response => response.json())
                .then((responseDataArray) => {
                    console.log(responseDataArray);

                    let hasSuccess3 = responseDataArray.some(response => response.success === 1);

                    if (hasSuccess3) {
                        window.alert('Su reporte ha sido cargado');
                        window.location.href = "/flotilla/documentos/" + id;
                    } else {
                        window.alert('Lo lamentamos, no se ha podido subir su reporte. Inténtelo de nuevo');
                    }
                }).catch(console.log);

        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }

    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 1, "Documentos")) {
        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Agrega un nuevo documento</h2>
                    <div className="card">
                        <form onSubmit={sendData}>
                            <div className="card-header">
                                <h4>Documentación del vehículo</h4>
                            </div>
                            <div className="card-body">
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="">Nombre</label>
                                            <input
                                                type="text"
                                                name="nombre"
                                                id="nombre"
                                                value={nombre}
                                                className={`form-control ${nombre == '' || nombre == null ? "is-invalid" : ""}`}
                                                placeholder="Escriba el nombre del documento"
                                                minLength={1}
                                                maxLength={30}
                                                autoComplete='off'
                                                required
                                                onChange={(e) => setNombre(e.target.value)}
                                            />
                                            <small id="helpId" className="form-text text-muted">
                                                *Seleccione el tipo de documento que agregara
                                            </small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="">Tipo de documento</label>
                                            <select
                                                className={`custom-select form-control 
                                                    ${tipo == '' || tipo == null ? "is-invalid" : ""}`}
                                                value={tipo}
                                                onChange={(e) => setTipo(e.target.value)}
                                                name="tipo"
                                                id="tipo"
                                                required
                                            >
                                                <option value="">Seleccione el tipo de documento a agregar</option>
                                                <optgroup label="Vehículos o plataformas">
                                                    <option value="Poliza de seguro">Poliza de seguro</option>
                                                    <option value="Titulo de Propiedad del Vehiculo">Título de Propiedad del Vehículo</option>
                                                    <option value="Factura de Compra">Factura de Compra</option>
                                                    <option value="Licencia de Circulacion">Licencia de Circulación</option>
                                                    <option value="Verificacion Vehicular">Verificación Vehicular</option>
                                                    <option value="Tarjeta de Circulacion">Tarjeta de Circulación</option>
                                                    <option value="Manual del Propietario">Manual del Propietario</option>
                                                    <option value="Registro de Mantenimiento">Registro de Mantenimiento</option>
                                                    <option value="Certificado de Inspeccion Tecnica">Certificado de Inspección Técnica</option>
                                                    <option value="Permiso de Importacion">Permiso de Importación</option>
                                                    <option value="Registro de Accidentes">Registro de Accidentes</option>
                                                </optgroup>
                                                <option value="Otros">Otros</option>
                                            </select>
                                            <small id="helpId" className="form-text text-muted">
                                                *Seleccione el tipo de documento que agregara
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="">Fecha de expedición</label>
                                            <input
                                                type="date"
                                                name="fecha_exp"
                                                id="fecha_exp"
                                                value={fecha_exp}
                                                className={"form-control"}
                                                onChange={(e) => setFecha_exp(e.target.value)}
                                                min={formattedDate}
                                                autoComplete='off'
                                            />
                                            <small id="helpId" className="form-text text-muted">
                                                Seleccione la fecha que se expidio el documento
                                            </small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="">Fecha de vencimiento</label>
                                            <input
                                                type="date"
                                                name="fecha_ven"
                                                id="fecha_ven"
                                                value={fecha_ven}
                                                className={"form-control"}
                                                onChange={(e) => setFecha_ven(e.target.value)}
                                                min={formattedDate}
                                                autoComplete='off'
                                            />
                                            <small id="helpId" className="form-text text-muted">
                                                Seleccione la fecha que se vence el documento
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={9}>
                                        <div className="form-group">
                                            <label htmlFor="">¿Su archivo se encuentra alojado en una plataforma de internet?</label>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="respuesta"
                                                id="respuesta"
                                                value={"false"}
                                                checked={respuesta === "false"}
                                                onChange={(e) => setRespuesta(e.target.value)}
                                            />

                                            <label className="form-check-label" htmlFor="">
                                                <b>
                                                    &nbsp;No
                                                </b>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="respuesta"
                                                id="respuesta"
                                                value={"true"}
                                                checked={respuesta == "true"}
                                                onChange={(e) => setRespuesta(e.target.value)}
                                            />

                                            <label className="form-check-label" htmlFor="">
                                                <b>
                                                    &nbsp;Sí
                                                </b>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    {respuesta === 'false' && (
                                        <Option />
                                    )}
                                    {respuesta === 'true' && (
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Liga o link del archivo</label>
                                                <input
                                                    type="text"
                                                    name="liga"
                                                    id="liga"
                                                    value={liga}
                                                    className={`form-control ${liga == '' || liga == null ? "is-invalid" : ""}`}
                                                    placeholder="Ingrese la liga del documento"
                                                    minLength={1}
                                                    maxLength={120}
                                                    autoComplete='off'
                                                    required
                                                    onChange={(e) => setLiga(e.target.value)}
                                                />
                                                <small id="helpId" className="form-text text-muted">
                                                    * Máximo 120 caracteres
                                                </small>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            <div className="card-footer text-muted">
                                <div className="btn-group" role="group" aria-label="">
                                    <button type="submit"
                                        className="btn btn-success"
                                    >
                                        Agregar
                                    </button>
                                    <Link className="btn btn-danger text-white" to={"/flotilla/documentos/" + id + "/"}>Regresar</Link>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Documentos de un vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Documents_new_flotilla;