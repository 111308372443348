//Components
import Api from "@/services/api";

//Images
import fondo from "@/images/fondopdf.jpeg";

//Librarys
import React, { useEffect, useState } from 'react';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const PDF_bitacora = ({ id, user }) => {


    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState([]);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [usuario, setUsuario] = useState([]);




    const CheckBox = ({ isChecked }) => (
        <View style={styles.checkbox}>
            <Text style={styles.checked}>{isChecked ? 'X' : ''}</Text>
        </View>
    );

    const SuperCheck = ({ cosa }) => (
        <View style={styles.tableCol}>
            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.text}>{cosa}</Text>
                </View>
                <View style={styles.col}>
                    <CheckBox isChecked={terceraConsulta.some(item => item.rem_nombre_servicio === "" + cosa + "")} />
                </View>
            </View>
        </View>
    );

    // Componente Checkbox
    const InputCheck = ({ name }) => (
        <View style={styles.tableCol50}>
            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.text}>{name}</Text>
                </View>
                <View style={styles.col}>
                    <CheckBox isChecked={terceraConsulta.some(item => item.ret_nombre_documento === name)} />
                </View>
            </View>
        </View>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name }) => (
        <View style={styles.row}>
            <View style={styles.col80}>
                <Text style={styles.text}>{name}</Text>
            </View>
            <View style={styles.col20}>
                <CheckBox isChecked={segundaConsulta.some(item => item.ret_nombre_accesorio === name)} />
            </View>
        </View>
    );

    const serviciosEspeciales = [
        "Cambio de Aceite al Motor",
        "Cambio de Filtro de Aceite al Motor",
        "Cambio de Filtro de Aire",
        "Cambio de Filtro de Combustible",
        "Cambio de Aceite de Transmisión",
        "Cambio de Filtro de Transmisión",
        "Cambio de Anticongelante",
        "Cambio de Plumillas de Limpia-Parabrisas",
        "Cambio de Amortiguadores",
        "Cambio de Neumáticos",
        "Cambio de Batería",
        "Cambio de Balatas",
        "Alineación y Balanceo",
        "Cambio de Bujías",
        "Limpieza de Inyectores",
        "Cambio de luces",
        "Cambio de Líquido de Frenos",
        "Alineación de Ruedas",
        "null"
    ];

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag, av, vg, vv }) => (
        <View style={styles.row}>
            <View style={styles.col}><Text style={styles.label}>{tg}</Text></View>
            <View style={styles.col}>
                <Text style={styles.text}>
                    {vg ? vg[ag] : ""}</Text>
            </View>
            <View style={styles.col}><Text style={styles.label}>{tv}</Text></View>
            <View style={styles.col}>
                <Text style={styles.text}>
                    {vv ? vv[av] : ""}</Text>
            </View >
        </View >
    );

    const registroEspecial = terceraConsulta.find(item => !serviciosEspeciales.includes(item.rem_nombre_servicio));

    useEffect(() => {
        console.log('id: ' + id);
        console.log('userId: ' + user);

        fetch(Api + "reportes.php?repconsest=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);


        fetch(Api + "usuarios.php/?listaid=" + user)
            .then(response3 => response3.json())
            .then((responseData3) => {
                console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)

    }, []);

    // Estilos personalizados para el PDF
    const styles = StyleSheet.create({
        page: {
            flexDirection: "column", // Asegura que la imagen de fondo se extienda por toda la página
            width: '100%', // Ajusta el tamaño de la imagen de fondo
            height: '100%',
            position: 'relative',
        },
        headerText: {
            fontSize: 18,
            textAlign: 'right',
        },
        label: {
            fontWeight: "bold",
            fontSize: 12,
        },
        text: {
            fontSize: 10,
            fontWeight: "light",
            fontStyle: "italic",
        },
        image: {
            width: '100%',
            height: '100%',
            position: 'absolute', // Ajusta la posición de la imagen
            top: 0, // Coloca la imagen en la parte superior izquierda
            left: 0,
            zIndex: -1, // Coloca la imagen detrás de los elementos de texto
        },
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent', // Fondo transparente para ver la imagen de fondo
        },
        card: {
            marginLeft: "5%",
            marginRight: "5%",
        },
        cardheader: {
            width: "100%",
            alignItems: 'right',
            justifyContent: 'flex-end',
            height: "13vw",
        },
        imgvehicle: {
            width: "100%",
            height: "12vw",
        },
        evidencia: {
            width: "100%",
            height: "100%",
        },
        row: {
            flexDirection: 'row', // Esto establece la dirección de las columnas
            marginBottom: 2, // Espacio entre filas (ajusta según tus necesidades)
        },
        col: {
            marginLeft: 5,
            flex: 1, // Esto permite que las columnas se ajusten automáticamente
        },
        col80: {
            marginLeft: 5,
            width: "90%",
        },
        col20: {
            marginRight: 5, // Espacio entre columnas (ajusta según tus necesidades)
            width: "10%",
        },
        textLeft: {
            textAlign: 'left',
        },
        checkbox: {
            width: 12,
            height: 12,
            border: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        checked: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: "center",
        },
        tableCol: {
            width: "33.3%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol50: {
            width: "50%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol66: {
            width: "66.6%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: "auto",
            marginTop: 5,
            fontSize: 12,
            fontWeight: "bold"
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        },
        tableCol10: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol35: {
            width: "37.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        tableCol15: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        textCenter: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        greenBackground: {
            backgroundColor: '#A6DDB880',
        },
        space: {
            paddingTop: 93,
        }
    });

    if (!loadedData) {
        return (
            <Document>
                <Page size="A4">
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <Text>
                            No se pudo cargar la información.
                        </Text>
                    </View>
                </Page>
            </Document>
        );
    } else {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.card}>
                            <View style={styles.cardheader}>
                                <Text style={styles.headerText}>REPORTE ACTUAL DEL ESTADO DEL VEHÍCULO</Text>
                            </View>
                            <View style={styles.cardbody}>
                                <View style={styles.row}>
                                    <View style={styles.col}></View>
                                    <View style={styles.col}></View>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={styles.textLeft}>
                                            <Text style={styles.label}>Folio: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.folio}</Text>
                                            </Text>
                                        </View>
                                        <View>
                                            <Text
                                                style={styles.label}
                                            >Fecha: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.Fecha_generada}</Text>
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>DATOS GENERALES</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>DATOS DEL VEHÍCULO</Text>
                                    </View>
                                </View>
                                <DatosGV tg="APELLIDO PATERNO:" tv="MARCA:" ag="ap_paterno" av="marca" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="APELLIDO MATERNO:" tv="MODELO:" ag="ap_materno" av="modelo" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="NOMBRE:" tv="PLACAS:" ag="nombre" av="placas" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="UNIDAD:" tv="NIV:" ag="unidad" av="niv" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="OBRA:" tv="NÚM.ECONÓMICO:" ag="obra" av="num_economico" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="DIRECCIÓN:" tv="ODÓMETRO, KM:" ag="direccion" av="odometro_actual" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="DEPARTAMENTO:" tv="DESCRIPCIÓN:" ag="departamento" av="descripcion" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="TELEFONO:" ag="telefono" vg={usuario} />
                                <DatosGV tg="CORREO ELECTRÓNICO:" ag="email" vg={usuario} />
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>DOCUMENTACIÓN DEL VEHÍCULO</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <InputCheck name="Tarjeta de Circulación" />
                                        <InputCheck name="Comprobante de Verificación" />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <InputCheck name="Póliza de seguro" />
                                        <InputCheck name="IAVE" />
                                    </View>
                                </View>
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>VERIFICACIÓN DE ACCESORIOS Y HERRAMIENTAS</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.label}>EXTERIORES</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.label}>INTERIORES</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.label}>OTROS</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <InputCheck2 name="Luces" />
                                            <InputCheck2 name="Cuarto" />
                                            <InputCheck2 name="Antena" />
                                            <InputCheck2 name="Espejos Laterales" />
                                            <InputCheck2 name="Cristales" />
                                            <InputCheck2 name="Emblemas" />
                                            <InputCheck2 name="Llantas" />
                                            <InputCheck2 name="Tapones de Rueda" />
                                            <InputCheck2 name="Molduras" />
                                            <InputCheck2 name="Tapón de Gasolina" />
                                            <InputCheck2 name="Limpiadores" />
                                        </View>
                                        <View style={styles.tableCol}>
                                            <InputCheck2 name="Instrumentos de Tablero" />
                                            <InputCheck2 name="Calefacción" />
                                            <InputCheck2 name="Radio/Estereo" />
                                            <InputCheck2 name="Bocinas" />
                                            <InputCheck2 name="Encendedor" />
                                            <InputCheck2 name="Espejo Retrovisor" />
                                            <InputCheck2 name="Ceniceros" />
                                            <InputCheck2 name="Cinturones de Seguridad" />
                                            <InputCheck2 name="Manijas de Puerta" />
                                            <InputCheck2 name="Tapetes" />
                                            <InputCheck2 name="Vestiduras" />
                                        </View>
                                        <View style={styles.tableCol}>
                                            <InputCheck2 name="Gato" />
                                            <InputCheck2 name="Maneral de Gato" />
                                            <InputCheck2 name="Llave de Rueda" />
                                            <InputCheck2 name="Birlo de Seguridad" />
                                            <InputCheck2 name="Bastón de Seguridad" />
                                            <InputCheck2 name="Llanta de Refacción" />
                                            <InputCheck2 name="Estuche de Herramienta" />
                                            <InputCheck2 name="Triángulo de seguridad" />
                                            <InputCheck2 name="Extinguidor" />
                                            <InputCheck2 name="Llave del Vehículo" />
                                            <InputCheck2 name="Duplicado de Llave" />
                                        </View>
                                    </View>
                                </View>
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>INFORME ESTADO DEL VEHÍCULO</Text>
                                        </View>
                                    </View>
                                    <View style={styles.textCenter}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColHeader}>
                                                <Text style={styles.text}>{primeraConsulta.info_estado}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.space}>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                        <View style={styles.card}>
                            <View style={styles.row}>
                                <Text style={styles.label}>EVIDENCIA DEL ESTADO DEL VEHÍCULO</Text>
                            </View>
                            <View style={styles.row}>
                                <Image
                                    style={styles.evidencia}
                                    // Cambia la URL de la imagen
                                    src={primeraConsulta.evidencia ? Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                        + id + "/evi/" + primeraConsulta.evidencia : ""}
                                />
                            </View>
                            <Text style={styles.text}>&nbsp;</Text>
                            <View style={styles.row}>
                                <View style={styles.col}>
                                    <Text style={styles.label}>Ejecutado:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_soli ?
                                            Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_soli : ""}
                                    />

                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.usuario}</Text>
                                    </View>
                                </View>
                                <View style={styles.col}>
                                    <Text style={styles.label}>Verificado:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_verificada ?
                                            Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_verificada : ""}
                                    />
                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.nom_verificado}</Text>
                                    </View>
                                </View>
                                <View style={[styles.col, styles.greenBackground]}>
                                    <Text style={styles.label}>Visto bueno:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_vo ?
                                            Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/fir/" + primeraConsulta.firma_vo : "Holo"}
                                    />
                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.nom_vo}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document >
        );
    }
}
export default PDF_bitacora;
