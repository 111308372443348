//librarys
import React from 'react';
import { Link } from 'react-router-dom';

//Images
import bitacora from "@/images/checklist-1.jpg";
import tecnico from "@/images/reporte-tecnico.jpg";
import fallas from "@/images/fallas.jpg";
import otros from "@/images/estado.jpg";

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';

function Report_flotilla() {
    const { pagFlotilla} = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;

    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true; 
            // We exit the loop once the page is found
            break; 
        }
    }
    if (paginaEncontrada) {
        return (
            <div className='container'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <h2>Consulta o crea tu reporte vehicular</h2>

                {/* Image menu */}
                <div className="row w-100">
                    <div className="col-lg-6">
                        <div className="card text-white bg-secondary card-reporte">
                            <Link to={"/flotilla/bitacora-de-mantenimiento"}>
                                <img
                                    className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} imagen-personalizada"
                                    src={bitacora}
                                    alt="Reporte Vehicular"
                                />
                            </Link>
                            <div className="card-body text-center">
                                <h4 className="card-title">Reporte</h4>
                                <p className="card-text">Mantenimiento</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card text-white bg-secondary card-reporte">
                            <Link to={"/flotilla/reporte/tecnico"}>
                                <img
                                    className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} imagen-personalizada"
                                    src={tecnico}
                                    alt="Reporte técnico"
                                />
                            </Link>
                            <div className="card-body text-center">
                                <h4 className="card-title">Reporte</h4>
                                <p className="card-text">Estado actual</p>
                            </div>
                        </div>

                    </div>
                </div>
                <br />
                <div className="row w-100">
                    <div className="col-lg-6">
                        <div className="card text-white bg-secondary card-reporte">
                            <Link to={"/flotilla/reporte/fallas-incidencias-ocurrencias"}>
                                <img
                                    className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} imagen-personalizada"
                                    src={fallas}
                                    alt="Reporte de fallas, incidencias u ocurrencias"
                                />
                            </Link>
                            <div className="card-body text-center">
                                <h4 className="card-title">Reporte</h4>
                                <p className="card-text">Fallas, incidencias u ocurrencias</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card text-white bg-secondary card-reporte">
                            <Link to={"/flotilla/reporte/otros-reportes"}>
                                <img
                                    className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} imagen-personalizada"
                                    src={otros}
                                    alt="Otros reportes"
                                />
                            </Link>
                            <div className="card-body text-center">
                                <h4 className="card-title">Reporte</h4>
                                <p className="card-text">Otros reportes</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Report_flotilla;