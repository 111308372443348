import React, { useEffect, useState } from 'react';
import ISO from "@/images/mantener-el-certificado-ISO-9001.jpg";
import '@/styles/general_styles.css';
import { Col, Row } from 'react-bootstrap';
const Central_decorative = () => {
  const Carta = ({ tt, img, tx }) => (
    <Col className='mt-1 mb-1'>
      <div className="card text-white bg-danger card-hover">
        <img className="card-img-top" src={img} alt="REGLA ISO" />
        <div className="card-body">
          <h4 className="card-title">{tt}</h4>
          <p className="card-text fw-lighter" style={{ textAlign: 'justify' }}>{tx}</p>
        </div>
      </div>
    </Col>
  );
  return (
    <div className='container'>
      <Row xs={1} md={3} lg={3}>
        <Carta tt="ISO 14001" img="https://sustant.es/wp-content/uploads/2019/10/14001.png"
          tx="Comprometidos con el medio ambiente, Race Carreteras opera con un Sistema de 
          Gestión Ambiental certificado que cumple con la norma ISO 14001, asegurando la 
          sostenibilidad en cada proyecto."
        />
        <Carta tt="ISO 9001" img="https://www.madrecert.com/wp-content/uploads/2021/04/logo-ISO-9001.jpg"
          tx="Calidad garantizada en la construcción y mantenimiento de carreteras con Race Carreteras, 
          certificada bajo la norma ISO 9001 para la satisfacción total del cliente y 
          la mejora continua"
        />
        <Carta tt="ISO 45001" img="https://sustant.es/wp-content/uploads/2019/10/ISO-45001.png"
          tx="Priorizando la seguridad, Race Carreteras protege a su personal y clientes mediante la 
          implementación de la norma ISO 45001, promoviendo un entorno de trabajo seguro y saludable"
        />
      </Row>
    </div>
  );
};

export default Central_decorative;
