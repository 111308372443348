// Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@/components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

function Maintenance_log_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);

    useEffect(() => {
        // Carga los datos una vez que el componente se monta
        fetch(Api + "reportes.php?consultar=" + userID)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setDocumentss(responseData.data);
            })
            .catch(console.log);
    }, []);

    const [filters, setFilters] = useState({
        folio: "",
        titulo: "",
        tipoMantenimiento: "",
        fechaCreacion: "",
        fechaProxima: "",
        estatus: ""
    });

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value
        });
    };

    const filteredDocuments = documentss.filter(doc => {
        return (
            doc.id.includes(filters.folio) &&
            doc.titulo.includes(filters.titulo) &&
            doc.tipo_mantenimiento.includes(filters.tipoMantenimiento) &&
            doc.fecha_generada.includes(filters.fechaCreacion) &&
            doc.fecha_proxima.includes(filters.fechaProxima) &&
            doc.rep_estatus.includes(filters.estatus)
        );
    });

    const { pagFlotilla, userID } = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];

    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }
    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora de mantenimiento</h2>
                    <div className="card">
                        <div className="card-header">
                            {CRUDUser[0] === 1 && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/mantenimiento/"}>
                                    Agregar nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes de mantenimiento</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" placeholder="Filtrar Folio" onChange={e => handleFilterChange(e, "folio")} /></th>
                                            <th><input type="text" placeholder="Filtrar Titulo" onChange={e => handleFilterChange(e, "titulo")} /></th>
                                            <th><input type="text" placeholder="Filtrar Tipo" onChange={e => handleFilterChange(e, "tipoMantenimiento")} /></th>
                                            <th><input type="text" placeholder="Filtrar Fecha de creación" onChange={e => handleFilterChange(e, "fechaCreacion")} /></th>
                                            <th colSpan={3}><input type="text" placeholder="Filtrar Fecha próxima" onChange={e => handleFilterChange(e, "fechaProxima")} /></th>
                                            <th><input type="text" placeholder="Filtrar Estatus" onChange={e => handleFilterChange(e, "estatus")} /></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}></th>
                                            <th colSpan={3} className='text-center border border-secondary'>Servicio</th>
                                            <th colSpan={2}></th>
                                        </tr>
                                        <tr>
                                            <th>Folio</th>
                                            <th>Titulo</th>
                                            <th>Tipo de
                                                <br />
                                                mantenimiento</th>
                                            <th>Fecha de
                                                <br />
                                                creación</th>
                                            <th className='border border-secondary'>Fecha
                                                <br />
                                                próxima</th>
                                            <th className='border border-secondary'>Horas</th>
                                            <th className='border border-secondary'>Ódometro</th>
                                            <th>Estatus</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    {CRUDUser[2] === 3 && (<tbody>
                                        {filteredDocuments.map(
                                            (documentss) => (
                                                <tr key={documentss.id}>
                                                    <td>{documentss.id}</td>
                                                    <td>{documentss.titulo}</td>
                                                    <td>{documentss.tipo_mantenimiento}</td>
                                                    <td>{documentss.fecha_generada}</td>
                                                    <td>{documentss.fecha_proxima}</td>
                                                    <td>{documentss.horas_proximo}</td>
                                                    <td>{documentss.odometro_proximo}</td>
                                                    <td>{documentss.rep_estatus}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/bitacora-de-mantenimiento/consultar/" + documentss.id}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        }
                                    </tbody>)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora de mantenimiento</h2>
                    <div className="card">
                        <div className="card-header">
                            {CRUDUser[0] === 1 && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/mantenimiento/"}>
                                    Agregar nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes de mantenimiento</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" placeholder="Filtrar Folio" onChange={e => handleFilterChange(e, "folio")} /></th>
                                            <th><input type="text" placeholder="Filtrar Titulo" onChange={e => handleFilterChange(e, "titulo")} /></th>
                                            <th><input type="text" placeholder="Filtrar Tipo" onChange={e => handleFilterChange(e, "tipoMantenimiento")} /></th>
                                            <th><input type="text" placeholder="Filtrar Fecha de creación" onChange={e => handleFilterChange(e, "fechaCreacion")} /></th>
                                            <th colSpan={3}><input type="text" placeholder="Filtrar Fecha próxima" onChange={e => handleFilterChange(e, "fechaProxima")} /></th>
                                            <th><input type="text" placeholder="Filtrar Estatus" onChange={e => handleFilterChange(e, "estatus")} /></th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th colSpan={4}></th>
                                            <th colSpan={3} className='text-center border border-secondary'>Servicio</th>
                                            <th colSpan={2}></th>
                                        </tr>
                                        <tr>
                                            <th>Folio</th>
                                            <th>Titulo</th>
                                            <th>Tipo de
                                                <br />
                                                mantenimiento</th>
                                            <th>Fecha de
                                                <br />
                                                creación</th>
                                            <th className='border border-secondary'>Fecha
                                                <br />
                                                próxima</th>
                                            <th className='border border-secondary'>Horas</th>
                                            <th className='border border-secondary'>Ódometro</th>
                                            <th>Estatus</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    {CRUDUser[2] === 3 && (<tbody>
                                        {filteredDocuments.map(
                                            (documentss) => (
                                                <tr key={documentss.id}>
                                                    <td>{documentss.id}</td>
                                                    <td>{documentss.titulo}</td>
                                                    <td>{documentss.tipo_mantenimiento}</td>
                                                    <td>{documentss.fecha_generada}</td>
                                                    <td>{documentss.fecha_proxima}</td>
                                                    <td>{documentss.horas_proximo}</td>
                                                    <td>{documentss.odometro_proximo}</td>
                                                    <td>{documentss.rep_estatus}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/bitacora-de-mantenimiento/consultar/" + documentss.id}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        }
                                    </tbody>)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }

}

export default Maintenance_log_flotilla;