import React from 'react';
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Car_Bar_Flotilla from '../components/Car_Bar_Flotilla';
import Loading from '../../general/Loading_papu';

class Geocerca_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        loadedData: false,
        users: [],
        showAddButton: true,
        showDeleteButton: false,
        showRectangleButton: true,
        showPinButton: true
    }

    loadData() {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                this.setState({ loadedData: true, users: responseData })
            })
            .catch(console.log)
    }

    componentDidMount() {
        this.loadData();
    }

    handlePinButton = () => {
        this.setState({
            showPinButton: true,
            showRectangleButton: false
        });
    }

    handleAddButton = () => {
        const { showPinButton, showRectangleButton } = this.state;
        if (showPinButton === true && showRectangleButton === true) {
            window.alert('Agregue más de 3 puntos o agregue un rectangulo al mapa.');
        } else {
            this.setState({
                showAddButton: false,
                showDeleteButton: true
            });
        }
    }

    handleDeleteButton = () => {
        this.setState({
            showAddButton: true,
            showDeleteButton: false,
            showPinButton: true,
            showRectangleButton: true
        });
    }

    handleRectangleButton = () => {
        this.setState({
            showRectangleButton: true,
            showPinButton: false
        });
    }

    render() {
        {/* Retrieve loaded data */ }
        const { loadedData, users, showAddButton, showDeleteButton, showRectangleButton, showPinButton } = this.state

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla />
                    <Loading></Loading>
                </div>
            );
        } else {
            return (
                <div className='container'>
                    <Nav_bar_flotilla />
                    <h2>Geocerca</h2>
                    <Car_Bar_Flotilla />
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d12530251.7681442!2d-99.92237537343975!3d24.243318856122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1691527646794!5m2!1ses-419!2smx" width="100%" height="500px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h3>Historial de ubicaciones</h3>
                    <div className="btn-group" role="group" aria-label="">
                        {showPinButton && <button type="button" className="btn btn-primary" onClick={this.handlePinButton}>Agregar alfiler</button>}
                        {showRectangleButton && <button type="button" className="btn btn-primary" onClick={this.handleRectangleButton}>Agregar rectangulo</button>}
                        {showDeleteButton && <button type="button" className="btn btn-warning" onClick={this.handleDeleteButton}>Eliminar o retroceder</button>}
                        {showAddButton && <button type="button" className="btn btn-success" onClick={this.handleAddButton}>Agregar geocerca</button>}

                    </div>
                    <div className="form-group">
                        <label for="">Historial</label>
                        <textarea className="form-control" name="" id="" rows="3" readOnly></textarea>
                    </div>
                </div>
            );
        }
    }
}

export default Geocerca_flotilla;