//Librarys
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

//Images
import img from '@/images/pngimg.com - box_PNG40.png';

function ViewDoc() {
    // External variables
    const { pagFlotilla, userID } = useUser();
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    // Internal variables
    const [loadedData, setLoadedData] = useState(true);
    const [documentss, setDocumentss] = useState([]);

    const renderFile = () => {
        // Comprueba si documentss y documentss.link existen
        if (documentss && documentss.usu_foto_documento) {
            // Utiliza una expresión regular para obtener la extensión del archivo
            const extension = documentss.usu_foto_documento.match(/\.(pdf|jpg|jpeg|png|gif)$/i);

            // Si la extensión es PDF
            if (extension && extension[1].toLowerCase() === 'pdf') {
                return (
                    <>
                        <label htmlFor="">Documento</label>
                        <iframe
                            src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/doc/" + documentss.usu_foto_documento}
                            className="pdf-viewer"
                            title="PDF cargado">
                        </iframe>
                    </>
                );
            }
            // Si la extensión es una imagen (jpg, jpeg, png, gif)
            else if (extension && ['jpg', 'jpeg', 'png', 'gif'].includes(extension[1].toLowerCase())) {
                return (
                    <>
                        <label htmlFor="">Documento</label>
                        <div className='text-center'>
                            <img
                                src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/doc/" + documentss.usu_foto_documento}
                                className="img-fluid rounded"
                                alt="Imagen cargada" />
                        </div>
                    </>
                );
            }
        }

        // Si no hay un link o no es una extensión reconocida, puedes retornar null o algún placeholder
        return null;
    };

    // Componente Checkbox
    const Option = () => (
        <Col>
            <div className="form-group">
                {
                    documentss.usu_foto_documento ? renderFile() :
                        <>
                            <label htmlFor="">Documento</label>
                            <div className='text-center'>
                                <img src={img} className="img-fluid rounded w-50" alt="No se ha podido cargar su imagen" />
                            </div>
                        </>

                }
            </div>
        </Col>
    );

    //Componente textField 
    const TextField = ({ tittle, vr, tp }) => (
        <Col>
            <div className="form-group">
                <label htmlFor={vr}>{tittle}</label>
                <input
                    type={tp}
                    name={vr}
                    id={vr}
                    value={documentss ? documentss[vr] : ""}
                    className="form-control"
                    readOnly
                />
            </div>
        </Col>
    );

    const loadData = () => {
        fetch(Api + "usuarios.php?docview=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setDocumentss(responseData.perfil[0]);
            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
    }, []);

    const sendData = (e) => {
        e.preventDefault();
        /*
        console.log(userID);
        console.log(id);
        console.log(id_doc);
        console.log(permisos);
        */


    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (userID) {
        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className="card">
                        <form onSubmit={sendData}>
                            <div className="card-header">
                                <h4>{documentss ? documentss.usuario_nombre_documento : ""}</h4>
                            </div>
                            <div className="card-body">
                                <Row>
                                    {documentss.usu_fecha_expedido && <TextField tittle={"Fecha de expedición"} vr={"usu_fecha_expedido"} tp={"date"} />}
                                    {documentss.usu_fecha_vencimiento && <TextField tittle={"Fecha de vencimiento"} vr={"usu_fecha_vencimiento"} tp={"date"} />}
                                </Row>
                                <Row>
                                    <TextField tittle={"Tipo de documento"} vr={"usu_tipo_documento"} tp={"text"} />
                                    <TextField tittle={"Estado del documento"} vr={"usu_estado_documento"} tp={"text"} />
                                </Row>
                                <Row>
                                    {
                                        documentss && documentss.usu_foto_documento ?
                                            (documentss.usu_foto_documento.startsWith('http') || documentss.usu_foto_documento.startsWith('https') ?
                                                <TextField title={"Link o liga del documento (externo)"} vr={"usu_foto_documento"} tp={"text"} />
                                                :
                                                <Option />
                                            )
                                            :
                                            <TextField title={"Link o liga del documento (externo)"} vr={"usu_foto_documento"} tp={"text"} />
                                    }
                                </Row>
                                <br />

                            </div>
                            <div className="card-footer text-muted text-center">
                                <div className="btn-group" role="group" aria-label="">
                                    <button type="submit"
                                        className="btn btn-success"
                                    >
                                        Actualizar cambios
                                    </button>
                                    <Link className="btn btn-danger text-white" to={"/flotilla/perfil/"}>Regresar</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Documentos de un vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default ViewDoc;