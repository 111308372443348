//Librarys
import React from 'react';

//Components
import Nav_bar_central from "../components/Nav_bar_central";
import Body_central from "../components/Body_central";
import Central_text from '../components/Central_text';
import Central_decorative from '../components/Central_decorative';

//Services
import { useUser } from '../../../services/UserContext';


function Central_page() {
    // Validador de inicio de sesión
    const { userID, userName} = useUser();
    if (userID) {
        // -- - -- //
        return (
            <div className='container-fluid'>
                <Nav_bar_central />
                <h1>Bienvenido ¡{userName}!</h1>
                <Body_central />
                <Central_text />
                <Central_decorative />
            </div>
        );
        // -- - -- //
    } else {
        window.location.href="/login";
    }
    // Termina la validación
}

export default Central_page;