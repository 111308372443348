//Librarys
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";
import ConvMedida from '@Components/general/ConvMedida';

//Images
import coche from "@/images/coche.png";
import checklist from "@/images/checklist-1.jpg";
import reporte from "@/images/descargar.jpg";
import viajes from "@/images/consejos-para-hacer-un-road-trip-en-Mexico.jpeg";
import documentacion from "@/images/documentacion.jpg";
import historial from "@/images/historial.png";
import estado from "@/images/estado.jpg";
import procedimiento from "@/images/procedimiento.jpg";
import geocerca from "@/images/geocerca.jpg";
import futuras from "@/images/404621.png";

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import MapComponent from '@/components/Flotilla/components/ViajesUbi/MapComponent';


function Vehicle_flotilla() {
    //Variables externas
    const { id } = useParams(); // Obtenemos el valor "id" de la URL
    const { pagFlotilla } = useUser();
    //Variables internas
    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState([]);
    const [dataProcesada, setDataProcesada] = useState(null);

    // Ubicaciones tipo STRING
    const [ubicaciones, setUbicaciones] = useState([]);
    //Coordenadas EPSG:3857
    const [markers, setMarkers] = useState([]);
    const [coordenadas, setCoordenadas] = useState(markers);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(Api + "automovil-flotilla.php?coordenadas=" + id);
                const responseData = await response.json();
                //console.log(responseData);
                // Procesando datos para agrupar por id_vehiculo
                const agrupado = {};
                responseData.segunda_consulta.forEach(item => {
                    if (!agrupado[item.id_vehiculo]) {
                        agrupado[item.id_vehiculo] = {
                            usuario: item.usuario,
                            lugares: []
                        };
                    }
                    agrupado[item.id_vehiculo].lugares.push(item.lugares);
                });

                const datosProcesados = Object.values(agrupado).map(item => ({
                    ...item,
                    lugares: item.lugares.join(' - ') // Combina todos los 'lugares' en un string
                }));

                setDataProcesada(datosProcesados);
                // Procesando datos para 'ubicaciones'
                const ubicacionesActualizadas = responseData.segunda_consulta.map(item => item.lugares);
                setUbicaciones(ubicacionesActualizadas);

                const coordsPromises = ubicacionesActualizadas.map(async ubicacion => {
                    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${ubicacion}`);
                    const data = await response.json();
                    if (data[0]) {
                        return fromLonLat([parseFloat(data[0].lon), parseFloat(data[0].lat)], 'EPSG:3857');
                    }
                    return null;
                });

                const newCoords = await Promise.all(coordsPromises);
                //console.log(newCoords);
                const filteredCoords = newCoords.filter(coord => coord !== null);

                setCoordenadas(filteredCoords);

                /* Procesando datos para 'markers'
                const markersActualizados = responseData.primera_consulta.map(item => [
                    parseFloat(item.longitud),
                    parseFloat(item.latitud)
                ]);
                setMarkers(markersActualizados);
                setCoordenadas(markersActualizados);
  
                */

            } catch (error) {
                // Manejar el error y mostrar un mensaje de alerta
                console.error('Error en la solicitud:', error);
                window.alert('Servicio de mapa no disponible por el momento');
            }
        };

        fetch(Api + "automovil-flotilla.php?vehiculo=" + id)
            .then(response => response.json())
            .then((responseData) => {
                // console.log(responseData);
                setVehiculo(responseData[0]);
            })
            .catch(console.log);

        setLoadedData(true);
        fetchData();

    }, [id]);

    // Componente Datos
    const Datos = ({ av, vv }) => (
        <p className="card-text d-inline">
            {vv ? vv[av] : ""}</p>
    );
    // Componente Botones menu
    const BtnMenu = ({ t, link, img }) => (
        <Col sm>
            <div className="card text-white bg-secondary card-reporte">
                <Link to={link}>
                    <img
                        className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} imagen-personalizada"
                        src={img}
                    />
                </Link>
                <div className="card-body text-center">
                    <h4 className="card-title">{t}</h4>
                </div>
            </div>
        </Col>
    );

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        }
        else {
            return (
                <div className="container">
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className="row">
                        <div className="col-lg-4">
                            <img
                                src={Api + process.env.REACT_APP_LINK_RUTA_IMG_VEHICULO + 
                                    vehiculo.num_economico + "/" + vehiculo.imagen}
                                className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                                alt="Vehículo" />
                        </div>
                        <div className="col-lg-8">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Marca</th>
                                        <th>Año</th>
                                        <th>Kilometraje Actual</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Datos av={"marca"} vv={vehiculo} /></td>
                                        <td><Datos av={"ano"} vv={vehiculo} /></td>
                                        <td><Datos av={"km"} vv={vehiculo} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Modelo</th>
                                        <th>Tipo</th>
                                        <th>Capacidad de carga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Datos av={"modelo"} vv={vehiculo} /></td>
                                        <td><Datos av={"categoria"} vv={vehiculo} /></td>
                                        <td><Datos av={"cap_carga"} vv={vehiculo} /> <ConvMedida unidad={vehiculo ? vehiculo.medida_carga : ""} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Capacidad de combustible</th>
                                                <th>Tipo de combustible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Datos av={"cap_combustible"} vv={vehiculo} /> litros</td>
                                                <td><Datos av={"tipo_combustible"} vv={vehiculo} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <MapComponent markers={coordenadas} />
                    </Row>
                    <br />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Conductor asignado</th>
                                <th>Dirección o ruta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataProcesada && dataProcesada.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.usuario}</td>
                                    <td>{item.lugares}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <Row>
                        {(existsInPagFlotilla(pagFlotilla, 3, "Reportes") || existsInPagFlotilla(pagFlotilla, 1, "Reportes")
                            || existsInPagFlotilla(pagFlotilla, 2, "Reportes"))
                            &&
                            <BtnMenu t={"Bitacora de mantenimiento"} link={"/flotilla/bitacora-de-mantenimiento"} img={checklist} />}
                        {(existsInPagFlotilla(pagFlotilla, 3, "Reportes") || existsInPagFlotilla(pagFlotilla, 1, "Reportes")
                            || existsInPagFlotilla(pagFlotilla, 2, "Reportes"))
                            &&
                            <BtnMenu t={"Reporte"} link={"/flotilla/reporte"} img={reporte} />}
                        {(existsInPagFlotilla(pagFlotilla, 3, "Vehiculo"))
                            &&
                            <BtnMenu t={"Viajes y ubicaciones"} link={"/flotilla/viajes-y-ubicaciones/" + vehiculo.id} img={viajes} />}
                    </Row>
                    <br />
                    {(existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) &&
                        <Row>
                            <BtnMenu t={"Documentación"} link={"/flotilla/documentos/" + vehiculo.id} img={documentacion} />
                            { /*<BtnMenu t={"Historial"} link={"/flotilla/historial"} img={historial} />*/}
                            <BtnMenu t={"Estado"} link={"/flotilla/reporte/tecnico"} img={estado} />
                            <BtnMenu t={"Procedimiento"} link={"/flotilla/procedimiento"} img={procedimiento} />
                        </Row>}
                    <br />
                    {/*<Row>
                        {(existsInPagFlotilla(pagFlotilla, 1, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 4, "Vehiculo"))
                            &&
                            <BtnMenu t={"Procedimiento"} link={"/flotilla/procedimiento"} img={procedimiento} />}
                            <BtnMenu t={"Geocerca"} link={"/flotilla/vehiculo/geocerca"} img={geocerca} />
                            <BtnMenu t={"Futuras actualizaciones"} link={"/flotilla"} img={futuras} />
                    </Row>*/}
                </div>

            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Vehicle_flotilla;