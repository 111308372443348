//  Librarys
import React, { useState } from 'react';
import {
    Navbar,
    Nav,
    Col,
    Container,
    Row,
    NavDropdown,
    Form,
    Button,
    Dropdown,
} from 'react-bootstrap';
import { useEffect } from 'react';

// Images
import logorace from "@/images/racecarreteras logo.png";
import usuario from "@/images/usuario.png";
import { Link } from 'react-router-dom';
import Select_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Select_bar_flotilla';

// Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";

function Nav_bar_flotilla() {
    //Variables externas
    const {
        userID,
        setUserID,
        userName,
        setUserName,
        jerarquia,
        setJerarquia,
        pagFlotilla
    } = useUser();
    // Variables internas
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [img, setImg] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const routes = [
        { path: '/', name: 'Central' },
        { path: '/flotilla', name: 'Inicio' },
        { path: '/flotilla/reporte', name: 'Reportes' },
        { path: '/flotilla/perfil', name: 'Perfil de usuario' },
        { path: '/flotilla/calendario', name: 'Calendario' },
        { path: '/flotilla/graficos', name: 'Graficos' },
        { path: '/flotilla/calculadora', name: 'Calculadora' },
        { path: '/flotilla/bitacora-de-mantenimiento', name: 'Bitacora de mantenimiento' },
        { path: '/flotilla/reporte/fallas-incidencias-ocurrencias', name: 'Bitacora de fallas incidencias u ocurrencias' },
        { path: '/flotilla/reporte/tecnico', name: 'Bitacora del estado actual del vehiculo' },
    ];


    const filteredRoutes = searchTerm.length > 0
        ? routes.filter(route => route.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const loadData = () => {
        fetch(Api + "usuarios.php?id_usuario=" + userID + "&perfil=" + 'Imagen')
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setImg(responseData.perfil[0]);
            })
            .catch(console.log);
    };

    //Revisa estado del usuario
    useEffect(() => {
        // Redirige si todas las variables de estado necesarias no están establecidas
        if (!userID || !userName || !jerarquia) {
            // Si alguno de los valores es falso o no está definido, redirige a la página de login
            window.location.href = "/login";
        }
        loadData();
    }, [userID, userName, jerarquia]);

    // Componente
    const UserMenu = () => {
        //Variables externas
        return (
            <div
                style={{
                    position: 'absolute', // This positions the menu absolutely
                    top: '100%', // This positions the menu below the button
                    right: 0, // This aligns the menu to the right
                    zIndex: 1000 // This ensures the menu stacks on top of other content
                }}
                className="user-menu" onMouseLeave={(e) => setIsVisible2(false)}>
                <ul className="list-group">
                    <Link to="/soporte-tecnico" className="list-group-item">Soporte técnico</Link>
                    <Link to="/manual-usuario" className="list-group-item">Manual de usuario</Link>
                    <Link to="/alertas-notificaciones" className="list-group-item">Alerta y notificaciones</Link>
                    <Link to="/flotilla/perfil" className="list-group-item">Perfil de usuario</Link>
                    {(jerarquia == 'Root' || jerarquia == 'Administrador' || existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios")) &&
                        (<Link to="flotilla/control-de-usuarios" className="list-group-item">Control de usuario</Link>)}
                    <Link
                        onClick={(e) => {
                            setUserID(null);
                            setUserName(null);
                            setJerarquia(null);
                        }}
                        className="list-group-item"
                    >Cerrar sesión
                    </Link>
                </ul>
            </div>
        );
    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];

    return (
        <>
            {/* First bar oriented for Seach bar */}
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/flotilla">
                        <div className="brand">
                            <img src={logorace} alt="Race Carreteras S.A. de C.V." className="brand-image" />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" className='w-100 align-middle'>
                        <div className='dropdown w-100'>
                            <Form className="d-flex ms-3 me-3 w-100">
                                <Form.Control
                                    type="search"
                                    placeholder="Escriba alguna palabra"
                                    className="me-2"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        setShowDropdown(e.target.value.length > 0);
                                    }}
                                />

                            </Form>
                            {showDropdown && (
                                <Dropdown.Menu show className="w-100"> {/* Asegúrate de que el ancho sea el adecuado */}
                                    {filteredRoutes.map((route, index) => (
                                        <Dropdown.Item key={index} href={route.path}>
                                            {route.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            )}
                        </div>
                        <div className='chat-Icons' style={{ position: 'relative' }}>
                            {img ? (
                                <img
                                    src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/img/" + img.img}
                                    className="brand-image rounded-circle border border-dark"
                                    alt="Imagen cargada"
                                    onMouseOver={() => setIsVisible2(true)}
                                    onError={(e) => e.target.src = usuario}  // Aquí agregamos el manejo de error
                                />
                            ) : (
                                <img
                                    src={usuario}
                                    alt="Imagen del usuario"
                                    className="brand-image rounded-circle border border-dark"
                                    onMouseOver={() => setIsVisible2(true)}
                                />
                            )}

                            {isVisible2 && <UserMenu />}
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* Second bar oriented for menu*/}

            <Container fluid>
                <Row className='objectsWhite'>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {(existsInPagFlotilla(pagFlotilla, 1, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 2, "Vehiculo") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) &&
                            <Link className='active hover-Bar' onMouseOver={() => setIsVisible(true)} >Vehiculo</Link>}
                        {(existsInPagFlotilla(pagFlotilla, 1, "Calendario") || existsInPagFlotilla(pagFlotilla, 2, "Calendario") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Calendario") || existsInPagFlotilla(pagFlotilla, 3, "Calendario")) &&
                            <Link to={'/flotilla/calendario'} className='hover-Bar' onMouseOver={() => setIsVisible(true)}>
                                Calendario</Link>}
                        {(existsInPagFlotilla(pagFlotilla, 1, "Control de usuarios") || existsInPagFlotilla(pagFlotilla, 2, "Control de usuarios") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Control de usuarios") || existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios")) &&
                            <Link to={'/flotilla/control-de-usuarios'} className='hover-Bar' onMouseOver={() => setIsVisible(true)}>
                                Control de usuario</Link>}
                        {(existsInPagFlotilla(pagFlotilla, 1, "Reportes") || existsInPagFlotilla(pagFlotilla, 2, "Reportes") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Reportes") || existsInPagFlotilla(pagFlotilla, 3, "Reportes")) &&
                            <Link to={'/flotilla/reporte'} className='hover-Bar' onMouseOver={() => setIsVisible(true)}>
                                Reporte</Link>}
                        {(existsInPagFlotilla(pagFlotilla, 1, "Graficos") || existsInPagFlotilla(pagFlotilla, 2, "Graficos") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Graficos") || existsInPagFlotilla(pagFlotilla, 3, "Graficos")) &&
                            <Link to={'/flotilla/graficos'} className='hover-Bar' onMouseOver={() => setIsVisible(true)}>
                                Graficos</Link>}
                        {/*(existsInPagFlotilla(pagFlotilla, 1, "Calculadora") || existsInPagFlotilla(pagFlotilla, 2, "Calculadora") ||
                            existsInPagFlotilla(pagFlotilla, 4, "Calculadora") || existsInPagFlotilla(pagFlotilla, 3, "Calculadora")) &&
                            <Link to={'/flotilla/calculadora'} className='hover-Bar' onMouseOver={() => setIsVisible(true)} >
                                Calculadora</Link>*/}
                    </Nav>
                </Row>
                <Row>
                    <Col sm>
                        <Container fluid>
                            <Row>

                                <Col sm>
                                    <Container className='menu-content'
                                        onMouseOver={() => setIsVisible(true)}
                                        onMouseOut={() => setIsVisible(false)}>
                                        {isVisible && (<Select_bar_flotilla />)}
                                    </Container>
                                </Col>

                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

        </>
    );
}

export default Nav_bar_flotilla;