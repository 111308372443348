//Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

//Images
import car1 from '@/images/Car1.jpeg';
import car2 from '@/images/Car2.jpeg';
import car3 from '@/images/Car3.jpeg';
import car4 from '@/images/Car4.jpeg';

function getDateTime() {
    const now = new Date();

    // Obtenemos las partes de la fecha y hora
    const año = now.getFullYear();
    const mes = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque los meses en JavaScript comienzan desde 0
    const dia = String(now.getDate()).padStart(2, '0');
    const hora = String(now.getHours()).padStart(2, '0');
    const minutos = String(now.getMinutes()).padStart(2, '0');
    const segundos = String(now.getSeconds()).padStart(2, '0');

    // Formateamos la fecha y hora
    const fechaYHora = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

    return fechaYHora;
}

const Fails_new_flotilla = () => {

    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [checkboxValues2, setCheckboxValues2] = useState([]);
    const [firma, setFirma] = useState(null);
    const sigCanvas = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [listaFirma, setListaFirma] = useState([]);
    const [verificado, setVerificado] = useState(null);
    const [vistoBueno, setVistoBueno] = useState(null);
    const [usuario, setUsuario] = useState([]);
    const [Fecha, setFecha] = useState(getDateTime());
    const [descripcion, setDescripcion] = useState(null);

    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [img3, setImg3] = useState(null);
    const [img4, setImg4] = useState(null);



    const [condicion, setCondicion] = useState(null);

    // Función asincrona
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirma(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirma(null);
    };

    useEffect(() => {
        fetch(Api + "automovil-flotilla.php/?todos")
            .then(response => response.json())
            .then((responseData) => {
                // console.log(responseData);
                setLoadedData(true);
                setVehiculo(responseData);
            })
            .catch(console.log)

        fetch(Api + "usuarios.php/?listaus")
            .then(response2 => response2.json())
            .then((responseData2) => {
                // console.log(responseData2);
                setListaFirma(responseData2.data);


            })
            .catch(console.log)

        fetch(Api + "usuarios.php/?listaid=" + userID)
            .then(response3 => response3.json())
            .then((responseData3) => {
                // console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)


    }, []);


    const handleCheckboxChange2 = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            // Si el checkbox está marcado, agrega el valor al array
            setCheckboxValues2([...checkboxValues2, value]);
        } else {
            // Si el checkbox se desmarca, elimina el valor del array
            setCheckboxValues2(checkboxValues2.filter((item) => item !== value));
        }
    };

    const valueChange = (e) => {
        const newState = { ...selectedVehicle }; // Crear una copia del estado vehiculo
        newState[e.target.name] = e.target.value;
        setSelectedVehicle(newState); // Actualizar el estado con la nueva información
    }

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag }) => (
        <Row>
            <Col sm={2}><label htmlFor="">{tg}</label></Col>
            <Col sm={4}>
                <p className="card-text d-inline">
                    {selectedVehicle ? selectedVehicle[ag] : ""}
                </p>
            </Col>
            <Col sm={2}><label htmlFor=""></label></Col>
            <Col sm>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="condicion"
                        id="condicion"
                        value={tv}
                        checked={condicion === tv}
                        onChange={(e) => setCondicion(e.target.value)}
                    />
                    <p className="card-text d-inline">
                        &nbsp;{tv}
                    </p>
                </div>
            </Col>
        </Row>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name1, name2, name3 }) => (
        <tr>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name1}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={name1}
                            onChange={handleCheckboxChange2}
                            checked={checkboxValues2.includes(name1)}
                        />
                    </div>
                </Row>
            </td>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name2}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={name2}
                            onChange={handleCheckboxChange2}
                            checked={checkboxValues2.includes(name2)}
                        />
                    </div>
                </Row>
            </td>
            <td>
                <Row className='container'>
                    <div className="form-check">
                        <label className="card-text" htmlFor="">
                            &nbsp;{name3}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={name3}
                            onChange={handleCheckboxChange2}
                            checked={checkboxValues2.includes(name3)}
                        />
                    </div>
                </Row>
            </td>
        </tr>
    );

    //Componente imagen Input
    const handleImageChange = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Aquí guardamos el archivo directamente
        }
    };


    const InputImage = ({ nomVar, nomSet, img }) => {
        return (
            <td>
                {
                    nomVar ?
                        <img
                            src={URL.createObjectURL(nomVar)} // Creamos una URL temporal para mostrar la imagen
                            className="img-fluid rounded"
                            alt="Imagen cargada"
                        />
                        :
                        <img
                            src={img}
                            className="img-fluid rounded"
                            alt="No se ha podido cargar su imagen"
                        />
                }

                <input
                    type="file"
                    onChange={(e) => handleImageChange(e, nomSet)}
                    name="nomVar"
                    id="nomVar"
                    accept="image/*"
                    value={nomSet}
                    className={`form-control ${nomVar == null || nomVar == '' ? "is-invalid" : ""}`}
                />
            </td>
        );
    };

    const sendData = (e) => {

        e.preventDefault();

        if (img1 == null || img1 == "") {
            window.alert("Agregar la imagen superior izquierda.");
            return;
        }
        if (img2 == null || img2 == "") {
            window.alert("Agregar la imagen superior derecha.");
            return;
        }
        if (img3 == null || img3 == "") {
            window.alert("Agregar la imagen inferior izquierda.");
            return;
        }
        if (img4 == null || img4 == "") {
            window.alert("Agregar la imagen inferior derecha.");
            return;
        }

        /*
        console.log(userID);
        console.log(folio);
        console.log(selectedVehicle.id)
        console.log(selectedVehicle.odometro);
        console.log(condicion);
        console.log(checkboxValues2);
        console.log(descripcion);
        console.log(img1);
        console.log(img2);
        console.log(img3);
        console.log(img4);
        console.log(vistoBueno);
        console.log(verificado);
        console.log(firma);
        */

        if (
            userID &&
            selectedVehicle && selectedVehicle.id &&
            condicion &&
            checkboxValues2.length > 0 && // Verificamos que el array no esté vacío
            selectedVehicle && selectedVehicle.odometro &&
            verificado &&
            vistoBueno &&
            descripcion &&
            firma
        ) {
            // verificar parametros necesarios

            if (btnCheck) {
                if (firma == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {

                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("id", selectedVehicle.id);
                    formData.append("checkboxValues2", JSON.stringify(checkboxValues2));
                    formData.append("img1", img1);
                    formData.append("img2", img2);
                    formData.append("img3", img3);
                    formData.append("img4", img4);
                    formData.append("id_verificado", verificado);
                    formData.append("id_vistobueno", vistoBueno);
                    formData.append("firma", firma);
                    formData.append("odoActual", selectedVehicle.odometro);
                    formData.append("condicion", condicion);
                    formData.append("descripcion", descripcion);

                    fetch(Api + "/reportes.php?insreporfio=1", {
                        method: "POST",
                        body: formData,
                    }).then(response => response.json())
                        .then((responseDataArray) => {
                            console.log(responseDataArray);

                            let validSuccessValues = [1, 2];
                            let hasAllSuccesses = validSuccessValues.every(val =>
                                responseDataArray.some(response => response.success === val)
                            );

                            if (hasAllSuccesses) {
                                window.alert('Su reporte ha sido cargado');
                                window.location.href = "/flotilla/reporte/fallas-incidencias-ocurrencias";
                            } else {
                                window.alert('Lo lamentamos, no se ha podido subir su reporte. Inténtelo de nuevo');
                            }

                        }).catch(console.log);

                }
            } else {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }

    };

    const { pagFlotilla, userID } = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];


    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }
    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        <h2>Agrega un nuevo reporte de Fallas, Incidencias u Ocurrencias</h2>
                        {CRUDUser[3] === 4 && (<div className="card">

                            <form onSubmit={sendData}>
                                <div className="card-header">
                                    <h4>Reporte de Fallas, Incidencias u Ocurrencias</h4>
                                </div>
                                <div className="card-body">
                                    <Row>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="">Vehículo o plataforma</label>
                                                <select
                                                    className="custom-select form-control"
                                                    value={selectedVehicle ? selectedVehicle.id : ""}
                                                    onChange={(e) => {
                                                        const selectedId = e.target.value;
                                                        const selected = vehiculo.find((v) => v.id === selectedId);
                                                        setSelectedVehicle(selected);
                                                    }}
                                                    name="vehiculo"
                                                    id="vehiculo"
                                                    required >
                                                    <option value="">Seleccione un vehículo o plataforma</option>
                                                    <optgroup label="Vehículos o plataformas">
                                                        {vehiculo.map(
                                                            (vehiculo) => (
                                                                <option key={vehiculo.id} value={vehiculo.id}>
                                                                    {vehiculo.vehiculo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                    <option value="Otros">Otros</option>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione la categoría del vehículo
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm></Col>
                                        <Col sm={4}>

                                            <div className="form-group">
                                                <label htmlFor="">Fecha de modificación</label>
                                                <p className="card-text">{Fecha}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm>
                                            <h3>DATOS GENERALES</h3>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={2}><label htmlFor="">TRANSPORTE:</label></Col>
                                        <Col sm={4}>
                                            <div className="form-group d-flex align-items-center">
                                                <div className="form-check form-check-inline">
                                                    <p className="card-text d-inline">&nbsp;Vehículo</p>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Compacto"
                                                        checked={selectedVehicle &&
                                                            (selectedVehicle.categoria === "Compacto" || selectedVehicle.categoria === "Motocicleta"
                                                                || selectedVehicle.categoria === "Pick-Up" || selectedVehicle.categoria === "SUV"
                                                                || selectedVehicle.categoria === "Sedan" || selectedVehicle.categoria === "Vehículo ligero"
                                                                || selectedVehicle.categoria === "Pick-up")}
                                                    />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <p className="card-text d-inline">&nbsp;Maquinaria o vehículo pesado</p>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Vehículo pesado inline"
                                                        checked={selectedVehicle &&
                                                            (selectedVehicle.categoria === "Vehículo pesado" || selectedVehicle.categoria === "Camión"
                                                                || selectedVehicle.categoria === "Furgoneta" || selectedVehicle.categoria === "Plataforma"
                                                                || selectedVehicle.categoria === "Remolque")}
                                                    />
                                                </div>
                                                <div className="form-check">
                                                    <p className="card-text d-inline">&nbsp;Otros</p>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Otros"
                                                        checked={selectedVehicle && (selectedVehicle.categoria === "Otros" || selectedVehicle.categoria === "otros")}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={2}><label htmlFor="">USUARIO:</label></Col>
                                        <Col>
                                            <p className="card-text d-inline">
                                                {usuario ? usuario.nombre + " " + usuario.ap_paterno + " " + usuario.ap_materno : ""}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={2}><label htmlFor="">MODELO:</label></Col>
                                        <Col sm={4}>
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.modelo : ""}
                                            </p>
                                        </Col>
                                        <Col sm={2}><label htmlFor="">TIPO DE CONDICIÓN:</label></Col>
                                        <Col sm>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="condicion"
                                                    id="condicion"
                                                    value="Normal"
                                                    checked={condicion === "Normal"}
                                                    onChange={(e) => setCondicion(e.target.value)}
                                                />
                                                <p className="card-text d-inline">
                                                    &nbsp;Normal
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <DatosGV tg="PLACAS:" tv="Regular" ag="placas" />
                                    <DatosGV tg="NIV:" tv="Medio Regular" ag="niv" />
                                    <Row>

                                        <Col sm={2}><label htmlFor="">ODÓMETRO, KM:</label></Col>
                                        <Col sm={4}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="odometro"
                                                    id="odometro"
                                                    value={selectedVehicle ? selectedVehicle.odometro : ""}
                                                    className={`form-control w-50 ${(selectedVehicle && (selectedVehicle.odometro === '' || selectedVehicle.odometro === null)) ? "is-invalid" : ""}`}
                                                    placeholder="Indique la lectura de su odómetro"
                                                    aria-describedby="helpId"
                                                    pattern="^\d+(\.\d)?$"
                                                    onChange={valueChange}
                                                    title="Por favor, ingrese un número entero o un número con hasta un decimal."
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={2}><label htmlFor=""></label></Col>
                                        <Col sm>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="condicion"
                                                    id="condicion"
                                                    value="Mala"
                                                    checked={condicion === "Mala"}
                                                    onChange={(e) => setCondicion(e.target.value)}
                                                />
                                                <p className="card-text d-inline">
                                                    &nbsp;Mala
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <DatosGV tg="NÚM, ECONÓMICO:" tv="Desastrozo" ag="neconomico" />

                                    <Row>

                                        <Col sm={8}>


                                            <div className="form-group">
                                                <p className="card-text d-inline">
                                                    {selectedVehicle ? selectedVehicle.neconomico : ""}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <div className="table-responsive">
                                            <table className="table table table-bordered table-hover">
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th colSpan={3} className='text-center'>TIPO DE FALLA, INCIDENTE U OCURRENCIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbodyy'>
                                                    <InputCheck2 name1="Luces Rotas" name2="Choque" name3="Multa" />
                                                    <InputCheck2 name1="Neumáticos Desinflados" name2="Transporte no Arranca" name3="Robo de Transporte" />
                                                    <InputCheck2 name1="Batería Muerta" name2="Falla de Bomba de Aceite" name3="Mala Lubricación" />
                                                    <InputCheck2 name1="Sobrecalentamiento" name2="Falla de Sistema Eléctrico" name3="Ruidos Extraños" />
                                                    <InputCheck2 name1="Frenos Defectuosos" name2="Problemas con la Gasolina" name3="Falla de Dirección" />
                                                    <InputCheck2 name1="Fugas" name2="Falla de Transmisión" name3="Otros" />
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div class="form-group">
                                                                <label htmlFor="">DESCRIPCIÓN</label>
                                                                <textarea
                                                                    name="descripcion"
                                                                    id="descripcion"
                                                                    placeholder='Describa la situación acontencida'
                                                                    required
                                                                    minLength={1}
                                                                    maxLength={100}
                                                                    rows="3"
                                                                    className={`form-control ${descripcion == null || descripcion == '' ? "is-invalid" : ""}`}
                                                                    value={descripcion}
                                                                    onChange={(e) => setDescripcion(e.target.value)}
                                                                    pattern="^[^'\;--%/]*$" title="Caracteres especiales no permitidos."
                                                                >
                                                                </textarea>
                                                                <small id="helpId" className="form-text text-muted">*Describa la situación en la que el vehículo se encuentra</small>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="table-responsive">
                                            <table className="table table table-bordered table-hover">
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th colSpan={3} className='text-center'>EVIDENCIA DEL ESTADO DEL VEHÍCULO</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbodyy'>
                                                    <tr className='table-light'>
                                                        <InputImage nomVar={img1} nomSet={setImg1} img={car1} />
                                                        <InputImage nomVar={img2} nomSet={setImg2} img={car2} />
                                                    </tr>
                                                    <tr className='table-light'>
                                                        <InputImage nomVar={img3} nomSet={setImg3} img={car4} />
                                                        <InputImage nomVar={img4} nomSet={setImg4} img={car3} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="firmaVer">Firma de verificado</label>
                                                <select
                                                    className="custom-select form-control"
                                                    required
                                                    onChange={e => setVerificado(e.target.value)}  // Agregar esta línea
                                                >
                                                    <option value="">Seleccione una persona que pueda firmar</option>
                                                    <optgroup label="Firmante para verificar el reporte">
                                                        {listaFirma.map(
                                                            (listaFirma) => (
                                                                <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                    {listaFirma.nombre_completo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione a la persona que firmará de verificado.
                                                </small>
                                            </div>
                                        </Col>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="firmaVO">Firma de visto bueno</label>
                                                <select
                                                    className="custom-select form-control"
                                                    required
                                                    onChange={e => setVistoBueno(e.target.value)}  // Agregar esta línea
                                                >
                                                    <option value="">Seleccione una persona que pueda firmar</option>
                                                    <optgroup label="Firmante para visto bueno el reporte">
                                                        {listaFirma.map(
                                                            (listaFirma) => (
                                                                <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                    {listaFirma.nombre_completo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione a la persona que firmará de visto bueno.
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <br />
                                        <div className="mb-3 text-center">
                                            <label className="form-label">Firma</label>
                                            <br />
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                canvasProps={{
                                                    className: 'signature-canvas'
                                                }}

                                            />
                                            <br />
                                            <button type="button" onClick={saveSignature}>Guardar Firma</button>
                                            <button type="button" onClick={clearSignature}>Borrar Firma</button>
                                            <p className="form-text text-muted">
                                                *Dibuja tu firma en el espacio de arriba.
                                            </p>
                                        </div>

                                    </Row>
                                </div>
                                <div className="card-footer text-muted">
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit"
                                            className="btn btn-success"
                                        >
                                            Agregar
                                        </button>
                                        <Link className="btn btn-danger text-white" to="/flotilla/reporte/fallas-incidencias-ocurrencias">Regresar</Link>
                                    </div>
                                </div>
                            </form>
                        </div>)
                        }
                    </div >
                </div >
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Fails_new_flotilla;