//Librarys
import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";

//Components
import Api from "@/services/api";

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const Excel_bitacora = ({ id }) => {

    const [loadedData, setLoadedData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [primeraConsulta, setPrimeraConsulta] = useState(null);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [apocoSiTilin, setTilin] = useState([]);

    useEffect(() => {
        console.log(id);

        fetch(Api + "reportes.php?repvehvis=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setTilin(responseData);
                setPrimeraConsulta(responseData.primera_consulta);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);

    }, []);

    const handleDownload = async () => {
        setLoading(true);
        const libro = XLSX.utils.book_new();
    
        // Agrega la primera consulta
        const hoja1 = XLSX.utils.json_to_sheet(primeraConsulta);
        XLSX.utils.book_append_sheet(libro, hoja1, "INFORMACIÓN");
    
        // Agrega la segunda consulta
        const hoja2 = XLSX.utils.json_to_sheet(segundaConsulta);
        XLSX.utils.book_append_sheet(libro, hoja2, "MANTENIMIENTO");
    
        // Agrega la tercera consulta
        const hoja3 = XLSX.utils.json_to_sheet(terceraConsulta);
        XLSX.utils.book_append_sheet(libro, hoja3, "PIEZA");
    
        await new Promise(resolve => setTimeout(resolve, 1000)); // Espera 1 segundo
    
        XLSX.writeFile(libro, "Bitacora de mantenimiento "+id+".xlsx");
        setLoading(false);
        window.alert('Archivo descargado');
    };
    

    if (!loadedData) {
        return (
            <p>Información exportándose a archivo XLSX.</p>
        );
    } else {
        return (
            <div>
                {loading ? (
                    <p>Descargando archivo...</p>
                ) : (
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={handleDownload}
                    >Descargar archivo
                    </button>
                )}
            </div>
        );
    }
};


export default Excel_bitacora;
