import React, { Component } from "react";

class Type_calendar extends Component {
  state = {
    searchQuery: ""
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    const { searchQuery } = this.state;

    const options = [
      "Asunto general",
      "Urgencia",
      "Mantenimiento"
    ];

    return (
      <div>
        
        <select
          className="form-control"
          value={searchQuery}
          onChange={this.handleSearchChange}
        >
          <option value="">Selecciona una opción...</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <p>Seleccionaste: {searchQuery}</p>
      </div>
    );
  }
}

export default Type_calendar;