//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

//Components
import Loading_papu from "@/components/general/Loading_papu";

const InputInfoUser = ({ disable, onInputChange, data, view, account }) => {

    // Inicializar 'variable' como un objeto con las claves para cada input
    const [showPassword, setShowPassword] = useState(false);
    const [mostrarCampoEmpresa, setMostrarCampoEmpresa] = useState(false);
    const { jerarquia } = useUser();
    const [loadedData, setLoadedData] = useState(false);
    const [datos, setDatos] = useState(null);

    const [variable, setVariable] = useState({
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        pass: '',
        apodo: '',
        email: '',
        empresa: '',
        jerarquia: '',
        depa: '',
        pue: ''
    });


    const handleChange = (e) => {
        // Actualizar solo el campo correspondiente en el objeto 'variable'
        const newVariable = { ...variable, [e.target.name]: e.target.value };
        setVariable(newVariable);
        onInputChange(newVariable); // Enviar el objeto actualizado al padre
        const { name, value } = e.target;

        if (name === 'empresa') {
            setMostrarCampoEmpresa(value === 'otros');
        }
        onInputChange(newVariable);
    };

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword => !showPassword);
    };

    const loadData = () => {
        fetch(Api + "usuarios.php/?doc_import2=")
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setDatos(responseData);

                if (view) {
                    setVariable({
                        nombre: view.nombre || '',
                        apellidoPaterno: view.apaterno || '',
                        apellidoMaterno: view.amaterno || '',
                        pass: view.contra || '',
                        apodo: view.usuario || '',
                        email: view.correo || '',
                        empresa: view.emp_nombre || '',
                        jerarquia: view.jerarquia || '',
                        depa: view.id_depa || '',
                        pue: view.id_pue || ''
                    });

                    setLoadedData(true);
                } else {
                    setLoadedData(true);
                }

            })
            .catch(console.log)
    };

    useEffect(() => {
        loadData();
    }, [view]); // Se ejecuta sólo cuando 'view' cambia

    if (loadedData) {

        return (
            <div>
                <div className="card-body">
                    <Row>
                        <Col>
                            <label htmlFor={variable.nombre} >Nombre:</label>
                            <div className="input-group">
                                <span className="input-group-text w-25">Nombre completo</span>
                                <input
                                    type="text"
                                    className={`form-control w-25 ${!disable && (!variable.nombre ? "is-invalid w-25" : "")}`}
                                    value={variable.nombre}
                                    name="nombre"
                                    id="nombre"
                                    autoComplete='off'
                                    aria-describedby="helpId"
                                    required
                                    placeholder="Nombre"
                                    minLength={1}
                                    maxLength={45}
                                    onChange={handleChange}
                                    readOnly={disable}
                                />
                                <input
                                    type="text"
                                    className={`form-control w-25 ${!disable && (!variable.apellidoPaterno ? "is-invalid w-25" : "")}`}
                                    value={variable.apellidoPaterno}
                                    name="apellidoPaterno"
                                    id="apellidoPaterno"
                                    autoComplete='off'
                                    required
                                    placeholder="Apellido Paterno"
                                    minLength={1}
                                    maxLength={45}
                                    onChange={handleChange}
                                    readOnly={disable}
                                />
                                <input
                                    type="text"
                                    className="form-control w-25"
                                    value={variable.apellidoMaterno}
                                    name="apellidoMaterno"
                                    id="apellidoMaterno"
                                    autoComplete='off'
                                    placeholder="Materno"
                                    minLength={1}
                                    maxLength={45}
                                    onChange={handleChange}
                                    readOnly={disable}
                                />
                            </div>
                            {!disable ? <small id="helpId" className="form-text text-muted">*Ingrese el nombre del usuario</small> : <br />}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor={variable.apodo} >Nombre de usuario (Apodo o Alias):</label>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    value={variable.apodo}
                                    name="apodo"
                                    autoComplete='off'
                                    id="apodo"
                                    aria-describedby="apodo"
                                    placeholder="Alias, apodo o sobrenombre del usuario"
                                    minLength={1}
                                    maxLength={20}
                                    onChange={handleChange}
                                    readOnly={disable}
                                />
                                {!disable ? <small id="helpId" className="form-text text-muted">Apodo o sobrenombre del usuario</small> : <br />}
                            </div>
                        </Col>
                    </Row>
                    {!account && (<Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="">Jerarquía</label>
                                <select
                                    className={`custom-select form-control ${!disable && (!variable.jerarquia && 'custom-select is-invalid')}`}
                                    value={variable.jerarquia}
                                    onChange={handleChange}
                                    name="jerarquia"
                                    id="jerarquia"
                                    autoComplete='off'
                                    disabled={disable}
                                    required >
                                    <option value="">Seleccione la jerarquía que el usuario tendrá</option>
                                    <optgroup label="jerarquia dentro de la aplicación web">
                                        <option value="Usuario">
                                            Usuario
                                        </option>
                                        {(jerarquia == 'Root' || jerarquia == 'Administrador') && (<option value="Administrador">
                                            Administrador
                                        </option>)}
                                    </optgroup>
                                    {view && view.jerarquia == 'Root' &&
                                        (<option value={view.jerarquia}>
                                            {view.jerarquia}
                                        </option>)
                                    }
                                </select>
                                {!disable ? <small id="helpId" className="form-text text-muted">
                                    *Seleccione la jerarquía que el usuario tendrá.
                                </small> : <br />}
                            </div>
                        </Col>
                    </Row>)}
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email">Correo Electrónico:</label>
                                <input
                                    type="email"
                                    className={`form-control ${!disable && (!variable.email && 'is-invalid')}`}
                                    value={variable.email}
                                    name="email"
                                    id="email"
                                    autoComplete='off'
                                    aria-describedby="emailHelp"
                                    placeholder="tu@correo.com"
                                    onChange={handleChange}
                                // Puedes agregar 'required' si deseas que sea un campo obligatorio
                                />
                                {!disable ? (
                                    <>
                                        <small id="emailHelp" className="form-text text-muted">
                                            *Ingrese el correo electrónico del usuario.
                                        </small>
                                        {/* Muestra un mensaje de error si el email no es válido */}
                                        {!variable.email && <div className="invalid-feedback">Por favor ingresa un correo electrónico válido.</div>}
                                    </>
                                )
                                    : <br />}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="pass">Contraseña del usuario:</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className={`form-control w-75 ${!disable && (!variable.pass ? "is-invalid w-75" : "")}`}
                                        value={variable.pass}
                                        name="pass"
                                        autoComplete='off'
                                        id="pass"
                                        aria-describedby="helpId"
                                        required
                                        placeholder="Contraseña"
                                        minLength={7}
                                        maxLength={20}
                                        onChange={handleChange}
                                        readOnly={disable}
                                    />
                                    {!disable && (<div className="input-group-append" style={{ height: "7vh" }}>
                                        <button className="btn btn-outline-secondary h-100" type="button" onClick={togglePasswordVisiblity}>
                                            <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                        </button>
                                    </div>)}
                                </div>
                                {!disable ? <small id="helpId" className="form-text text-muted">*Contraseña del usuario</small> : <br />}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="card-body">
                    <Row>
                        {mostrarCampoEmpresa && (
                            <Col>

                                <div className="form-group">
                                    <label htmlFor="empresaTexto">Nombre de la empresa:</label>
                                    <input
                                        type="text"
                                        className={`form-control ${!disable && (!variable.nombreEmpresa && 'is-invalid')}`}
                                        value={variable.nombreEmpresa}
                                        onChange={handleChange}
                                        required
                                        autoComplete='off'
                                        name="nombreEmpresa"
                                        id="empresaTexto"
                                        readOnly={disable}
                                    />
                                </div>
                            </Col>
                        )}
                        <Col>
                            <div className="form-group">
                                <label htmlFor="">Empresa</label>
                                <select
                                    className={`custom-select form-control ${!disable && (!variable.empresa && 'custom-select is-invalid')}`}
                                    value={variable.empresa}
                                    onChange={handleChange}
                                    name="empresa"
                                    autoComplete='off'
                                    id="empresa"
                                    disabled={disable}
                                    required >

                                    <option value="">Seleccione una empresa, o en su caso escribala</option>
                                    <optgroup label="Empresa registrada">
                                        {data.map(
                                            (item) => (
                                                <option key={item.id} value={item.empresa}>
                                                    {item.empresa}
                                                </option>
                                            ))}
                                    </optgroup>
                                    <optgroup label="Empresa registrada">
                                        <option value="otros">Escriba la empresa</option>
                                    </optgroup>
                                </select>
                                {!disable ? <small id="helpId" className="form-text text-muted">
                                    *Seleccione o escriba la empresa correspondiente.
                                </small> : <br />}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="depa">Departarmento</label>
                                <select
                                    className={`custom-select form-control ${!disable && (!variable.empresa && 'custom-select is-invalid')}`}
                                    value={variable.depa}
                                    onChange={handleChange}
                                    name="depa"
                                    autoComplete='off'
                                    id="depa"
                                    disabled={disable}
                                    required >

                                    <option value="">Seleccione un departamento disponible</option>
                                    {datos.quinta_consulta.map(
                                        (item) => (
                                            <option key={item.id_depa} value={item.id_depa}>
                                                {item.dep_nombre}
                                            </option>
                                        ))}
                                </select>
                                {!disable ? <small id="helpId" className="form-text text-muted">
                                    *Seleccione el departamiento correspondiente.
                                </small> : <br />}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="pue">Puesto</label>
                                <select
                                    className={`custom-select form-control ${!disable && (!variable.empresa && 'custom-select is-invalid')}`}
                                    value={variable.pue}
                                    onChange={handleChange}
                                    name="pue"
                                    autoComplete='off'
                                    id="pue"
                                    disabled={disable}
                                    required >

                                    <option value="">Seleccione el puesto correspondiente del usuario</option>
                                    {datos.sexta_consulta.map(
                                        (item) => (
                                            <option key={item.id_pue} value={item.id_pue}>
                                                {item.pue_nombre}
                                            </option>
                                        ))}
                                </select>
                                {!disable ? <small id="helpId" className="form-text text-muted">
                                    *Seleccione el puesto correspondiente.
                                </small> : <br />}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        );
    } else {
        return (
            <div className='container'>
                <Loading_papu></Loading_papu>
            </div>
        );
    }
};
export default InputInfoUser;