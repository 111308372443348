//Components
import Api from "@/services/api";

//Images
import fondo from "@/images/fondopdf.jpeg";

//Librarys
import React, { useEffect, useState } from 'react';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const PDF_fallas = ({ id, user }) => {


    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState([]);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [usuario, setUsuario] = useState([]);




    const CheckBox = ({ isChecked }) => (
        <View style={styles.checkbox}>
            <Text style={styles.checked}>{isChecked ? 'X' : ''}</Text>
        </View>
    );

    const ColCheck = ({ title, cosa }) => (
        <View style={styles.col}>
            <Text style={styles.text}>{title}</Text>
            <CheckBox isChecked=
                {primeraConsulta &&
                    (primeraConsulta.transporte === "Compacto" || primeraConsulta.transporte === "Motocicleta"
                        || primeraConsulta.transporte === "Pick-Up" || primeraConsulta.transporte === "SUV"
                        || primeraConsulta.transporte === "Sedan" || primeraConsulta.transporte === "Vehículo ligero"
                        || primeraConsulta.transporte === "Pick-up")
                } />
        </View>
    );

    // Componente Checkbox
    const InputCheck = ({ name }) => (
        <View style={styles.tableCol50}>
            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.text}>{name}</Text>
                </View>
                <View style={styles.col}>
                    <CheckBox isChecked={terceraConsulta.some(item => item.ret_nombre_documento === name)} />
                </View>
            </View>
        </View>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name1, name2, name3 }) => (
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <View style={styles.row}>
                    <View style={styles.col}>
                        <Text style={styles.text}>{name1}</Text>
                    </View>
                    <View style={styles.col}>
                        <CheckBox isChecked={segundaConsulta.some(item => item.ref_suceso_fallas === name1)} />
                    </View>
                </View>
            </View>
            <View style={styles.tableCol}>
                <View style={styles.row}>
                    <View style={styles.col}>
                        <Text style={styles.text}>{name2}</Text>
                    </View>
                    <View style={styles.col}>
                        <CheckBox isChecked={segundaConsulta.some(item => item.ref_suceso_fallas === name2)} />
                    </View>
                </View>
            </View>
            <View style={styles.tableCol}>
                <View style={styles.row}>
                    <View style={styles.col}>
                        <Text style={styles.text}>{name3}</Text>
                    </View>
                    <View style={styles.col}>
                        <CheckBox isChecked={segundaConsulta.some(item => item.ref_suceso_fallas === name3)} />
                    </View>
                </View>
            </View>
        </View>
    );

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag }) => (
        <View style={styles.row}>
            <View style={styles.col}><Text style={styles.label}>{tg}</Text></View>
            <View style={styles.col}>
                <Text style={styles.text}>
                    {primeraConsulta ? primeraConsulta[ag] : ""}</Text>
            </View>
            <View style={styles.col}><Text style={styles.label}></Text></View>
            <View style={styles.col}>
                <View style={styles.row}>
                    <View style={styles.col20}>
                        <CheckBox isChecked={primeraConsulta.condicion === tv} />
                    </View>
                    <View style={styles.col80}>
                        <Text style={styles.text}>{tv}</Text>
                    </View>
                </View>
            </View >
        </View >
    );

    useEffect(() => {
        console.log('id: ' + id);
        console.log('userId: ' + user);

        fetch(Api + "reportes.php?repconfio=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);


        fetch(Api + "usuarios.php/?listaid=" + user)
            .then(response3 => response3.json())
            .then((responseData3) => {
                console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)

    }, []);

    // Estilos personalizados para el PDF
    const styles = StyleSheet.create({
        page: {
            flexDirection: "column", // Asegura que la imagen de fondo se extienda por toda la página
            width: '100%', // Ajusta el tamaño de la imagen de fondo
            height: '100%',
            position: 'relative',
        },
        headerText: {
            fontSize: 18,
            textAlign: 'right',
        },
        label: {
            fontWeight: "bold",
            fontSize: 12,
        },
        text: {
            fontSize: 10,
            fontWeight: "light",
            fontStyle: "italic",
        },
        littleText: {
            fontSize: 5,
            fontWeight: "light",
            fontStyle: "italic",
        }
        ,
        image: {
            width: '100%',
            height: '100%',
            position: 'absolute', // Ajusta la posición de la imagen
            top: 0, // Coloca la imagen en la parte superior izquierda
            left: 0,
            zIndex: -1, // Coloca la imagen detrás de los elementos de texto
        },
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent', // Fondo transparente para ver la imagen de fondo
        },
        card: {
            marginLeft: "5%",
            marginRight: "5%",
        },
        cardheader: {
            width: "100%",
            alignItems: 'right',
            justifyContent: 'flex-end',
            height: "13vw",
            paddingLeft: "25%",
        },
        imgvehicle: {
            width: "100%",
            height: "12vw",
        },
        evidencia: {
            width: "100%",
            height: "50vh",
        },
        row: {
            flexDirection: 'row', // Esto establece la dirección de las columnas
            marginBottom: 2, // Espacio entre filas (ajusta según tus necesidades)
        },
        col: {
            marginLeft: 5,
            flex: 1, // Esto permite que las columnas se ajusten automáticamente
        },
        col80: {
            marginLeft: 5,
            width: "90%",
        },
        col20: {
            marginRight: 5, // Espacio entre columnas (ajusta según tus necesidades)
            width: "10%",
        },
        textLeft: {
            textAlign: 'left',
        },
        checkbox: {
            width: 12,
            height: 12,
            border: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        checked: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: "center",
        },
        tableCol: {
            width: "33.3%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol50: {
            width: "50%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol66: {
            width: "66.6%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: "auto",
            marginTop: 5,
            fontSize: 12,
            fontWeight: "bold"
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        },
        tableCol10: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol35: {
            width: "37.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        tableCol15: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        textCenter: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        greenBackground: {
            backgroundColor: '#A6DDB880',
        },
        space: {
            paddingTop: 93,
        }
    });

    if (!loadedData) {
        return (
            <Document>
                <Page size="A4">
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <Text>
                            No se pudo cargar la información.
                        </Text>
                    </View>
                </Page>
            </Document>
        );
    } else {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.card}>
                            <View style={styles.cardheader}>
                                <Text style={styles.headerText}>REPORTE DE FALLAS, INCIDENCIAS U</Text>
                                <Text style={styles.headerText}>OCURRENCIAS</Text>
                            </View>
                            <View style={styles.cardbody}>
                                <View style={styles.row}>
                                    <View style={styles.col}></View>
                                    <View style={styles.col}></View>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={styles.textLeft}>
                                            <Text style={styles.label}>Folio: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.folio}</Text>
                                            </Text>
                                        </View>
                                        <View>
                                            <Text
                                                style={styles.label}
                                            >Fecha: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.Fecha_generada}</Text>
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>DATOS GENERALES</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>TRANSPORTE:</Text>
                                    </View>

                                    <View style={styles.col}>
                                        <View style={styles.row}>
                                            <View style={styles.col}>
                                                <Text style={styles.littleText}>&nbsp;Vehículo</Text>
                                                <CheckBox isChecked=
                                                    {primeraConsulta &&
                                                        (primeraConsulta.transporte === "Compacto" || primeraConsulta.transporte === "Motocicleta"
                                                            || primeraConsulta.transporte === "Pick-Up" || primeraConsulta.transporte === "SUV"
                                                            || primeraConsulta.transporte === "Sedan" || primeraConsulta.transporte === "Vehículo ligero"
                                                            || primeraConsulta.transporte === "Pick-up")
                                                    } />
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.littleText}>&nbsp;Maquinaria o vehículo pesado</Text>
                                                <CheckBox isChecked=
                                                    {primeraConsulta &&
                                                        (primeraConsulta.transporte === "Vehículo pesado" || primeraConsulta.transporte === "Camión"
                                                            || primeraConsulta.transporte === "Furgoneta" || primeraConsulta.transporte === "Plataforma"
                                                            || primeraConsulta.transporte === "Remolque")
                                                    } />
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.littleText}>&nbsp;Otros</Text>
                                                <CheckBox isChecked=
                                                    {primeraConsulta && (primeraConsulta.transporte === "Otros" || primeraConsulta.transporte === "otros")
                                                    } />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>USUARIO:</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta ? primeraConsulta.usuario : ""}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>MODELO:</Text>
                                    </View>
                                    <View style={styles.col}>
                                        {primeraConsulta ? primeraConsulta.vehiculo : ""}
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>TIPO DE CONDICIÓN:</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <View style={styles.row}>
                                            <View style={styles.col20}>
                                                <CheckBox isChecked={primeraConsulta.condicion === "Normal"} />
                                            </View>
                                            <View style={styles.col80}>
                                                <Text style={styles.text}>Normal</Text>
                                            </View>
                                        </View>
                                    </View >
                                </View>
                                <DatosGV tg="PLACAS:" tv="Regular" ag="placas" />
                                <DatosGV tg="NIV:" tv="Medio Regular" ag="niv" />
                                <DatosGV tg="ODÓMETRO, KM:" tv="Mala" ag="odometro_actual" />
                                <DatosGV tg="NÚM, ECONÓMICO:" tv="Desastrozo" ag="neconomico" />
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>TIPO DE FALLA, INCIDENTE U OCURRENCIA</Text>
                                        </View>
                                    </View>
                                    <InputCheck2 name1="Luces Rotas" name2="Choque" name3="Multa" />
                                    <InputCheck2 name1="Neumáticos Desinflados" name2="Transporte no Arranca" name3="Robo de Transporte" />
                                    <InputCheck2 name1="Batería Muerta" name2="Falla de Bomba de Aceite" name3="Mala Lubricación" />
                                    <InputCheck2 name1="Sobrecalentamiento" name2="Falla de Sistema Eléctrico" name3="Ruidos Extraños" />
                                    <InputCheck2 name1="Frenos Defectuosos" name2="Problemas con la Gasolina" name3="Falla de Dirección" />
                                    <InputCheck2 name1="Fugas" name2="Falla de Transmisión" name3="Otros" />
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>DESCRIPCIÓN</Text>
                                            <Text style={styles.text}>{primeraConsulta ? primeraConsulta.descripcion : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.space}>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                        <View style={styles.card}>
                            <View style={styles.row}>
                                <Text style={styles.label}>EVIDENCIA DEL ESTADO DEL VEHÍCULO</Text>
                            </View>
                            <View style={styles.row}>
                                <Image
                                    style={styles.evidencia}
                                    // Cambia la URL de la imagen
                                    src={primeraConsulta.evidencia ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                        + "/evi/" + primeraConsulta.evidencia : ""}
                                />
                            </View>
                            <Text style={styles.text}>&nbsp;</Text>
                            <View style={styles.row}>
                                <View style={styles.col}>
                                    <Text style={styles.label}>Ejecutado:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={primeraConsulta.firma_soli ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                            + "/fir/" + primeraConsulta.firma_soli : ""}
                                    />

                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.usuario}</Text>
                                    </View>
                                </View>
                                <View style={styles.col}>
                                    <Text style={styles.label}>Verificado:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={primeraConsulta.firma_verificada ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                            + "/fir/" + primeraConsulta.firma_verificada : ""}
                                    />
                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.nom_verificado}</Text>
                                    </View>
                                </View>
                                <View style={[styles.col, styles.greenBackground]}>
                                    <Text style={styles.label}>Visto bueno:</Text>
                                    <Image
                                        style={styles.imgvehicle}
                                        // Cambia la URL de la imagen
                                        src={primeraConsulta.firma_vo ? Api + process.env.REACT_APP_LINK_IMG_REP_FIO + primeraConsulta.folio
                                            + "/fir/" + primeraConsulta.firma_vo : ""}
                                    />
                                    <View style={styles.textCenter}>
                                        <Text style={styles.text}>Firma</Text>
                                        <Text style={styles.text}>{primeraConsulta.nom_vo}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document >
        );
    }
}
export default PDF_fallas;
