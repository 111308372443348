//Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

//Images
import car1 from '@/images/Car1.jpeg';
import car2 from '@/images/Car2.jpeg';
import car3 from '@/images/Car3.jpeg';
import car4 from '@/images/Car4.jpeg';

function Technician_new_flotilla() {

    const { pagFlotilla, userID } = useUser();
    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [checkboxValues2, setCheckboxValues2] = useState([]);
    const [firma, setFirma] = useState(null);
    const sigCanvas = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [listaFirma, setListaFirma] = useState([]);
    const [verificado, setVerificado] = useState(null);
    const [vistoBueno, setVistoBueno] = useState(null);
    const [usuario, setUsuario] = useState([]);
    const [paesmu, setPaesmu] = useState([]);
    const [pais, setPais] = useState('');
    const [estado, setEstado] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [direccion, setDireccion] = useState('');
    const { id } = useParams(); // Obtenemos el valor "id" de la URL
    const [primeraConsulta, setPrimeraConsulta] = useState([]);
    const [odoActual, setOdoActual] = useState(null);

    const [obra, setObra] = useState(null);
    const [unidad, setUnidad] = useState(null);
    const [infoEstado, setInfoEstado] = useState(null);
    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [img3, setImg3] = useState(null);
    const [img4, setImg4] = useState(null);

    // Función asincrona
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirma(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirma(null);
    };

    const loadData = () => {
        if (id == null || id === "") {
            console.log('Nuevo reporte A.');

            fetch(Api + "automovil-flotilla.php/?todos")
                .then(response => response.json())
                .then((responseData) => {
                    //console.log(responseData);
                    setVehiculo(responseData);
                })
                .catch(console.log)

        } else {
            console.log('Nuevo reporte B.');

            fetch(Api + "reportes.php?repconsest=" + id.substring(0, id.length - 1) + 'A')
                .then(response => response.json())
                .then((responseData) => {
                    //console.log(responseData);
                    setPrimeraConsulta(responseData.primera_consulta[0]);
                })
                .catch(console.log);
        }
    }


    useEffect(() => {
        loadData();

        fetch(Api + "usuarios.php/?listaus")
            .then(response2 => response2.json())
            .then((responseData2) => {
                //console.log(responseData2);
                setListaFirma(responseData2.data);
            })
            .catch(console.log)

        fetch(Api + "usuarios.php/?listaid=" + userID)
            .then(response3 => response3.json())
            .then((responseData3) => {
                //console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)

        fetch(Api + "usuarios.php/?paisestadomuni")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setPaesmu(responseData);
                setLoadedData(true);
            })
            .catch(console.log)
    }, []);
    //Preparación para la dirección
    const paisesUnicos = [...new Set(paesmu.map(item => item.pais))];
    const estadosPorPais = pais ? paesmu.filter(item => item.pais === pais) : [];
    const municipiosPorEstado = estado ? paesmu.filter(item => item.estado === estado) : [];

    // Filtramos los estados para que cada uno aparezca una sola vez
    const estadosUnicos = [...new Set(estadosPorPais.map(item => item.estado))];

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            // Si el checkbox está marcado, agrega el valor al array
            setCheckboxValues([...checkboxValues, value]);
        } else {
            // Si el checkbox se desmarca, elimina el valor del array
            setCheckboxValues(checkboxValues.filter((item) => item !== value));
        }
    };

    const handleCheckboxChange2 = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            // Si el checkbox está marcado, agrega el valor al array
            setCheckboxValues2([...checkboxValues2, value]);
        } else {
            // Si el checkbox se desmarca, elimina el valor del array
            setCheckboxValues2(checkboxValues2.filter((item) => item !== value));
        }
    };

    const valueChange = (e) => {
        const newState = { ...selectedVehicle }; // Crear una copia del estado vehiculo
        newState[e.target.name] = e.target.value;
        setSelectedVehicle(newState); // Actualizar el estado con la nueva información
    }

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag, av, vg, vv }) => (
        (id == null || id === "") ? (
            <Row>
                <Col sm={2}><label htmlFor="">{tg}</label></Col>
                <Col sm={4}>
                    <p className="card-text d-inline">
                        {usuario ? usuario[ag] : ""}
                    </p>
                </Col>
                <Col sm={2}><label htmlFor="">{tv}</label></Col>
                <Col>
                    <p className="card-text d-inline">
                        {selectedVehicle ? selectedVehicle[av] : ""}
                    </p>
                </Col>
            </Row>
        ) : (
            <Row>
                <Col sm={2}><label htmlFor="">{tg}</label></Col>
                <Col sm={4}>
                    <p className="card-text d-inline">
                        {vg ? vg[ag] : ""}</p>
                </Col>
                <Col sm={2}><label htmlFor="">{tv}</label></Col>
                <Col>
                    <p className="card-text d-inline">
                        {vv ? vv[av] : ""}</p>
                </Col>
            </Row>
        )
    );


    // Componente Checkbox
    const InputCheck = ({ name }) => (
        <div className="form-check">
            <label className="card-text" htmlFor="">
                &nbsp;{name}
            </label>
            <input
                className="form-check-input"
                type="checkbox"
                value={name}
                onChange={handleCheckboxChange}
                checked={checkboxValues.includes(name)}
            />
        </div>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name }) => (
        <Row className='container'>
            <div className="form-check">
                <label className="card-text" htmlFor="">
                    &nbsp;{name}
                </label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    value={name}
                    onChange={handleCheckboxChange2}
                    checked={checkboxValues2.includes(name)}
                />
            </div>
        </Row>
    );

    //Componente imagen Input
    const handleImageChange = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Aquí guardamos el archivo directamente
        }
    };

    const InputImage = ({ nomVar, nomSet, img }) => {
        return (
            <td>
                {
                    nomVar ?
                        <img
                            src={URL.createObjectURL(nomVar)} // Creamos una URL temporal para mostrar la imagen
                            className="img-fluid rounded"
                            alt="Imagen cargada"
                        />
                        :
                        <img
                            src={img}
                            className="img-fluid rounded"
                            alt="No se ha podido cargar su imagen"
                        />
                }

                <input
                    type="file"
                    onChange={(e) => handleImageChange(e, nomSet)}
                    name="nomVar"
                    id="nomVar"
                    accept="image/*"
                    value={nomSet}
                    className={`form-control ${nomVar == null || nomVar == '' ? "is-invalid" : ""}`}
                />
            </td>
        );
    };

    const sendData = (e) => {

        e.preventDefault();

        if (img1 == null || img1 == "") {
            window.alert("Agregar la imagen superior izquierda.");
            return;
        }
        if (img2 == null || img2 == "") {
            window.alert("Agregar la imagen superior derecha.");
            return;
        }
        if (img3 == null || img3 == "") {
            window.alert("Agregar la imagen inferior izquierda.");
            return;
        }
        if (img4 == null || img4 == "") {
            window.alert("Agregar la imagen inferior derecha.");
            return;
        }

        /*
        let id2 = id.substring(0, id.length - 1);
        console.log(id2);
        console.log(userID);
        console.log(unidad);
        console.log(obra);
        console.log(primeraConsulta.id);
        console.log(checkboxValues);
        console.log(checkboxValues2);
        console.log(infoEstado);
        console.log(img1);
        console.log(img2);
        console.log(img3);
        console.log(img4);
        console.log(vistoBueno);
        console.log(verificado);
        console.log(firma);
        */
        //
        if (
            userID &&
            ((selectedVehicle && selectedVehicle.id) || ((primeraConsulta) && primeraConsulta.id)) &&
            checkboxValues.length > 0 && // Verificamos que el array no esté vacío
            checkboxValues2.length > 0 && // Verificamos que el array no esté vacío
            ((selectedVehicle && selectedVehicle.odometro) || (odoActual != null)) &&
            verificado &&
            vistoBueno &&
            unidad &&
            infoEstado
        ) {
            // verificar parametros necesarios

            if (btnCheck) {
                if (firma == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {
                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("checkboxValues", JSON.stringify(checkboxValues));
                    formData.append("checkboxValues2", JSON.stringify(checkboxValues2));
                    formData.append("unidad", unidad);
                    formData.append("obra", obra);
                    formData.append("infoEstado", infoEstado);
                    formData.append("img1", img1);
                    formData.append("img2", img2);
                    formData.append("img3", img3);
                    formData.append("img4", img4);
                    formData.append("id_verificado", verificado);
                    formData.append("id_vistobueno", vistoBueno);
                    formData.append("firma", firma);

                    // Dirección
                    if (municipio == null || municipio == '') {
                        formData.append("direccion", direccion + ", " + estado + ", " + pais);
                    } else {
                        formData.append("direccion", direccion + ", " + municipio.toLowerCase() + ", " + estado + ", " + pais);
                    }

                    if (id == null || id === "") {
                        // Primer reporte o A
                        formData.append("id", selectedVehicle.id);
                        formData.append("odoActual", selectedVehicle.odometro);

                        fetch(Api + "/reportes.php?insreporact=1", {
                            method: "POST",
                            body: formData,
                        }).then(response => response.json())
                            .then((responseDataArray) => {

                                //console.log(responseDataArray);

                                // Filtrar respuestas exitosas y extraer sus mensajes
                                let successMessages = responseDataArray
                                    .filter(response => response.success === 1)
                                    .map(response => response.message);

                                // Verificar si hay mensajes de éxito para mostrar
                                if (successMessages.length > 0) {
                                    // Unir los mensajes con saltos de línea y mostrar en un alert
                                    window.alert(successMessages.join('\n'));
                                    window.location.href = "/flotilla/reporte/tecnico";
                                } else {
                                    window.alert('Lo lamentamos, no se ha podido subir su reporte. Inténtelo de nuevo');
                                }

                            }).catch(console.log);
                    } else {
                        // Segundo reporte o B
                        let id2 = id.substring(0, id.length - 1);
                        formData.append("id", primeraConsulta.id);
                        formData.append("odoActual", odoActual);
                        formData.append("folio", id2);

                        fetch(Api + "/reportes.php?insreporactb=1", {
                            method: "POST",
                            body: formData,
                        }).then(response => response.json())
                            .then((responseDataArray) => {
                                console.log(responseDataArray);

                                // Filtrar respuestas exitosas y extraer sus mensajes
                                let successMessages = responseDataArray
                                    .filter(response => response.success === 1)
                                    .map(response => response.message);

                                // Verificar si hay mensajes de éxito para mostrar
                                if (successMessages.length > 0) {
                                    // Unir los mensajes con saltos de línea y mostrar en un alert
                                    window.alert(successMessages.join('\n'));
                                    window.location.href = "/flotilla/reporte/tecnico";
                                } else {
                                    window.alert('Lo lamentamos, no se ha podido subir su reporte. Inténtelo de nuevo');
                                }

                            }).catch(console.log);
                    }
                }
            } else {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
        //
    };
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */


    if (existsInPagFlotilla(pagFlotilla, 3, "Reportes")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        <h2>Agrega un nuevo reporte del estado del vehículo</h2>
                        {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (<div className="card">

                            <form onSubmit={sendData}>
                                <div className="card-header">
                                    <h4>Reporte Actual del Estado del Vehículo</h4>
                                </div>
                                <div className="card-body">
                                    {(id == null || id === "") && <Row>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="">Vehículo o plataforma</label>
                                                <select
                                                    className="custom-select form-control"
                                                    value={selectedVehicle ? selectedVehicle.id : ""}
                                                    onChange={(e) => {
                                                        const selectedId = e.target.value;
                                                        const selected = vehiculo.find((v) => v.id === selectedId);
                                                        setSelectedVehicle(selected);
                                                    }}
                                                    name="vehiculo"
                                                    id="vehiculo"
                                                    required >
                                                    <option value="">Seleccione un vehículo o plataforma</option>
                                                    <optgroup label="Vehículos o plataformas">
                                                        {vehiculo.map(
                                                            (vehiculo) => (
                                                                <option key={vehiculo.id} value={vehiculo.id}>
                                                                    {vehiculo.vehiculo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                    <option value="Otros">Otros</option>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione la categoría del vehículo
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>}
                                    <br />
                                    <Row>
                                        <Col sm>
                                            <h3>DATOS GENERALES</h3>
                                        </Col>
                                        <Col>
                                            <h3>DATOS DEL VEHÍCULO</h3>
                                        </Col>
                                    </Row>
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="APELLIDO PATERNO:" tv="MARCA:" ag="ap_paterno" av="fabricante" />
                                    ) : (
                                        <>
                                            <DatosGV tg="APELLIDO PATERNO:" tv="MARCA:" ag="ap_paterno" av="marca" vg={usuario} vv={primeraConsulta} />
                                        </>
                                    )}
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="APELLIDO MATERNO:" tv="MODELO:" ag="ap_materno" av="modelo" />
                                    ) : (
                                        <DatosGV tg="APELLIDO MATERNO:" tv="MODELO:" ag="ap_materno" av="modelo" vg={usuario} vv={primeraConsulta} />
                                    )}
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="NOMBRE:" tv="PLACAS:" ag="nombre" av="placas" />
                                    ) : (
                                        <DatosGV tg="NOMBRE:" tv="PLACAS:" ag="nombre" av="placas" vg={usuario} vv={primeraConsulta} />
                                    )}
                                    <Row>
                                        <Col sm={2}><label htmlFor="">UNIDAD:</label></Col>
                                        <Col sm={4}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="unidad"
                                                    id="unidad"
                                                    value={unidad}
                                                    className={`form-control w-50 ${unidad == '' || unidad == null ? "is-invalid" : ""}`}
                                                    placeholder="Indique su unidad"
                                                    minLength={1}
                                                    maxLength={45}
                                                    required
                                                    onChange={(e) => setUnidad(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={2}><label htmlFor="">NIV:</label></Col>
                                        {(id == null || id === "") ? (
                                            <Col>
                                                <p className="card-text d-inline">
                                                    {selectedVehicle ? selectedVehicle.niv : ""}</p>
                                            </Col>
                                        ) : (
                                            <Col>
                                                <p className="card-text d-inline">
                                                    {primeraConsulta ? primeraConsulta.niv : ""}</p>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col sm={2}><label htmlFor="">OBRA:</label></Col>
                                        <Col sm={4}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="obra"
                                                    id="obra"
                                                    value={obra}
                                                    className={`form-control w-50 ${obra == '' || obra == null ? "is-invalid" : ""}`}
                                                    placeholder="Indique la obra a la que usted esta/va ir"
                                                    minLength={1}
                                                    maxLength={70}
                                                    required
                                                    onChange={(e) => setObra(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={2}><label htmlFor="">NÚM.ECONÓMICO:</label></Col>
                                        {(id == null || id === "") ? (
                                            <Col>
                                                <p className="card-text d-inline">
                                                    {selectedVehicle ? selectedVehicle.neconomico : ""}</p>
                                            </Col>
                                        ) : (
                                            <Col>
                                                <p className="card-text d-inline">
                                                    {primeraConsulta ? primeraConsulta.num_economico : ""}</p>
                                            </Col>
                                        )}

                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <label htmlFor="">DIRECCIÓN: <span className='fw-light text-lowercase'>(UBICACIÓN EN DONDE SE ENCUENTRA)</span></label>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    name="direccion"
                                                    id="direccion"
                                                    value={direccion}
                                                    className={`form-control w-50 ${direccion == '' || direccion == null ? "is-invalid" : ""}`}
                                                    placeholder="Indique la obra a la que usted esta/va ir"
                                                    minLength={1}
                                                    maxLength={120}
                                                    required
                                                    onChange={(e) => setDireccion(e.target.value)}
                                                />
                                            </div>
                                            <div className="btn-group">
                                                {pais === "México" && estado && (
                                                    <select
                                                        className="btn btn-outline-secondary dropdown-toggle w-100"
                                                        value={municipio}
                                                        onChange={e => setMunicipio(e.target.value)}
                                                    >
                                                        <option value="">Municipio o Delegación</option>
                                                        {municipiosPorEstado.map((item, index) => (
                                                            <option key={index} value={item.municipio}>
                                                                {item.municipio}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                                {pais && (
                                                    <select
                                                        className="btn btn-outline-secondary dropdown-toggle w-100"
                                                        value={estado}
                                                        required
                                                        onChange={e => {
                                                            setEstado(e.target.value);
                                                            setMunicipio('');
                                                        }}
                                                    >
                                                        <option value="">Estado o Provincia</option>
                                                        {estadosUnicos.map((estadoActual, index) => (
                                                            <option key={index} value={estadoActual}>
                                                                {estadoActual}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                                <select
                                                    className="btn btn-outline-secondary dropdown-toggle w-100"
                                                    required
                                                    onChange={e => {
                                                        setPais(e.target.value);
                                                        setEstado('');
                                                        setMunicipio('');
                                                    }}  // Agregar esta línea
                                                >
                                                    <option value="">País</option>
                                                    {paisesUnicos.map((pais, index) => (
                                                        <option key={index} value={pais}>
                                                            {pais}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                        </Col>
                                        <Col sm={2}><label htmlFor="">ODÓMETRO, KM:</label></Col>
                                        {(id == null || id === "") ? (
                                            <Col>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        name="odometro"
                                                        id="odometro"
                                                        value={selectedVehicle ? selectedVehicle.odometro : ""}
                                                        className={`form-control w-50 ${(selectedVehicle && (selectedVehicle.odometro === '' || selectedVehicle.odometro === null)) ? "is-invalid" : ""}`}
                                                        placeholder="Indique la lectura de su odómetro"
                                                        aria-describedby="helpId"
                                                        required
                                                        pattern="^\d+(\.\d)?$"
                                                        onChange={valueChange}
                                                        title="Por favor, ingrese un número entero o un número con hasta un decimal."
                                                    />
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        name="odometro"
                                                        id="odometro"
                                                        min="1" pattern="^[0-9]+"
                                                        value={odoActual}
                                                        className={`form-control w-50 ${(odoActual === '' || odoActual === null) ? "is-invalid" : ""}`}
                                                        placeholder="Indique la lectura de su odómetro"
                                                        aria-describedby="helpId"
                                                        required
                                                        step={1}
                                                        onChange={(e) => setOdoActual(e.target.value)}
                                                        title="Por favor, ingrese un número entero o un número con hasta un decimal."
                                                    />
                                                </div>
                                            </Col>
                                        )}

                                    </Row>
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="DEPARTAMENTO:" tv="DESCRIPCIÓN:" ag="departamento" av="descripcion" />
                                    ) : (
                                        <DatosGV tg="DEPARTAMENTO:" tv="DESCRIPCIÓN:" ag="departamento" av="descripcion" vg={usuario} vv={primeraConsulta} />
                                    )}
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="TELEFONO:" ag="telefono" />
                                    ) : (
                                        <DatosGV tg="TELEFONO:" ag="telefono" vg={usuario} />
                                    )}
                                    {(id == null || id === "") ? (
                                        <DatosGV tg="CORREO ELECTRÓNICO:" ag="email" />
                                    ) : (
                                        <DatosGV tg="CORREO ELECTRÓNICO:" ag="email" vg={usuario} />
                                    )}
                                    <Row>

                                        <Col sm={8}>


                                            <div className="form-group">
                                                <p className="card-text d-inline">
                                                    {selectedVehicle ? selectedVehicle.neconomico : ""}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <div className="table-responsive">
                                            <br />
                                            <table className="table table table-bordered table-hover">
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th colSpan={3} className='text-center'>DOCUMENTACIÓN DEL VEHÍCULO</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbodyy'>
                                                    <tr>
                                                        <td>
                                                            <InputCheck name="Tarjeta de Circulación" />
                                                        </td>
                                                        <td>
                                                            <InputCheck name="Comprobante de Verificación" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <InputCheck name="Póliza de seguro" />
                                                        </td>
                                                        <td>
                                                            <InputCheck name="IAVE" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="table-responsive">
                                            <table className="table table table-bordered table-hover">
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th colSpan={3} className='text-center'>VERIFICACIÓN DE ACCESORIOS Y HERRAMIENTAS</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbodyy'>
                                                    <tr className='table-light text-center'>
                                                        <td>
                                                            EXTERIORES
                                                        </td>
                                                        <td>
                                                            INTERIORES
                                                        </td>
                                                        <td>
                                                            OTROS
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <InputCheck2 name="Luces" />
                                                            <InputCheck2 name="Cuarto" />
                                                            <InputCheck2 name="Antena" />
                                                            <InputCheck2 name="Espejos Laterales" />
                                                            <InputCheck2 name="Cristales" />
                                                            <InputCheck2 name="Emblemas" />
                                                            <InputCheck2 name="Llantas" />
                                                            <InputCheck2 name="Tapones de Rueda" />
                                                            <InputCheck2 name="Molduras" />
                                                            <InputCheck2 name="Tapón de Gasolina" />
                                                            <InputCheck2 name="Limpiadores" />
                                                        </td>
                                                        <td>
                                                            <InputCheck2 name="Instrumentos de Tablero" />
                                                            <InputCheck2 name="Calefacción" />
                                                            <InputCheck2 name="Radio/Estereo" />
                                                            <InputCheck2 name="Bocinas" />
                                                            <InputCheck2 name="Encendedor" />
                                                            <InputCheck2 name="Espejo Retrovisor" />
                                                            <InputCheck2 name="Ceniceros" />
                                                            <InputCheck2 name="Cinturones de Seguridad" />
                                                            <InputCheck2 name="Manijas de Puerta" />
                                                            <InputCheck2 name="Tapetes" />
                                                            <InputCheck2 name="Vestiduras" />
                                                        </td>
                                                        <td>
                                                            <InputCheck2 name="Gato" />
                                                            <InputCheck2 name="Maneral de Gato" />
                                                            <InputCheck2 name="Llave de Rueda" />
                                                            <InputCheck2 name="Birlo de Seguridad" />
                                                            <InputCheck2 name="Bastón de Seguridad" />
                                                            <InputCheck2 name="Llanta de Refacción" />
                                                            <InputCheck2 name="Estuche de Herramienta" />
                                                            <InputCheck2 name="Triángulo de seguridad" />
                                                            <InputCheck2 name="Extinguidor" />
                                                            <InputCheck2 name="Llave del Vehículo" />
                                                            <InputCheck2 name="Duplicado de Llave" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">INFORME DEL ESTADO DEL VEHÍCULO</h5>
                                                <div className="form-group">
                                                    <textarea
                                                        name="infoEstado"
                                                        id="infoEstado"
                                                        placeholder='Indique el estado actual del vehículo'
                                                        required
                                                        minLength={1}
                                                        maxLength={100}
                                                        rows="3"
                                                        className={`form-control ${infoEstado == null || infoEstado == '' ? "is-invalid" : ""}`}
                                                        value={infoEstado}
                                                        onChange={(e) => setInfoEstado(e.target.value)}
                                                        pattern="^[^'\;--%/]*$" title="Caracteres especiales no permitidos."
                                                    >
                                                    </textarea>
                                                    <small id="helpId" className="form-text text-muted">Indique el estado actual del vehículo</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="table-responsive">
                                            <table className="table table table-bordered table-hover">
                                                <thead className='table-secondary'>
                                                    <tr>
                                                        <th colSpan={3} className='text-center'>EVIDENCIA DEL ESTADO DEL VEHÍCULO</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbodyy'>
                                                    <tr className='table-light'>
                                                        <InputImage nomVar={img1} nomSet={setImg1} img={car1} />
                                                        <InputImage nomVar={img2} nomSet={setImg2} img={car2} />
                                                    </tr>
                                                    <tr className='table-light'>
                                                        <InputImage nomVar={img3} nomSet={setImg3} img={car4} />
                                                        <InputImage nomVar={img4} nomSet={setImg4} img={car3} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="firmaVer">Firma de verificado</label>
                                                <select
                                                    className="custom-select form-control"
                                                    required
                                                    onChange={e => setVerificado(e.target.value)}  // Agregar esta línea
                                                >
                                                    <option value="">Seleccione una persona que pueda firmar</option>
                                                    <optgroup label="Firmante para verificar el reporte">
                                                        {listaFirma.map(
                                                            (listaFirma) => (
                                                                <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                    {listaFirma.nombre_completo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione a la persona que firmará de verificado.
                                                </small>
                                            </div>
                                        </Col>
                                        <Col sm>
                                            <div className="form-group">
                                                <label htmlFor="firmaVO">Firma de visto bueno</label>
                                                <select
                                                    className="custom-select form-control"
                                                    required
                                                    onChange={e => setVistoBueno(e.target.value)}  // Agregar esta línea
                                                >
                                                    <option value="">Seleccione una persona que pueda firmar</option>
                                                    <optgroup label="Firmante para visto bueno el reporte">
                                                        {listaFirma.map(
                                                            (listaFirma) => (
                                                                <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                    {listaFirma.nombre_completo}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione a la persona que firmará de visto bueno.
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <br />
                                        <div className="mb-3 text-center">
                                            <label className="form-label">Firma</label>
                                            <br />
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                canvasProps={{
                                                    className: 'signature-canvas'
                                                }}

                                            />
                                            <br />
                                            <button type="button" onClick={saveSignature}>Guardar Firma</button>
                                            <button type="button" onClick={clearSignature}>Borrar Firma</button>
                                            <p className="form-text text-muted">
                                                *Dibuja tu firma en el espacio de arriba.
                                            </p>
                                        </div>

                                    </Row>
                                </div>
                                <div className="card-footer text-muted">
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit"
                                            className="btn btn-success"
                                        >
                                            Agregar
                                        </button>
                                        <Link className="btn btn-danger text-white" to="/flotilla/reporte/tecnico">Regresar</Link>
                                    </div>
                                </div>
                            </form>
                        </div>)
                        }
                    </div >
                </div >
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Technician_new_flotilla;