//Librarys
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

//Images
import img from '@/images/pngimg.com - box_PNG40.png';

function Documents_view_flotilla() {
    // External variables
    const { pagFlotilla, userID } = useUser();
    const { id, id_doc } = useParams(); // Obtenemos el valor "id" de la URL

    // Internal variables
    const [loadedData, setLoadedData] = useState(true);
    const [documentss, setDocumentss] = useState([]);
    const [priv, setPriv] = useState([]);
    const [usuario, setUsuario] = useState(null);
    const [permisos, setPermisos] = useState([]);
    const [permisos2, setPermisos2] = useState([]);


    const handlePrivilegeChange = (id, isChecked) => {
        const newValue = isChecked ? 1 : 0;
        setPermisos(prevPermisos => {
            // Busca si el id ya existe en el estado
            const existingPermisos = prevPermisos.find(permiso => permiso.id_usuario === id);
            if (existingPermisos) {
                // Actualiza el valor existente
                return prevPermisos.map(permiso =>
                    permiso.id_usuario === id ? { ...permiso, privilegio: newValue } : permiso
                );
            } else {
                // Agrega un nuevo objeto si no existe
                return [...prevPermisos, { id_usuario: id, privilegio: newValue }];
            }
        });
    };

    const renderFile = () => {
        // Comprueba si documentss y documentss.link existen
        if (documentss && documentss.link) {
            // Utiliza una expresión regular para obtener la extensión del archivo
            const extension = documentss.link.match(/\.(pdf|jpg|jpeg|png|gif)$/i);

            // Si la extensión es PDF
            if (extension && extension[1].toLowerCase() === 'pdf') {
                return (
                    <>
                        <label htmlFor="">Documento</label>
                        <iframe
                            src={Api + process.env.REACT_APP_LINK_ARC_DOC_VIEW + id + "/" + documentss.link}
                            className="pdf-viewer"
                            title="PDF cargado">
                        </iframe>
                    </>
                );
            }
            // Si la extensión es una imagen (jpg, jpeg, png, gif)
            else if (extension && ['jpg', 'jpeg', 'png', 'gif'].includes(extension[1].toLowerCase())) {
                return (
                    <>
                        <label htmlFor="">Documento</label>
                        <div className='text-center'>
                            <img
                                src={Api + process.env.REACT_APP_LINK_ARC_DOC_VIEW + id + "/" + documentss.link}
                                className="img-fluid rounded"
                                alt="Imagen cargada" />
                        </div>
                    </>
                );
            }
        }

        // Si no hay un link o no es una extensión reconocida, puedes retornar null o algún placeholder
        return null;
    };

    // Componente Checkbox
    const Option = ({ option }) => (
        <Col>
            <div className="form-group">
                {
                    documentss.link ? renderFile() :
                        <>
                            <label htmlFor="">Documento</label>
                            <div className='text-center'>
                                <img src={img} className="img-fluid rounded w-50" alt="No se ha podido cargar su imagen" />
                            </div>
                        </>

                }
            </div>
        </Col>
    );

    //Componente textField 
    const TextField = ({ tittle, vr, tp }) => (
        <Col>
            <div className="form-group">
                <label htmlFor={vr}>{tittle}</label>
                <input
                    type={tp}
                    name={vr}
                    id={vr}
                    value={documentss ? documentss[vr] : ""}
                    className="form-control"
                    readOnly
                />
            </div>
        </Col>
    );

    const loadData = () => {
        fetch(Api + "automovil-flotilla.php?viewdoc2=" + id + "&id_doc=" + id_doc + "&id_user=" + userID)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setDocumentss(responseData.primera_consulta[0]);
                setPriv(responseData.segunda_consulta);
                setUsuario(responseData.tercera_consulta[0]);

                // Filtrar la segunda consulta para obtener solo los objetos donde privilegio es "1"
                let permisosFiltrados = responseData.cuarta_consulta.filter(item => item.privilegio === "1").map(item => item.id_usuario);
                setPermisos2(permisosFiltrados);
                // Filtrar la quinta consulta para obtener solo los objetos donde privilegio es "1"
                // y luego mapearlos a un array de objetos con id_usuario y privilegio
                permisosFiltrados = responseData.quinta_consulta
                    .filter(item => item.privilegio === "1")
                    .map(item => ({
                        id_usuario: item.id_usuario,
                        privilegio: 1 // Asignamos el valor 1 directamente ya que estamos filtrando por este valor
                    }));
                setPermisos(permisosFiltrados);


            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
    }, []);

    const sendData = (e) => {
        e.preventDefault();
        /*
        console.log(userID);
        console.log(id);
        console.log(id_doc);
        console.log(permisos);
        */
        if (
            userID &&
            id &&
            id_doc
        ) {
            if (permisos.length > 0) {
                // Recepción y llenado de valores
                var formData = new FormData();
                formData.append("userID", userID);
                formData.append("id_vehiculo", id);
                formData.append("id_doc", id_doc);
                formData.append("permisos", JSON.stringify(permisos))

                //Procedimiento para enviar datos al API REST
                fetch(Api + "/automovil-flotilla.php?insactdoc=1", {
                    method: "POST",
                    body: formData,
                }).then(response => response.json()) // Parsear como JSON
                    .then((responseDataArray) => {
                        console.log(responseDataArray);
                        // Extraer mensajes y unirlos con saltos de línea
                        let messages = responseDataArray.map(response => response.message).join('\n');

                        // Verificar si al menos un objeto tiene success igual a 1
                        let hasSuccess3 = responseDataArray.some(response => response.success === 1);

                        if (hasSuccess3) {
                            window.alert("Enhorabuena.\n" + messages); // Mostrar mensajes
                            window.location.reload(); // Recargar la página
                        } else {
                            window.alert('Lo lamentamos, no se ha podido actualizar los permisos.\n' + messages); // Mostrar mensajes
                        }
                    }).catch(console.log);
            } else {
                window.alert('No ha resultado en algún cambio.');
            }
        } else {
            window.alert('Usted no cuenta con algún ID (Comuniquese con el encargado de sistemas).');
        }

    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Documentos")) {
        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            if (permisos2.includes(userID)) {
                return (
                    <div className='container'>
                        <Nav_bar_flotilla></Nav_bar_flotilla>
                        <div className="card">
                            <form onSubmit={sendData}>
                                <div className="card-header">
                                    <h4>{documentss ? documentss.document : ""}</h4>
                                </div>
                                <div className="card-body">
                                    <Row>
                                        <TextField tittle={"Fecha de expedición"} vr={"fecha_expedido"} tp={"date"} />
                                        <TextField tittle={"Fecha de vencimiento"} vr={"fecha_vencimiento"} tp={"date"} />
                                    </Row>
                                    <Row>
                                        <TextField tittle={"Tipo de documento"} vr={"tipo_doc"} tp={"text"} />
                                        <TextField tittle={"Estado del documento"} vr={"estado_doc"} tp={"text"} />
                                    </Row>
                                    <Row>
                                        {
                                            documentss && documentss.link ?
                                                (documentss.link.startsWith('http') || documentss.link.startsWith('https') ?
                                                    <TextField title={"Link o liga del documento (externo)"} vr={"link"} tp={"text"} />
                                                    :
                                                    <Option />
                                                )
                                                :
                                                <TextField title={"Link o liga del documento (externo)"} vr={"link"} tp={"text"} />
                                        }
                                    </Row>
                                    <br />
                                    {(existsInPagFlotilla(pagFlotilla, 2, "Documentos") || existsInPagFlotilla(pagFlotilla, 1, "Documentos"))
                                        && ((usuario ? usuario.id_usuario : "") == userID) &&
                                        (
                                            <div className="alert alert-success" role="alert">
                                                <h4 className="alert-heading">Zona de administradores</h4>
                                                <p></p>
                                                <p className="mb-0"></p>

                                                <table className="table table-hover table-inverse table-success table-striped table-responsive">
                                                    <thead className="thead-inverse">
                                                        <tr>
                                                            <th>Usuario</th>
                                                            <th>Permiso (Compartir este documento)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {priv.map((lista) => (
                                                            <tr key={lista.id_usuario}>
                                                                <td>
                                                                    {lista.usuario}
                                                                </td>
                                                                <td>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input w-25"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id="privilegio"
                                                                            checked={permisos.some(permiso => permiso.id_usuario === lista.id_usuario && permiso.privilegio === 1)}
                                                                            onChange={(e) => handlePrivilegeChange(lista.id_usuario, e.target.checked)}
                                                                        />
                                                                        <label className="form-check-label">Sí o No</label>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="card-footer text-muted text-center">
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit"
                                            className="btn btn-success"
                                        >
                                            Actualizar cambios
                                        </button>
                                        <Link className="btn btn-danger text-white" to={"/flotilla/documentos/" + id + "/"}>Regresar</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                );
            } else {
                return (
                    <div className='container-fluid'>
                        {/* Navigation bar for application web "Flotilla vehicular" */}
                        < Nav_bar_flotilla ></Nav_bar_flotilla >
                        <h2>Usted no puede ver este documento, requiere que se lo autorice un administrador.</h2>
                        <Error404></Error404>
                    </div>
                );
            }
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Documentos de un vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Documents_view_flotilla;