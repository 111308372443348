//Components
import Api from "@/services/api";

//Images
import fondo from "@/images/fondopdf.jpeg";

//Librarys
import React, { useEffect, useState } from 'react';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const PDF_bitacora = ({ id }) => {


    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState([]);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);

    const CheckBox = ({ isChecked }) => (
        <View style={styles.checkbox}>
            <Text style={styles.checked}>{isChecked ? 'X' : ''}</Text>
        </View>
    );

    const SuperCheck = ({ cosa }) => (
        <View style={styles.tableCol}>
            <View style={styles.row}>
                <View style={styles.col}>
                    <Text style={styles.text}>{cosa}</Text>
                </View>
                <View style={styles.col}>
                    <CheckBox isChecked={terceraConsulta.some(item => item.rem_nombre_servicio === "" + cosa + "")} />
                </View>
            </View>
        </View>
    );

    const serviciosEspeciales = [
        "Cambio de Aceite al Motor",
        "Cambio de Filtro de Aceite al Motor",
        "Cambio de Filtro de Aire",
        "Cambio de Filtro de Combustible",
        "Cambio de Aceite de Transmisión",
        "Cambio de Filtro de Transmisión",
        "Cambio de Anticongelante",
        "Cambio de Plumillas de Limpia-Parabrisas",
        "Cambio de Amortiguadores",
        "Cambio de Neumáticos",
        "Cambio de Batería",
        "Cambio de Balatas",
        "Alineación y Balanceo",
        "Cambio de Bujías",
        "Limpieza de Inyectores",
        "Cambio de luces",
        "Cambio de Líquido de Frenos",
        "Alineación de Ruedas",
        "null"
    ];

    const registroEspecial = terceraConsulta.find(item => !serviciosEspeciales.includes(item.rem_nombre_servicio));


    useEffect(() => {
        //console.log('id: ' + id);

        fetch(Api + "reportes.php?repvehvis=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);

    }, []);

    // Estilos personalizados para el PDF
    const styles = StyleSheet.create({
        page: {
            flexDirection: "column", // Asegura que la imagen de fondo se extienda por toda la página
            width: '100%', // Ajusta el tamaño de la imagen de fondo
            height: '100%',
            position: 'relative',
        },
        headerText: {
            fontSize: 18,
            textAlign: 'right',
        },
        label: {
            fontWeight: "bold",
            fontSize: 12,
        },
        text: {
            fontSize: 10,
            fontWeight: "light",
            fontStyle: "italic",
        },
        image: {
            width: '100%',
            height: '100%',
            position: 'absolute', // Ajusta la posición de la imagen
            top: 0, // Coloca la imagen en la parte superior izquierda
            left: 0,
            zIndex: -1, // Coloca la imagen detrás de los elementos de texto
        },
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent', // Fondo transparente para ver la imagen de fondo
        },
        card: {
            marginLeft: "5%",
            marginRight: "5%",
        },
        cardheader: {
            width: "100%",
            alignItems: 'right',
            justifyContent: 'flex-end',
            height: "13vw",
        },
        imgvehicle: {
            width: "100%",
            height: "12vw",
        },
        row: {
            flexDirection: 'row', // Esto establece la dirección de las columnas
            marginBottom: 2, // Espacio entre filas (ajusta según tus necesidades)
        },
        col: {
            flex: 1, // Esto permite que las columnas se ajusten automáticamente
            marginRight: 5, // Espacio entre columnas (ajusta según tus necesidades)
        },
        textLeft: {
            textAlign: 'left',
        },
        checkbox: {
            width: 12,
            height: 12,
            border: '1px solid black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        checked: {
            fontSize: 12,
            fontWeight: 'bold',
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: "center",
        },
        tableCol: {
            width: "33.3%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol50: {
            width: "50%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol66: {
            width: "66.6%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: "auto",
            marginTop: 5,
            fontSize: 12,
            fontWeight: "bold"
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        },
        tableCol10: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol35: {
            width: "37.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        tableCol15: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        textCenter: {
            textAlign: "center",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        greenBackground: {
            backgroundColor: '#A6DDB880',
        },
    });

    if (!loadedData) {
        return (
            <Document>
                <Page size="A4">
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <Text>
                            No se pudo cargar la información.
                        </Text>
                    </View>
                </Page>
            </Document>
        );
    } else {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.card}>
                            <View style={styles.cardheader}>
                                <Text style={styles.headerText}>REPORTE DE MANTENIMIENTO</Text>
                            </View>
                            <View style={styles.cardbody}>
                                <View style={styles.row}>
                                    <View style={styles.col}></View>
                                    <View style={styles.col}></View>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={styles.textLeft}>
                                            <Text style={styles.label}>Folio: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.folio}</Text>
                                            </Text>
                                        </View>
                                        <View>
                                            <Text
                                                style={styles.label}
                                            >Fecha: &nbsp;
                                                <Text style={styles.text}>{primeraConsulta.Fecha_generada}</Text>
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>DATOS GENERALES</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Transporte</Text>
                                    </View>
                                    <Text style={styles.text}>Vehículo</Text>
                                    <View style={styles.col}>
                                        <View style={styles.checkbox}>
                                            {primeraConsulta &&
                                                (primeraConsulta.transporte === "Compacto" || primeraConsulta.transporte === "Motocicleta"
                                                    || primeraConsulta.transporte === "Pick-Up" || primeraConsulta.transporte === "SUV"
                                                    || primeraConsulta.transporte === "Sedan" || primeraConsulta.transporte === "Vehículo ligero"
                                                    || primeraConsulta.transporte === "Pick-up") &&
                                                <Text style={styles.checked}>X</Text>}
                                        </View>
                                    </View>
                                    <Text style={styles.text}>Maquinaria</Text>
                                    <View style={styles.col}>

                                        <View style={styles.checkbox}>
                                            {primeraConsulta &&
                                                (primeraConsulta.transporte === "Vehículo pesado" || primeraConsulta.transporte === "Camión"
                                                    || primeraConsulta.transporte === "Furgoneta" || primeraConsulta.transporte === "Plataforma"
                                                    || primeraConsulta.transporte === "Remolque") &&
                                                <Text style={styles.checked}>X</Text>}
                                        </View>
                                    </View>
                                    <Text style={styles.text}>Otros</Text>
                                    <View style={styles.col}>
                                        <View style={styles.checkbox}>
                                            {primeraConsulta &&
                                                !(primeraConsulta.transporte === "Vehículo pesado" || primeraConsulta.transporte === "Camión"
                                                    || primeraConsulta.transporte === "Furgoneta" || primeraConsulta.transporte === "Plataforma"
                                                    || primeraConsulta.transporte === "Remolque" || primeraConsulta.transporte === "Compacto"
                                                    || primeraConsulta.transporte === "Motocicleta" || primeraConsulta.transporte === "Pick-Up"
                                                    || primeraConsulta.transporte === "SUV" || primeraConsulta.transporte === "Sedan"
                                                    || primeraConsulta.transporte === "Vehículo ligero" || primeraConsulta.transporte === "Pick-up") &&
                                                <Text style={styles.checked}>X</Text>}
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Marca: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.marca}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Modelo: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.modelo} {primeraConsulta.ano}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Placa: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.placas}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>NIV: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.niv}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Odómetro, Km: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.odometro_actual}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Núm. Económico: &nbsp;</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.text}>{primeraConsulta.num_economico}</Text>
                                    </View>
                                </View>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>MANTENIMIENTO</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol50}>
                                            <View style={styles.row}>
                                                <View style={styles.col}>
                                                    <Text style={styles.text}>&nbsp;Preventivo</Text>
                                                </View>
                                                <View style={styles.col}>
                                                    <CheckBox isChecked={primeraConsulta.tipo_mantenimiento === "Preventivo"} />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.tableCol50}>
                                            <View style={styles.row}>
                                                <View style={styles.col}>
                                                    <Text style={styles.text}>&nbsp;Correctivo</Text>
                                                </View>
                                                <View style={styles.col}>
                                                    <CheckBox isChecked={primeraConsulta.tipo_mantenimiento === "Correctivo"} />
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Cambio de Aceite al Motor"} />
                                        <SuperCheck cosa={"Cambio de Filtro de Aceite al Motor"} />
                                        <SuperCheck cosa={"Cambio de Filtro de Aire"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Cambio de Filtro de Combustible"} />
                                        <SuperCheck cosa={"Cambio de Aceite de Transmisión"} />
                                        <SuperCheck cosa={"Cambio de Filtro de Transmisión"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Cambio de Anticongelante"} />
                                        <SuperCheck cosa={"Cambio de Plumillas de Limpia-Parabrisas"} />
                                        <SuperCheck cosa={"Cambio de Amortiguadores"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Cambio de Neumáticos"} />
                                        <SuperCheck cosa={"Cambio de Batería"} />
                                        <SuperCheck cosa={"Cambio de Balatas"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Alineación y Balanceo"} />
                                        <SuperCheck cosa={"Cambio de Bujías"} />
                                        <SuperCheck cosa={"Limpieza de Inyectores"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Cambio de luces"} />
                                        <SuperCheck cosa={"Cambio de Líquido de Frenos"} />
                                        <SuperCheck cosa={"Alineación de Ruedas"} />
                                    </View>
                                    <View style={styles.tableRow}>
                                        <SuperCheck cosa={"Otros"} />
                                        <View style={styles.tableCol66}>
                                            <Text style={styles.text}>{registroEspecial ? registroEspecial.rem_nombre_servicio : ''}</Text>
                                        </View>
                                    </View>
                                </View>
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol10}>
                                            <Text style={styles.label}>N</Text>
                                        </View>
                                        <View style={styles.tableCol35}>
                                            <Text style={styles.label}>PIEZA, REFACCIÓN, COMBUSTIBLE, ETC</Text>
                                        </View>
                                        <View style={styles.tableCol15}>
                                            <Text style={styles.label}>SISTEMA</Text>
                                        </View>
                                        <View style={styles.tableCol35}>
                                            <Text style={styles.label}>DESCRIPCIÓN</Text>
                                        </View>
                                    </View>
                                    {segundaConsulta.map((row, index) => (
                                        <View style={styles.tableRow} key={row.id_repmanrefacciones}>
                                            <View style={styles.tableCol10}>
                                                <Text style={styles.text}>{index + 1}</Text>
                                            </View>
                                            <View style={styles.tableCol35}>
                                                <View>
                                                    <Text style={styles.text}> {row.rem_nombre_pieza}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol15}>
                                                <View>
                                                    <Text style={styles.text}>{row.rem_sistema_pieza}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableCol35}>
                                                <View>
                                                    <Text style={styles.text}>{row.Descripcion}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.label}>PRÓXIMO CONTROL</Text>
                                        </View>
                                    </View>
                                    <View style={styles.textCenter}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.label}>Fecha</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.label}>Horas de uso</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.label}>Odómetro</Text>
                                            </View>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.text}>{primeraConsulta.fecha_mant}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.text}>{primeraConsulta.horas_mant}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.text}>{primeraConsulta.odo_mant}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <Text style={styles.text}>&nbsp;</Text>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Ejecutado:</Text>
                                        <Image
                                            style={styles.imgvehicle}
                                            // Cambia la URL de la imagen
                                            src={primeraConsulta.firma_soli ? 
                                                Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_soli : "Holo"}
                                        />

                                        <View style={styles.textCenter}>
                                            <Text style={styles.text}>Firma</Text>
                                            <Text style={styles.text}>{primeraConsulta.usuario}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.label}>Verificado:</Text>
                                        <Image
                                            style={styles.imgvehicle}
                                            // Cambia la URL de la imagen
                                            src={primeraConsulta.firma_verificada ? 
                                                Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_verificada : "Holo"}
                                        />
                                        <View style={styles.textCenter}>
                                            <Text style={styles.text}>Firma</Text>
                                            <Text style={styles.text}>{primeraConsulta.nom_verificado}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.col, styles.greenBackground]}>
                                        <Text style={styles.label}>Visto bueno:</Text>
                                        <Image
                                            style={styles.imgvehicle}
                                            // Cambia la URL de la imagen
                                            src={primeraConsulta.firma_vo ? 
                                                Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_vo
                                                : "Holo"}
                                        />
                                        <View style={styles.textCenter}>
                                            <Text style={styles.text}>Firma</Text>
                                            <Text style={styles.text}>{primeraConsulta.nom_vo}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}
export default PDF_bitacora;
