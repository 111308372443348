//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from "../../general/Loading_papu";
import PDF_vehicle from './Procedimiento/PDF_vehicle';
import Excel_vehicle from './Procedimiento/Excel_vehicle';

//Images
import coche from "@/images/coche.png";
import ConvMedida from '@Components/general/ConvMedida';

//Librarys
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';

//Services
import Api from "@/services/api";
import { useUser } from '@/services/UserContext';

//Function can show the date and time
function getDateTime() {
    const now = new Date();

    // Obtenemos las partes de la fecha y hora
    const año = now.getFullYear();
    const mes = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque los meses en JavaScript comienzan desde 0
    const dia = String(now.getDate()).padStart(2, '0');
    const hora = String(now.getHours()).padStart(2, '0');
    const minutos = String(now.getMinutes()).padStart(2, '0');
    const segundos = String(now.getSeconds()).padStart(2, '0');

    // Formateamos la fecha y hora
    const fechaYHora = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

    return fechaYHora;
}

//You can change the value of the Form


function eliminateData(id) {
    // Preguntar al usuario si realmente desea eliminar el vehículo
    const confirmar = window.confirm("¿Realmente desea eliminar el vehículo?");
    if (confirmar) {
        // Si el usuario hizo clic en "Aceptar"
        //console.log(id);
        fetch(Api + "/automovil-flotilla.php?borrar=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                // Puedes llamar a otra función aquí o realizar cualquier otra acción necesaria
                window.location.href = '/flotilla/procedimiento';
            })
            .catch(console.log);
    } else {
        // Si el usuario hizo clic en "Cancelar" (No se hace nada)
    }
}

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
function VehicleWithID() {
    // Variables Internas
    const [verPDF, setVerPDF] = React.useState(false);
    const [verExcel, setVerExcel] = React.useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState({});
    const [isVisible, setVisible] = useState(true);
    const [hola, setHola] = useState(null);
    const [actualizado_capacidad_carga, setActualizado_capacidad_carga] = useState(null);

    //Variables externas
    const { userID } = useUser();
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    useEffect(() => {
        // El código aquí se ejecuta cuando el componente se monta
        console.log('El componente se montó');
        //console.log(id);
        fetch(Api + "automovil-flotilla.php/?consultar=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                //console.log(responseData);
                setVehiculo(responseData);
                setActualizado_capacidad_carga(responseData.primera_consulta[0].id_capacidad);
                setLoadedData(true);
            })
            .catch(console.log)
        return () => {
            // El código aquí se ejecuta cuando el componente se desmonta
            console.log('El componente se desmontó');
        };
    }, []); // El segundo argumento, un array vacío [], indica que este efecto se ejecutará solo una vez, como componentDidMount

    getDateTime(() => {
        const now = new Date();

        // Obtenemos las partes de la fecha y hora
        const año = now.getFullYear();
        const mes = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque los meses en JavaScript comienzan desde 0
        const dia = String(now.getDate()).padStart(2, '0');
        const hora = String(now.getHours()).padStart(2, '0');
        const minutos = String(now.getMinutes()).padStart(2, '0');
        const segundos = String(now.getSeconds()).padStart(2, '0');

        // Formateamos la fecha y hora
        const fechaYHora = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

        return fechaYHora;
    });

    const valueChange = (e) => {
        const newState = { ...vehiculo }; // Crear una copia del estado vehiculo
        newState.primera_consulta[0][e.target.name] = e.target.value; // Actualizar la propiedad en la primera consulta
        setVehiculo(newState); // Actualizar el estado con la nueva información
        console.log(newState); // ¡Usa newState en lugar de vehiculo para mostrar el estado actualizado!

    }

    //Componente imagen Input
    const handleImageChange = (event, set) => {
        const file = event.target.files[0];
        if (file) {
            set(file); // Aquí guardamos el archivo directamente
        }
    };

    const InputImage = ({ nomVar, nomSet, img }) => {
        return (
            <>
                {
                    nomVar ?
                        <img
                            src={URL.createObjectURL(nomVar)} // Creamos una URL temporal para mostrar la imagen
                            className="img-fluid rounded"
                            alt="Imagen cargada"
                        />
                        :
                        <img
                            src={img}
                            className="img-fluid rounded"
                            alt="No se ha podido cargar su imagen"
                        />
                }

                <input
                    type="file"
                    onChange={(e) => handleImageChange(e, nomSet)}
                    name="imagen"
                    id="imagen"
                    accept="image/*"
                    value={nomSet}
                    className={`form-control ${nomVar == null || nomVar == '' ? "is-invalid" : ""}`}
                />
            </>
        );
    };

    const sendData = (e) => {

        e.preventDefault();
        /*
        console.log('Formulario enviado');
        console.log(userID);
        console.log(vehiculo.primera_consulta[0].fabricante);
        console.log(vehiculo.primera_consulta[0].categoria);
        console.log(vehiculo.primera_consulta[0].modelo);
        console.log(vehiculo.primera_consulta[0].ano);
        console.log(vehiculo.primera_consulta[0].odometro);
        console.log(vehiculo.primera_consulta[0].neconomico);
        console.log(vehiculo.primera_consulta[0].placas);
        console.log(vehiculo.primera_consulta[0].niv);
        console.log(vehiculo.primera_consulta[0].estado);
        console.log(vehiculo.primera_consulta[0].descripcion);
        console.log(vehiculo.primera_consulta[0].ccombustible);
        console.log(hola); //Variable de la imagen del vehiculo
        console.log(vehiculo.primera_consulta[0].tcombustible);
        console.log(vehiculo.primera_consulta[0].ucapacidad);
        console.log(vehiculo.primera_consulta[0].mcapadacidad);
        console.log(vehiculo.segunda_consulta[0].veh_disponibilidad_descripcion);
        console.log(vehiculo.segunda_consulta[0].veh_disponibilidad_fecha);
        console.log(actualizado_capacidad_carga);
        console.log(vehiculo.primera_consulta[0].id_capacidad);
        */
        if (
            userID &&                                               //Id Usuario
            vehiculo.primera_consulta[0].fabricante &&
            vehiculo.primera_consulta[0].modelo &&
            vehiculo.primera_consulta[0].ano &&
            vehiculo.primera_consulta[0].categoria &&
            vehiculo.primera_consulta[0].odometro &&
            vehiculo.primera_consulta[0].neconomico &&
            vehiculo.primera_consulta[0].niv &&
            vehiculo.primera_consulta[0].estado &&
            vehiculo.primera_consulta[0].ccombustible &&
            vehiculo.primera_consulta[0].tcombustible &&
            vehiculo.primera_consulta[0].ucapacidad &&
            vehiculo.primera_consulta[0].mcapadacidad &&
            id &&                                                   //Id del vehiculo
            vehiculo.primera_consulta[0].descripcion &&
            actualizado_capacidad_carga
        ) {
            // CARGA DE INFORMACIÓN
            var formData = new FormData();
            formData.append("marcaInput", vehiculo.primera_consulta[0].fabricante);
            formData.append("modelo", vehiculo.primera_consulta[0].modelo);
            formData.append("ano", vehiculo.primera_consulta[0].ano);
            formData.append("categoria", vehiculo.primera_consulta[0].categoria);
            formData.append("odometro", vehiculo.primera_consulta[0].odometro);
            formData.append("placas", vehiculo.primera_consulta[0].placas);
            formData.append("neconomico", vehiculo.primera_consulta[0].neconomico);
            formData.append("niv", vehiculo.primera_consulta[0].niv);
            formData.append("estado", vehiculo.primera_consulta[0].estado); // Activo o eliminado
            formData.append("descripcion", vehiculo.primera_consulta[0].descripcion);
            formData.append("ltcombustible", vehiculo.primera_consulta[0].ccombustible);
            formData.append("tipocombustible", vehiculo.primera_consulta[0].tcombustible);
            formData.append("ccarga", vehiculo.primera_consulta[0].ucapacidad);
            formData.append("mccarga", vehiculo.primera_consulta[0].mcapadacidad);
            formData.append("userID", userID);
            formData.append("id", id);
            formData.append("id_vehcapacidadd", actualizado_capacidad_carga);
            formData.append("actualizado_capacidad_carga", vehiculo.primera_consulta[0].id_capacidad);
            
            // Si cargo imagen
            if(hola){
                formData.append("imagen", hola);
            }

            fetch(Api + "/automovil-flotilla.php?actualizar=" + id, {
                method: "POST",
                body: formData
            })
                .then(response => response.json())
                .then((responseData) => {
                    //console.log(responseData);
                    if (responseData.success === 1) {

                        window.alert('La información del vehículo ha sido actualizada');
                        window.location.reload();

                    } else {
                        window.alert('La información se ha conservado, la información no ha podido ser actualizada.');
                    }
                }).catch(console.log)
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }

    };

    if (!loadedData) {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <Loading_papu></Loading_papu>
            </div>
        );
    } else {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <div className="card">
                    <div className="card-header">
                        <h2>{vehiculo.primera_consulta[0].vehiculo} | ID: {id}</h2>
                    </div>
                    <form onSubmit={sendData}>
                        <div className="card-body">
                            <Row>
                                <Col sm={8}>
                                    <Row>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Fabricante</label>
                                                <input type="text" required
                                                    className={`form-control ${vehiculo.primera_consulta[0].fabricante == '' ? "is-invalid" : ""}`}
                                                    id='fabricante' name='fabricante'
                                                    placeholder="Seleccione el fabricante o escríbalo"
                                                    value={vehiculo.primera_consulta[0].fabricante}
                                                    onChange={valueChange} />
                                                <small id="helpId" className="form-text text-muted">*Ingrese el fabricante del vehículo</small>
                                            </div>)}
                                            {isVisible && (<label htmlFor="">Fabricante</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].fabricante}</p>)}
                                        </Col>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Modelo</label>
                                                <input type="text"
                                                    required
                                                    className={`form-control ${vehiculo.primera_consulta[0].modelo == '' ? "is-invalid" : ""}`}
                                                    value={vehiculo.primera_consulta[0].modelo}
                                                    name="modelo" id="modelo"
                                                    aria-describedby="helpId"
                                                    placeholder="Escriba el modelo del vehículo"
                                                    onChange={valueChange}
                                                />
                                                <small id="helpId" className="form-text text-muted">*Ingrese el modelo del vehículo</small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Modelo</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].modelo}</p>)}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Año</label>
                                                <input type="number"
                                                    required
                                                    className={`form-control ${vehiculo.primera_consulta[0].ano == '' ? "is-invalid" : ""}`}
                                                    value={vehiculo.primera_consulta[0].ano}
                                                    name="ano" id="ano"
                                                    aria-describedby="helpId"
                                                    placeholder="Ingrese el año del vehículo"
                                                    min={1930} max={2023} maxLength={4}
                                                    onChange={valueChange}
                                                />
                                                <small id="helpId" className="form-text text-muted">
                                                    *Ingrese el año del vehículo (entre 1930 y el año actual)
                                                </small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Año</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].ano}</p>)}
                                        </Col>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Categoría</label>
                                                <select className={`custom-select form-control ${vehiculo.primera_consulta[0].categoria == '' ? "is-invalid" : ""}`}
                                                    value={vehiculo.primera_consulta[0].categoria} name="categoria"
                                                    id="categoria" required onChange={valueChange}
                                                >
                                                    <option value="">Seleccione una categoría</option>
                                                    <optgroup label="Vehículos ligeros">
                                                        <option value="Vehículo ligero">Vehículo ligero</option>
                                                        <option value="Compacto">Compacto</option>
                                                        <option value="Motocicleta">Motocicleta</option>
                                                        <option value="Pick-up">Pick-up</option>
                                                        <option value="SUV">SUV</option>
                                                        <option value="Sedan">Sedan</option>
                                                    </optgroup>
                                                    <optgroup label="Vehículos pesados">
                                                        <option value="Vehículo pesado">Vehículo pesado</option>
                                                        <option value="Camión">Camión</option>
                                                        <option value="Furgoneta">Furgoneta</option>
                                                        <option value="Maquinaria">Maquinaria</option>
                                                        <option value="Plataforma">Plataforma</option>
                                                        <option value="Remolque">Remolque</option>
                                                    </optgroup>
                                                    <option value="Otros">Otros</option>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione la categoría del vehículo
                                                </small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Categoría</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].categoria}</p>)}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Placas vehiculares</label>
                                                <input type="text"
                                                    className="form-control" value={vehiculo.primera_consulta[0].placas} name="placas" id="placas"
                                                    aria-describedby="helpId"
                                                    placeholder="Escriba las siglas de las placas del vehículo"
                                                    minLength={7} maxLength={10}
                                                    onChange={valueChange}
                                                />
                                                <small id="helpId" className="form-text text-muted">Ingrese los caracteres de las placas del vehículo (Si tiene)</small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Placas vehiculares</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].placas}</p>)}
                                        </Col>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">NIV</label>
                                                <input type="text"
                                                    className={`form-control ${vehiculo.primera_consulta[0].niv == '' ? "is-invalid" : ""}`}
                                                    value={vehiculo.primera_consulta[0].niv}
                                                    name="niv" id="niv" aria-describedby="helpId"
                                                    required
                                                    placeholder="Escriba el número NIV del vehículo"
                                                    minLength={17} maxLength={17}
                                                    onChange={valueChange}
                                                />
                                                <small id="helpId" className="form-text text-muted">Ingrese el número NIV del vehículo</small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">NIV</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].niv}</p>)}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Odómetro o kilometraje actual</label>
                                                <input type="number"
                                                    className="form-control" value={vehiculo.primera_consulta[0].odometro} name="odometro"
                                                    id="odometro" aria-describedby="helpId"
                                                    placeholder="Escriba el kilometraje que a recorrido el vehículo"
                                                    min="0" max="999999999999"
                                                    onChange={valueChange}
                                                    step={0.1} />
                                                <small id="helpId" className="form-text text-muted">Ingrese el kilometraje actual del vehículo. Número enteros máximo 1 decimal</small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Odómetro</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].odometro}</p>)}
                                        </Col>
                                        <Col>
                                            {!isVisible && (<div className="form-group">
                                                <label htmlFor="">Número económico</label>
                                                <input type="number"
                                                    className="form-control" value={vehiculo.primera_consulta[0].neconomico} name="neconomico"
                                                    id="neconomico" aria-describedby="helpId"
                                                    placeholder="Escriba el número económico"
                                                    step={1} minLength={5} maxLength={6}
                                                    onChange={valueChange}
                                                />
                                                <small id="helpId" className="form-text text-muted">Ingrese el número económico del vehículo</small>
                                            </div>)}

                                            {isVisible && (<label htmlFor="">Número económico</label>)}
                                            {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].neconomico}</p>)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm>
                                    {!isVisible && (
                                        <div className="form-group files-upload">
                                            <InputImage nomVar={hola} nomSet={setHola} img={coche} />
                                        </div>
                                    )}

                                    {isVisible && (<label htmlFor=""></label>)}
                                    {isVisible && (<img
                                        // Servidor/Carpeta_vehiculo/N_economico/ruta
                                        src={process.env.REACT_APP_LINK_RUTA_IMG_VEHICULO 
                                            + vehiculo.primera_consulta[0].neconomico + "/" + vehiculo.primera_consulta[0].ruta}
                                        className="img-fluid rounded"  /* Clase CSS para redondear la imagen */
                                        alt="No se encontró la imagen, suba una imagen por favor en la acción modificar."
                                    />)}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Situación actual del Vehículo</label>
                                        <select
                                            className={`custom-select form-control ${vehiculo.primera_consulta[0].estado == '' ? "is-invalid" : ""}`}
                                            value={vehiculo.primera_consulta[0].estado}
                                            onChange={valueChange}
                                            name="estado"
                                            id="estado" required
                                        >
                                            <option value="">Seleccione el estado del vehículo</option>
                                            <option value="ACTIVO">EN USO</option>
                                            <option value="DISPONIBLE">DISPONIBLE</option>
                                            <option value="EN RENTA">EN RENTA</option>
                                            <option value="ELIMINADO">ELIMINADO O VENDIDO</option>
                                        </select>
                                        <small id="helpId" className="form-text text-muted">
                                            *Seleccione la situación actual del vehículo
                                        </small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Estado de disponibilidad</label>)}
                                    {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].estado}</p>)}
                                </Col>
                                <Col>
                                    <label htmlFor="">Descripción del estado de disponibilidad</label>
                                    <p className="card-text">{vehiculo.segunda_consulta[0].veh_disponibilidad_descripcion}</p>
                                </Col>
                                <Col>
                                    <label htmlFor="">Fecha de modificación</label>
                                    <p className="card-text">{vehiculo.segunda_consulta[0].veh_disponibilidad_fecha}</p>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Capacidad del combustible</label>
                                        <input type="number"
                                            required
                                            className={`form-control ${vehiculo.primera_consulta[0].ccombustible == '' ? "is-invalid" : ""}`}
                                            value={vehiculo.primera_consulta[0].ccombustible} name="ccombustible" id="ccombustible"
                                            aria-describedby="helpId"
                                            placeholder="Escriba la capacidad en litros de combustibles máximos del vehículo"
                                            step={1} min={0} max={99999}
                                            onChange={valueChange} />
                                        <small id="helpId" className="form-text text-muted">*Ingrese la capacidad de combustible en litros del vehículo, si no consume escriba un 0</small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Capacidad en el depósito de combustible (Litros)</label>)}
                                    {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].ccombustible}</p>)}
                                </Col>
                                <Col>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Tipo de combustible</label>
                                        <select
                                            className={`custom-select form-control ${vehiculo.primera_consulta[0].tcombustible == '' ? "is-invalid" : ""}`}
                                            value={vehiculo.primera_consulta[0].tcombustible}
                                            name="tcombustible" id="tcombustible"
                                            onChange={valueChange}
                                            required>
                                            <option value="">Seleccione un combustible</option>
                                            <option value="No consume">No consume</option>
                                            <option value="Gasolina">Gasolina</option>
                                            <option value="Diesel">Diesel</option>
                                            <option value="Electrico">Eléctrico</option>
                                            <option value="Gas">Gas Natural Vehicular. GNV</option>
                                            <option value="Hibrido">Híbrido</option>
                                        </select>
                                        <small id="helpId" className="form-text text-muted">
                                            *Seleccione el tipo de combustible del vehículo
                                        </small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Tipo de combustible</label>)}
                                    {isVisible && (vehiculo.primera_consulta[0].tcombustible === 'No consume' ? (
                                        <p className="card-text">No consume</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === 'Gasolina' ? (
                                        <p className="card-text">Gasolina</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === 'Diesel' ? (
                                        <p className="card-text">Diesel</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === 'Electrico' ? (
                                        <p className="card-text">Eléctrico</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === 'Gas' ? (
                                        <p className="card-text">Gas Natural Vehicular. GNV</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === 'Hibrido' ? (
                                        <p className="card-text">Híbrido</p>
                                    ) : vehiculo.primera_consulta[0].tcombustible === '' ? (
                                        <p className="card-text">Seleccione un combustible</p>
                                    ) : null)}
                                </Col>
                                <Col>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Capacidad de carga</label>
                                        <input type="number"
                                            className={`form-control ${vehiculo.primera_consulta[0].ucapacidad == '' ? "is-invalid" : ""}`}
                                            value={vehiculo.primera_consulta[0].ucapacidad} name="ucapacidad" id="ucapacidad"
                                            aria-describedby="helpId"
                                            placeholder="Escriba la capacidad de carga del vehículo"
                                            step={1} min={0} max={9999999999}
                                            onChange={valueChange}
                                            required />
                                        <small id="helpId" className="form-text text-muted">
                                            *Ingrese la capacidad de carga del vehículo (Si no tiene registro indique un 0)
                                        </small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Capacidad de carga</label>)}
                                    {isVisible && (<p className="card-text">{vehiculo.primera_consulta[0].ucapacidad}</p>)}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Medida de capacidad de carga</label>
                                        <select
                                            className={`custom-select form-control ${vehiculo.primera_consulta[0].id_capacidad == '' ? "is-invalid" : ""}`}
                                            required
                                            value={vehiculo.primera_consulta[0].id_capacidad}
                                            name="id_capacidad" id="id_capacidad" onChange={valueChange}>
                                            <option value="">Seleccione una medida de capacidad de carga</option>
                                            <option value='1'>Container o Contenedor</option>
                                            <option value="2">Gramos</option>
                                            <option value="3">Kilogramos</option>
                                            <option value="4">Toneladas</option>
                                            <option value="5">Libras</option>
                                            <option value="6">Onzas</option>
                                            <option value="7">Palé o Paleta</option>
                                            <option value="8">Quintal</option>
                                            <option value="9">Tonelada corta</option>
                                            <option value="10">Tonelada larga</option>
                                            <option value="11">Unidad no reconocida</option>
                                        </select>
                                        <small id="helpId" className="form-text text-muted">
                                            *Seleccione la medida de la capacidad del vehículo
                                        </small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Medida de capacidad de carga</label>)}

                                    {isVisible && (
                                        <p><ConvMedida unidad={vehiculo.primera_consulta[0].mcapadacidad} /></p>
                                    )}
                                </Col>
                                <Col sm={8}>
                                    {!isVisible && (<div className="form-group">
                                        <label htmlFor="">Descripción del vehículo</label>
                                        <textarea className="form-control" value={vehiculo.primera_consulta[0].descripcion}
                                            name="descripcion" id="descripcion" rows="3" maxLength={300}
                                            onChange={valueChange}
                                            placeholder='Puede usted escribir alguna descripción del vehículo, plataforma u objeto'></textarea>
                                        <small id="helpId" className="form-text text-muted">
                                            Describa el vehículo
                                        </small>
                                    </div>)}

                                    {isVisible && (<label htmlFor="">Descripción del vehículo</label>)}
                                    {isVisible && (<p className="card-text textarea-like">{vehiculo.primera_consulta[0].descripcion}</p>)}
                                </Col>
                            </Row>
                            <div>
                                {verPDF ? (
                                    <PDFViewer style={{ width: "100%", height: "90vh" }}>
                                        <PDF_vehicle id={id} />
                                    </PDFViewer>
                                ) : null}
                            </div>
                        </div>
                        <div className="card-footer text-muted">
                            <Row>
                                <Col>
                                    <h5>Acciones</h5>
                                    <div className="btn-group w-100" role="group" aria-label="">
                                        {isVisible && (<button type="button"
                                            className="btn btn-warning btn-block"
                                            onClick={() => setVisible(false)}
                                        >Editar</button>)}
                                        {isVisible && (<button type="button"
                                            className="btn btn-danger btn-block"
                                            onClick={() => eliminateData(id)}
                                        >Eliminar</button>)}
                                        {!isVisible && (<button
                                            type="submit"
                                            className="btn btn-warning btn-block"
                                        >Aceptar</button>)}
                                        {!isVisible && (<button type="button"
                                            className="btn btn-secondary btn-block"
                                            onClick={() => setVisible(true)}
                                        >Cancelar</button>)}
                                    </div>

                                </Col>
                                <Col>
                                    {isVisible && (<h5>Exportación</h5>)}
                                    <div className="btn-group w-100" role="group" aria-label="">
                                        {isVisible && (<button type="button"
                                            className="btn btn-primary btn-block"
                                            onClick={() => {
                                                setVerPDF(!verPDF);
                                            }}
                                        >PDF</button>)}

                                        {isVisible && (
                                            verExcel ? (
                                                <Excel_vehicle id={id} />
                                            ) : null
                                        )}

                                        {isVisible && (
                                            !verExcel && (
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-block"
                                                    onClick={() => {
                                                        setVerExcel(!verExcel);
                                                    }}
                                                >EXCEL</button>
                                            )
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default VehicleWithID;
