// Librarys
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

function Report_fails_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);
    const { pagFlotilla, userID } = useUser();

    const [filters, setFilters] = useState({
        numero: "",
        folio: "",
        creador: "",
        fechaCreacion: "",
        estatus: ""
    });

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value
        });
    };

    const filteredDocuments = documentss.filter(doc => {
        return (
            doc.folio.includes(filters.folio) &&
            doc.usuario.includes(filters.creador) &&
            doc.fecha_generada.includes(filters.fechaCreacion) &&
            doc.estatus.includes(filters.estatus)
            // Puedes agregar más condiciones según necesites
        );
    });


    const loadData = () => {
        fetch(Api + "reportes.php?consultafio=" + userID)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setLoadedData(true);
                setDocumentss(responseData.data);
            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
    }, []);

    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];

    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }

    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora de Fallas, Incidencias u Ocurrencias</h2>
                    <div className="card">
                        <div className="card-header">
                            {CRUDUser[0] === 1 && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/fallas-incidencias-ocurrencias/crear"}>
                                    Agrega un nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes de fallas, incidencias u ocurrencias</h4>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "numero")} placeholder='Filtro de numero' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "folio")} placeholder='Filtro de folio' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "creador")} placeholder='Filtro de creador' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus")} placeholder='Filtro de estatus' /></th>
                                        </tr>
                                        <tr>
                                            <th>Número</th>
                                            <th>Folio</th>
                                            <th>Creador</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    {CRUDUser[2] === 3 && (
                                        <tbody>
                                            {filteredDocuments.map((documentss, index) => (
                                                <tr key={documentss.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{documentss.folio}</td>
                                                    <td>{documentss.usuario}</td>
                                                    <td>{documentss.fecha_generada}</td>
                                                    <td>{documentss.estatus}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/reporte/fallas-incidencias-ocurrencias/" + documentss.folio}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Bitacora de Fallas, Incidencias u Ocurrencias</h2>
                    <div className="card">
                        <div className="card-header">
                            {CRUDUser[0] === 1 && (
                                <Link className="btn btn-success text-white" to={"/flotilla/reporte/fallas-incidencias-ocurrencias/crear"}>
                                    Agrega un nuevo reporte
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Lista de reportes de fallas, incidencias u ocurrencias</h4>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "numero")} placeholder='Filtro de numero' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "folio")} placeholder='Filtro de folio' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "creador")} placeholder='Filtro de creador' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estatus")} placeholder='Filtro de estatus' /></th>
                                        </tr>
                                        <tr>
                                            <th>Número</th>
                                            <th>Folio</th>
                                            <th>Creador</th>
                                            <th>Fecha de creación</th>
                                            <th>Estatus</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    {CRUDUser[2] === 3 && (
                                        <tbody>
                                            {filteredDocuments.map((documentss, index) => (
                                                <tr key={documentss.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{documentss.folio}</td>
                                                    <td>{documentss.usuario}</td>
                                                    <td>{documentss.fecha_generada}</td>
                                                    <td>{documentss.estatus}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/reporte/fallas-incidencias-ocurrencias/" + documentss.folio}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte de fallas, Incidencias u ocurrencias</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Report_fails_flotilla;