//Librarys
import React, { useEffect, useState } from 'react';
import * as XLSX from "xlsx";

//Components
import Api from "@/services/api";

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
const Excel_vehicle = ({ id }) => {

    const [loadedData, setLoadedData] = useState(false);
    const [objeto, setObjeto] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {

        setLoading(true);
        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(objeto);
        XLSX.utils.book_append_sheet(libro, hoja, "Productos");

        await new Promise(resolve => setTimeout(resolve, 1000)); // Waiting it 1 second
        XLSX.writeFile(libro, "Vehiculo_" + objeto[0].neconomico + ".xlsx");
        setLoading(false);
        window.alert('Archivo descargado');
    };

    useEffect(() => {
        // El código aquí se ejecuta cuando el componente se monta
        //console.log('El componente se montó');
        //console.log(id);
        fetch(Api + "automovil-flotilla.php/?consultar=" + id)
            .then(res => res.json())
            .then((json) => {
                //console.log(json);
                setLoadedData(true);
                if (json) {
                    let listaObjetos = json.primera_consulta.map((data) => {
                        return {
                            id: data.id,
                            vehiculo: data.vehiculo,
                            categoria: data.categoria,
                            fabricante: data.fabricante,
                            modelo: data.modelo,
                            ano: data.ano,
                            odometro: data.odometro,
                            neconomico: data.neconomico,
                            placas: data.placas,
                            niv: data.niv,
                            estado: data.estado,
                            descripcion: data.descripcion,
                            ccombustible: data.ccombustible,
                            tcombustible: data.combustible,
                            ucapacidad: data.ucapacidad,
                            mcapadacidad: data.mcapadacidad,
                        };
                    });
                    setObjeto(listaObjetos);
                } else {
                    console.log('Error en la respuesta del servidor.');
                }
            }).catch((error) => {
                // Manejar errores de la solicitud
                console.error('Error en la solicitud:', error);
            });
    }, []); // El segundo argumento, un array vacío [], indica que este efecto se ejecutará solo una vez, como componentDidMount

    if (!loadedData) {
        return (
            <p>Información exportándose a archivo XLSX.</p>
        );
    } else {
        return (
            <div>
                {loading ? (
                    <p>Descargando archivo...</p>
                ) : (
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={handleDownload}
                    >Descargar archivo
                    </button>
                )}
            </div>
        );
    }
};


export default Excel_vehicle;
