// import './App.css';
import './App.scss';

// Components
import User_table from "./components/Flotilla/components/User_table";
import Onlyfooter from "./components/general/Onlyfooter";
import Central_page from "./components/central/pages/Central_page";
import Central_flotilla from './components/Flotilla/pages/Index/Central_flotilla';
import Vehicle_flotilla from './components/Flotilla/pages/Vehiculo/Vehicle_flotilla';
import Report_flotilla from "./components/Flotilla/pages/Report_flotilla";
import List_users_flotilla from "./components/Flotilla/pages/ProcUser/List_users_flotilla";
import User_new_flotilla from "./components/Flotilla/pages/ProcUser/User_new_flotilla";
import User_edit_flotilla from './components/Flotilla/pages/PerfilUser/User_edit_flotilla';
import Calendar_flotilla from './components/Flotilla/pages/Calendar_flotilla';
import Trips_flotilla from './components/Flotilla/pages/ViajesUbi/Trips_flotilla';
import Documents_flotilla from "./components/Flotilla/pages/Documents/Documents_flotilla";
import Documents_new_flotilla from "./components/Flotilla/pages/Documents/Documents_new_flotilla";
import Documents_view_flotilla from "./components/Flotilla/pages/Documents/Documents_view_flotilla";
import List_vehicle_flotilla from './components/Flotilla/pages/List_vehicle_flotilla';
import Vehicle_edit_flotilla from "./components/Flotilla/pages/Vehicle_edit_flotilla";
import Dashboard_flotilla from './components/Flotilla/pages/Dashboard_flotilla';
import Geocerca_flotilla from './components/Flotilla/pages/Geocerca_flotilla';
import Calculator_flotilla from './components/Flotilla/pages/Calculator_Flotilla';
import Maintenance_log_flotilla from './components/Flotilla/pages/ReporteMantenimiento/Maintenance_log_flotilla';
import Maintenance_view_flotilla from './components/Flotilla/pages/ReporteMantenimiento/Maintenance_view_flotilla';
import Report_maintenance_flotilla from './components/Flotilla/pages/ReporteMantenimiento/Report_maintenance_flotilla';
import Report_fails_flotilla from './components/Flotilla/pages/ReporteFallas/Report_fails_flotilla';
import Report_accidente_flotilla from './components/Flotilla/pages/Report_accident_flotilla';
import Report_technician_flotilla from './components/Flotilla/pages/ReporteTecnico/Report_technician_flotilla';
import Technician_new_flotilla from './components/Flotilla/pages/ReporteTecnico/Technician_new_flotilla';
import Technician_view_flotilla from './components/Flotilla/pages/ReporteTecnico/Technician_view_flotilla';
import Fails_new_flotilla from './components/Flotilla/pages/ReporteFallas/Fails_new_flotilla';
import Fails_view_flotilla from './components/Flotilla/pages/ReporteFallas/Fails_view_flotilla';
import Vehicles_list_flotilla from './components/Flotilla/pages/Procedimiento/Vehicles_list_flotilla';
import Vehicles_new_flotilla from './components/Flotilla/pages/Procedimiento/Vehicles_new_flotilla';
import VehicleWithID from './components/Flotilla/components/Vehicle_new_flotilla';
import LoginUser from './components/general/LoginUser';
import User_priv from './components/Flotilla/pages/ProcUser/User_priv';
import NewDoc from './components/Flotilla/pages/UserDoc/NewDoc';
import ViewDoc from './components/Flotilla/pages/UserDoc/ViewDoc';

//Librarys
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { UserProvider } from '@/services/UserContext';

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="container-fluid">

          <Routes>
            {/* Central */}
            <Route path="/" element={<Central_page />}></Route>
            <Route path="/index" element={<Central_page />}></Route>

            {/* Flotilla Vehicular */}
            <Route path="/inventario" element={<User_table />}></Route>

            {/* Inventario */}
            <Route path='/login' element={<LoginUser />}></Route>
            <Route exact path="/flotilla" element={<Central_flotilla />}></Route>
            <Route path='/flotilla/vehiculo/:id' element={<Vehicle_flotilla />}></Route>
            <Route exact path='/flotilla/reporte' element={<Report_flotilla />}></Route>
            <Route path='/flotilla/reporte/mantenimiento' element={<Report_maintenance_flotilla />}></Route>
            <Route path="/flotilla/control-de-usuarios" element={<List_users_flotilla />}></Route>
            <Route path="/flotilla/crear-nuevo-usuario" element={<User_new_flotilla />}></Route>
            <Route path="/flotilla/editar-usuario/:id" element={<User_priv />}></Route>
            <Route exact path="/flotilla/perfil" element={<User_edit_flotilla />}></Route>
            <Route exact path="/flotilla/perfil/crear-nuevo-documento/" element={<NewDoc />}></Route>
            <Route path="/flotilla/perfil/editar-documento/:id" element={<ViewDoc />}></Route>
            <Route path="/flotilla/calendario" element={<Calendar_flotilla />}></Route>
            <Route path="/flotilla/viajes-y-ubicaciones/:id" element={<Trips_flotilla />}></Route>
            <Route path='/flotilla/documentos/:id' element={<Documents_flotilla />}></Route>
            <Route path='/flotilla/documentos/:id/crear' element={<Documents_new_flotilla />}></Route>
            <Route path='/flotilla/documentos/:id/consultar/:id_doc' element={<Documents_view_flotilla />}></Route>
            <Route path='/flotilla/vehiculo/acciones' element={<List_vehicle_flotilla />}></Route>
            <Route path='/flotilla/vehiculo/acciones/agregar-nuevo-vehiculo' element={<Vehicles_new_flotilla />}></Route>
            <Route path='/flotilla/vehiculo/acciones/editar-vehiculo' element={<Vehicle_edit_flotilla />}></Route>
            <Route path='/flotilla/vehiculo/geocerca' element={<Geocerca_flotilla />}></Route>
            <Route path='/flotilla/graficos' element={<Dashboard_flotilla />}></Route>
            <Route path='/flotilla/calculadora' element={<Calculator_flotilla />}></Route>
            <Route path='/flotilla/bitacora-de-mantenimiento' element={<Maintenance_log_flotilla />}></Route>
            <Route path='/flotilla/bitacora-de-mantenimiento/consultar/:id' element={<Maintenance_view_flotilla />}></Route>
            <Route path='/flotilla/reporte/fallas-incidencias-ocurrencias' element={<Report_fails_flotilla />}></Route>
            <Route path='/flotilla/reporte/fallas-incidencias-ocurrencias/:id' element={<Fails_view_flotilla />}></Route>
            <Route path='/flotilla/reporte/fallas-incidencias-ocurrencias/crear' element={<Fails_new_flotilla />}></Route>
            <Route path='/flotilla/reporte/accidentes' element={<Report_accidente_flotilla />}></Route>
            <Route path='/flotilla/reporte/tecnico' element={<Report_technician_flotilla />}></Route>
            <Route path='/flotilla/reporte/tecnico/crear' element={<Technician_new_flotilla />}></Route>
            <Route path='/flotilla/reporte/tecnico/crear/:id' element={<Technician_new_flotilla />}></Route>
            <Route path='/flotilla/reporte/tecnico/:id' element={<Technician_view_flotilla />}></Route>
            <Route path='/flotilla/procedimiento' element={<Vehicles_list_flotilla />}></Route>
            <Route path='/flotilla/procedimiento/agregar-vehiculo' element={<Vehicles_new_flotilla />}></Route>
            <Route path='/flotilla/procedimiento/consultar/:id' element={<VehicleWithID />}></Route>
          </Routes>

        </div>
        <Onlyfooter></Onlyfooter>
      </Router>
    </UserProvider>
  );

}

export default App;
