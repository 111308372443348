import React from 'react';

import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';

class Report_accidente_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <div className="card">
                    <div className="card-header">
                        Reporte de accidente
                    </div>
                    <div className="card-body">
                        <h4 className="card-title">Title</h4>
                        <p className="card-text">Text</p>
                        
                    </div>
                    <div className="card-footer text-muted">
                        Footer
                    </div>
                </div>
            </div>
        );
    }
}
export default Report_accidente_flotilla;