//Services
import { Link } from 'react-router-dom';
import React from 'react';

const BtnMenu = ({ t, link, img }) => {
    return (
        <Link
            to={link}
            className="text-decoration-none w-100 h-100"
            style={{ color: 'inherit' }}
        >
            <div className="card card-hover">
                <img className="card-img" src={img} alt={`Botón de ${t}`} />
                <div className="card-img-overlay">
                    <h4 className="card-title card-titles">{t}</h4>
                </div>
            </div>
        </Link>
    );
}
export default BtnMenu;