import React from 'react';
import whatsapp from "@/images/whatsapp.png";
import facebook from "@/images/facebook.png";
import email from "@/images/manzana.png";
import maps from "@/images/mapas-de-google.png";
import logo from "@/images/racecarreteras.jpg"
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Onlyfooter extends React.Component {
  state = {}
  render() {
    const year = new Date().getFullYear();
    return (
      <footer>
        <br />
        <Container fluid className='footercito'>
          <Row>
            <Col sm={2}>
              {/* Logo image */}
              <img src={logo} alt='Logo Race Carreteras S.A. de C.V.' className='footer-logo'></img>
            </Col>
            <Col sm className='footer-left'>
              <Row className='footer-yellow'>
                <Col sm={7}>
                  <h3>RACE CARRETERAS S.A. DE C.V.</h3>
                  <span className='colorcito'>
                  <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}>Página principal</Link>
                  <br />
                  <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}>Ubicación</Link>
                  <br />
                  <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}>Soporte técnico</Link>
                  </span>
                </Col>
                <Col sm>
                  <Row>
                    <Col>
                      <h3>CONTACTO</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-row">
                      <div className="media">
                        <a className="d-flex align-self-bottom" href="https://www.facebook.com/profile.php?id=100069564936879&locale=ms_MY" target="_blank" rel="noreferrer noopener">
                          <img src={facebook} alt="facebook" className='footer-images' />
                        </a>
                      </div>
                      <div className="media">
                        <a className="d-flex align-self-bottom" href="mailto:ulises.tellez@racecarreteras.com?Subject=Deseo%20contactar%20con%20ustedes" target="_blank" rel="noreferrer noopener">
                          <img src={email} alt="Correo electronico" className='footer-images' />
                        </a>
                      </div>
                      <div className="media">
                        <a className="d-flex align-self-bottom" href="https://goo.gl/maps/11GzBpgKFC4q16my8" target="_blank" rel="noreferrer noopener">
                          <img src={maps} alt="Ubicación" className='footer-images' />
                        </a>
                      </div>
                      <div className="media">
                        <a className="d-flex align-self-bottom" href="https://wa.me/527713763646/?text=urlencodedtext" target="_blank" rel="noreferrer">
                          <img src={whatsapp} alt="WhatsApp" className='footer-images' />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='footer-red'>
                <Col sm className='d-flex flex-row text-right'>
                  <span className='footer-last'>
                  <p>
                    <span className='text-footer'>
                    RACE CARRETERAS S.A. DE C.V. {year}. ©
                    </span>
                    Todos los derechos reservados.</p>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer >
    );
  }
}

export default Onlyfooter;