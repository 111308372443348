import React from 'react';

const AnyTable = ({ lt, lc, col }) => {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        {lt.map((titulo, index) => (
                            <th key={index}>{titulo}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {lc.map((item, rowIndex) => (
                        <tr key={item.id_usuario}>
                            {col.map((cell, colIndex) => {
                                // Llama a la función correspondiente para la columna actual y el item actual
                                return cell(item, rowIndex);
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}; 
export default AnyTable;