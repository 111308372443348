//Librarys
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//Images
import logoRace from "@/images/racecarreteras logo.png";
import usuario from "@/images/usuario.png";
import chatIcon from "@/images/chat_icon.png";

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';


function NavBarFlotilla() {
    //Variables externas
    const {
        userID,
        setUserID,
        userName,
        setUserName,
        jerarquia,
        setJerarquia,
        pagFlotilla
    } = useUser();
    // Variables internas
    const [img, setImg] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    // Componente menu desplegable
    const UserMenu = () => {
        //Variables externas
        return (
            <div
                style={{
                    position: 'absolute', // This positions the menu absolutely
                    top: '100%', // This positions the menu below the button
                    right: 0, // This aligns the menu to the right
                    zIndex: 1000 // This ensures the menu stacks on top of other content
                }}
                className="user-menu" onMouseLeave={(e) => setIsVisible(false)}>
                <ul className="list-group">
                    <Link to="/soporte-tecnico" className="list-group-item">Soporte técnico</Link>
                    <Link to="/manual-usuario" className="list-group-item">Manual de usuario</Link>
                    <Link to="/alertas-notificaciones" className="list-group-item">Alerta y notificaciones</Link>
                    <Link to="/flotilla/perfil" className="list-group-item">Perfil de usuario</Link>
                    {(jerarquia == 'Root' || jerarquia == 'Administrador' || existsInPagFlotilla(pagFlotilla, 3, "Control de usuarios")) &&
                        (<Link to="flotilla/control-de-usuarios" className="list-group-item">Control de usuario</Link>)}
                    <Link
                        onClick={(e) => {
                            setUserID(null);
                            setUserName(null);
                            setJerarquia(null);
                        }}
                        className="list-group-item"
                    >Cerrar sesión
                    </Link>
                </ul>
            </div>
        );
    };

    const loadData = () => {
        fetch(Api + "usuarios.php?id_usuario=" + userID + "&perfil=" + 'Imagen')
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setImg(responseData.perfil[0]);
            })
            .catch(console.log);
    };

    //Revisa estado del usuario
    useEffect(() => {
        // Redirige si todas las variables de estado necesarias no están establecidas
        if (!userID || !userName || !jerarquia) {
            // Si alguno de los valores es falso o no está definido, redirige a la página de login
            window.location.href = "/login";
        }
        loadData();
    }, [userID, userName, jerarquia]);


    return (
        <Navbar expand="lg" className="bg-body-tertiary mb-3 ">
            <Link to={'/flotilla'}>
                <div className="brand">
                    <img src={logoRace} alt="Race Carreteras S.A. de C.V." className="brand-image" />
                </div>
            </Link>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas id="offcanvasNavbar" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Link to={'/flotilla'}>
                            <div className="brand">
                                <img src={logoRace} alt="Race Carreteras S.A. de C.V." className="brand-image" />
                            </div>
                        </Link>
                        Race Carreteras S.A. de C.V.
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="d-flex justify-content-center align-items-center w-100">
                        <Nav.Link href="/" className='hover-Bar1 font-weight-bold'>Central</Nav.Link>
                        {existsInPagFlotilla(pagFlotilla, 3, "Flotilla") &&
                            <Nav.Link href="/flotilla" className='hover-Bar1'>Flotilla</Nav.Link>}
                        {existsInPagFlotilla(pagFlotilla, 3, "Inventario") &&
                            <Nav.Link href="/inventario" className='hover-Bar1 disabled'>Inventario</Nav.Link>}
                    </Nav>
                    <div className='directionNav'>
                        <div className='chat-Icons'>
                            {img ? (
                                <img
                                    src={Api + process.env.REACT_APP_LINK_IMG_USER + userID + "/img/" + img.img}
                                    className="brand-image rounded-circle border border-dark"
                                    alt="Imagen cargada"
                                    onMouseOver={() => setIsVisible(true)}
                                    onError={(e) => e.target.src = usuario}  // Aquí agregamos el manejo de error
                                />
                            ) : (
                                <img
                                    src={usuario}
                                    alt="Imagen del usuario"
                                    className="brand-image rounded-circle border border-dark"
                                    onMouseOver={() => setIsVisible(true)}
                                />
                            )}
                            {isVisible && <UserMenu />}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    );
}
export default NavBarFlotilla;
