//Librarys
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import InputInfoUser from '@/components/Flotilla/components/ProcUser/InputInfoUser';
import InputPrivUser from '@/components/Flotilla/components/ProcUser/InputPrivUser';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";
import { Link } from 'react-router-dom';

function User_new_flotilla() {
    //Variables externas
    const { pagFlotilla, userID } = useUser();
    //Variables internas
    const [inputValue, setInputValue] = useState('');
    const [check, setCheck] = useState('');
    const [loadedData, setLoadedData] = useState(null);
    const [info, setInfo] = useState([]);
    const [priv, setPriv] = useState([]);

    const loadData = () => {
        fetch(Api + "usuarios.php/?listemp")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setInfo(responseData.primera_consulta);
                setPriv(responseData.segunda_consulta);
            })
            .catch(console.log)
    };

    useEffect(() => {
        loadData();
    }, []);

    // Metodos CallBack
    // Manejador de cambio para InputInfoUser
    const handleInfoChange = (value) => {
        setInputValue(value);
    };

    // Manejador de cambio para InputPrivUser
    const handlePrivChange = (value) => {
        setCheck(value);
    };

    const sendData = (e) => {

        e.preventDefault();
        console.log(inputValue); // Ahora inputValue contiene el valor del input del componente hijo
        console.log(check);     // Ahora check contiene el valor del input del componente hijo
        
        if (
            userID &&
            inputValue.nombre &&
            inputValue.apellidoPaterno &&
            inputValue.email &&
            inputValue.empresa &&
            inputValue.pass &&
            inputValue.jerarquia &&
            inputValue.pue &&
            inputValue.depa &&
            check.length > 0
        ) {
            // Creación de variable que guardara los datos a enviar al servidor
            var formData = new FormData();
            if (inputValue.empresa == 'otros') {
                if (inputValue.nombreEmpresa) {
                    // Valor del cuadro de texto nombre empresa - Selección 'Escriba la empresa'
                    formData.append("empresanueva", "0");
                    formData.append("empresa", inputValue.nombreEmpresa);
                } else {
                    window.alert('Error.\nNo se ha podido cargar la empresa');
                    return;
                }
            } else {
                // Valor al seleccionar alguna empresa existente
                formData.append("empresanueva", "1");
                formData.append("empresa", inputValue.empresa);
            }
            // Verificación que exista Apellido Materno
            if (inputValue.apellidoMaterno) {
                formData.append("apellidoMaterno", inputValue.apellidoMaterno);
            }
            // Seguimos guardando los demas valores
            formData.append("userID", userID);
            formData.append("nombre", inputValue.nombre);
            formData.append("apellidoPaterno", inputValue.apellidoPaterno);
            formData.append("email", inputValue.email);
            formData.append("alias", inputValue.apodo);
            formData.append("pass", inputValue.pass);
            formData.append("jerarquia", inputValue.jerarquia);
            formData.append("privilegios", JSON.stringify(check));
            formData.append("id_depa", inputValue.depa);
            formData.append("id_pue", inputValue.pue);
            //Insercción API REST
            fetch(Api + "/usuarios.php?insusuario=1", {
                method: "POST",
                body: formData,
            }).then(response => response.json())
                .then((responseDataArray) => {

                    console.log(responseDataArray);

                    let successMessages = '';
                    let errorMessages = '';

                    // Iterar sobre cada objeto en la respuesta
                    responseDataArray.forEach((responseItem) => {
                        if (responseItem.success == 1) {
                            successMessages += responseItem.message + '\n';
                        } else if (responseItem.success == 0) {
                            errorMessages += responseItem.message + '\n';
                        }
                    });

                    // Mostrar mensajes
                    if (successMessages) {
                        window.alert(successMessages);
                        window.location.href = "/flotilla/control-de-usuarios";
                    }
                    if (errorMessages) {
                        window.alert('Lo lamentamos, no se ha podido realizar algunas operaciones:\n' + errorMessages);
                    }

                }).catch(console.log);
        } else {
            window.alert('Error.\nLe ha faltado rellenar algún cuadro de texto.');
            return;
        }
        
    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    if (existsInPagFlotilla(pagFlotilla, 1, "Control de usuarios")) {
        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {
            return (
                <Container className='Fluid'>
                    <Row>
                        <Nav_bar_flotilla></Nav_bar_flotilla>
                        <h2>Agrega un nuevo usuario</h2>
                    </Row>
                    <Row>
                        <form onSubmit={sendData}>
                            <Col>
                                <div className="card-columns">
                                    <div className="card">
                                        <div className="card-header">
                                            Información del usuario
                                        </div>
                                        <InputInfoUser disable={false} onInputChange={handleInfoChange} data={info} />
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            Privilegios del usuario
                                        </div>
                                        <InputPrivUser disable={false} onInputChange={handlePrivChange} data={priv} />
                                    </div>
                                    <div className="card">
                                        <div className="card-footer text-muted text-center">
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit"
                                                    className="btn btn-success"
                                                >
                                                    Agregar usuario
                                                </button>
                                                <Link className="btn btn-danger text-white" to="/flotilla/control-de-usuarios">Regresar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </form>
                    </Row>
                </Container>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Usted no tiene permitido estar en esta página</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default User_new_flotilla;