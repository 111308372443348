import React from 'react';
class User_table extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return ( 
            <table className="table">
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th>Nombre</th>
                        <th>Empresa</th>
                        <th>Permisos</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Foto</td>
                        <td>Jesus Eduardo</td>
                        <td>Race Carreteras</td>
                        <td>Permisos</td>
                        <td>Acción</td>
                    </tr>
                </tbody>
            </table>
         );
    }
}
 
export default User_table;