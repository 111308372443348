// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from "@/components/general/Loading_papu";
import PDF from '@/components/Flotilla/components/ReporteTecnico/PDF_estactual';
import Excel from '@/components/Flotilla/components/ReporteTecnico/Excel_estactual';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

// Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';
import { Link, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';

// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
function Technician_view_flotilla() {

    const { pagFlotilla, userID } = useUser();

    const [verPDF, setVerPDF] = React.useState(false);
    const [verExcel, setVerExcel] = React.useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState(null);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [estatusReporte, setEstatusReporte] = useState(null);
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    const [firma_ver, setFirmaVer] = useState(null);
    const [firma_vo, setFirmaVo] = useState(null);
    const sigCanvas = useRef({});
    const sigCanvas2 = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [isVisible, setVisible] = useState(true);

    const [usuario, setUsuario] = useState([]);


    // Función asincrona Firma verificado
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_verif" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVer(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirmaVer(null);
    };

    // Función asincrona Firma visto bueno
    const saveSignature2 = () => {
        if (!sigCanvas2.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas2.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_vo" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVo(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature2 = () => {
        sigCanvas2.current.clear();
        setFirmaVo(null);
    };

    useEffect(() => {
        console.log(id);

        fetch(Api + "reportes.php?repconsest=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);

        fetch(Api + "usuarios.php/?listaid=" + userID)
            .then(response3 => response3.json())
            .then((responseData3) => {
                //console.log(responseData3);
                setUsuario(responseData3.data[0]);
            })
            .catch(console.log)
    }, []);

    // Componente Datos Generales y vehiculares
    const DatosGV = ({ tg, tv, ag, av, vg, vv }) => (
        <Row>
            <Col sm={2}><label htmlFor="">{tg}</label></Col>
            <Col sm={4}>
                <p className="card-text d-inline">
                    {vg ? vg[ag] : ""}</p>
            </Col>
            <Col sm={2}><label htmlFor="">{tv}</label></Col>
            <Col>
                <p className="card-text d-inline">
                    {vv ? vv[av] : ""}</p>
            </Col>
        </Row>
    );

    // Componente Checkbox
    const InputCheck = ({ name }) => (
        <div className="form-check">
            <label className="card-text" htmlFor="">
                &nbsp;{name}
            </label>
            <input
                className="form-check-input"
                type="checkbox"
                checked={terceraConsulta.some(item => item.ret_nombre_documento === name)}
            />
        </div>
    );

    // Componente Checkbox
    const InputCheck2 = ({ name }) => (
        <Row className='container'>
            <div className="form-check">
                <label className="card-text" htmlFor="">
                    &nbsp;{name}
                </label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={segundaConsulta.some(item => item.ret_nombre_accesorio === name)}
                />
            </div>
        </Row>
    );

    const sendData = (e) => {

        e.preventDefault();

        /*
        console.log(userID);                                // id del usuario
        console.log(primeraConsulta.id_reporte_tecnico);    // id_reporte_tecnico
        console.log(primeraConsulta.id);                    // id_vehiculo
        console.log(firma_ver);                             // id_usuario_verificar
        console.log(firma_vo);                              // id_usuario_visto bueno
        console.log(estatusReporte);                        // id_estatus_reporte
        */
        //
        if (
            userID &&
            primeraConsulta && primeraConsulta.folio &&
            primeraConsulta && primeraConsulta.id
        ) {
            // verificar parametros necesarios
            if (btnCheck) {
                // Firma Verificado
                if (firma_vo == null && firma_ver == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {
                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("id", primeraConsulta.id);
                    formData.append("folio", primeraConsulta.id_reporte_tecnico);
                    formData.append("firma_ver", firma_ver);
                    formData.append("firma_vo", firma_vo);

                    fetch(Api + "/reportes.php?firmareporact2=1", {
                        method: "POST",
                        body: formData,
                    }).then(response => response.json())
                        .then((responseDataArray) => {

                            console.log(responseDataArray);

                            // Filtrar respuestas exitosas y extraer sus mensajes
                            let successMessages = responseDataArray
                                .filter(response => response.success === 1)
                                .map(response => response.message);

                            // Verificar si hay mensajes de éxito para mostrar
                            if (successMessages.length > 0) {
                                // Unir los mensajes con saltos de línea y mostrar en un alert
                                window.alert(successMessages.join('\n'));
                            } else {
                                window.alert('Lo lamentamos, su firma no ha podido ser actualizada.\nInténtelo de nuevo');
                            }
                        }).catch(console.log);
                }
            } else if (
                (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null && primeraConsulta.estatus_reporte != 'Concluido'))
                ||
                (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null && primeraConsulta.estatus_reporte != 'Concluido'))
            ) {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
            // Estado del reporte
            if (primeraConsulta.id_vo === userID) {
                if (estatusReporte != null) {
                    if (estatusReporte != '') {
                        var formData2 = new FormData();
                        formData2.append("userID", userID);
                        formData2.append("id", primeraConsulta.id);
                        formData2.append("folio", primeraConsulta.id_reporte_tecnico);
                        formData2.append("estatus", estatusReporte);
                        /*
                            *   Termina por acá y despues sigue con PDF y EXCEL
                        */
                        fetch(Api + "/reportes.php?repostatusea=1", {
                            method: "POST",
                            body: formData2,
                        }).then(response2 => response2.json())
                            .then((responseDataArray) => {
                                console.log(responseDataArray);

                                // Filtrar respuestas exitosas y extraer sus mensajes
                                let successMessages = responseDataArray
                                    .filter(response => response.success === 1)
                                    .map(response => response.message);

                                // Verificar si hay mensajes de éxito para mostrar
                                if (successMessages.length > 0) {
                                    // Unir los mensajes con saltos de línea y mostrar en un alert
                                    window.alert(successMessages.join('\n'));
                                } else {
                                    window.alert('Lo lamentamos, el estado del reporte no ha podido ser actualizado.\nInténtelo de nuevo');
                                }
                            }).catch(console.log);
                    }
                }
            }
            window.location.reload();
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
        //
    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Reportes")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (<div className="card">
                            <div className="card-header">
                                <h4>{primeraConsulta.vehiculo} | FOLIO: {primeraConsulta && primeraConsulta.folio ? primeraConsulta.folio : "Cargando..."}</h4>
                            </div>
                            <div className="card-body">
                                <Row>
                                    <Col sm>
                                        {primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido" &&
                                            (<div className="form-group">
                                                <label htmlFor="">Estatus del reporte</label>
                                                <select className={`custom-select form-control ${primeraConsulta.estatus_reporte == '' ? "is-invalid" : ""}`}
                                                    required onChange={e => setEstatusReporte(e.target.value)}
                                                >
                                                    <option value={null} >Seleccione el estado del reporte</option>
                                                    <optgroup label="Estado del reporte">
                                                        <option value="Concluido">Concluido</option>
                                                        <option value="Pendiente">Pendiente</option>
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    * Seleccione el estado del reporte
                                                </small>
                                            </div>)}
                                    </Col>
                                    <Col sm={4}>
                                        <div className="form-group">
                                            <label htmlFor="" >Folio:</label>
                                            <input type="text"
                                                className={`form-control ${primeraConsulta.folio == '' ? "is-invalid" : ""}`}
                                                value={primeraConsulta.folio}
                                                placeholder="Escriba el folio del documento"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="">Fecha de modificación</label>
                                            <p className="card-text">{primeraConsulta.Fecha_generada}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <h3>DATOS GENERALES</h3>
                                    </Col>
                                    <Col>
                                        <h3>DATOS DEL VEHÍCULO</h3>
                                    </Col>
                                </Row>
                                <DatosGV tg="APELLIDO PATERNO:" tv="MARCA:" ag="ap_paterno" av="marca" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="APELLIDO MATERNO:" tv="MODELO:" ag="ap_materno" av="modelo" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="NOMBRE:" tv="PLACAS:" ag="nombre" av="placas" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="UNIDAD:" tv="NIV:" ag="unidad" av="niv" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="OBRA:" tv="NÚM.ECONÓMICO:" ag="obra" av="num_economico" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="DIRECCIÓN:" tv="ODÓMETRO, KM:" ag="direccion" av="odometro_actual" vg={primeraConsulta} vv={primeraConsulta} />
                                <DatosGV tg="DEPARTAMENTO:" tv="DESCRIPCIÓN:" ag="departamento" av="descripcion" vg={usuario} vv={primeraConsulta} />
                                <DatosGV tg="TELEFONO:" ag="telefono" vg={usuario} />
                                <DatosGV tg="CORREO ELECTRÓNICO:" ag="email" vg={usuario} />
                                <Row>
                                    <div className="table-responsive">
                                        <br />
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>DOCUMENTACIÓN DEL VEHÍCULO</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr>
                                                    <td>
                                                        <InputCheck name="Tarjeta de Circulación" />
                                                    </td>
                                                    <td>
                                                        <InputCheck name="Comprobante de Verificación" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <InputCheck name="Póliza de seguro" />
                                                    </td>
                                                    <td>
                                                        <InputCheck name="IAVE" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="table-responsive">
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>VERIFICACIÓN DE ACCESORIOS Y HERRAMIENTAS</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr className='table-light text-center'>
                                                    <td>
                                                        EXTERIORES
                                                    </td>
                                                    <td>
                                                        INTERIORES
                                                    </td>
                                                    <td>
                                                        OTROS
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <InputCheck2 name="Luces" />
                                                        <InputCheck2 name="Cuarto" />
                                                        <InputCheck2 name="Antena" />
                                                        <InputCheck2 name="Espejos Laterales" />
                                                        <InputCheck2 name="Cristales" />
                                                        <InputCheck2 name="Emblemas" />
                                                        <InputCheck2 name="Llantas" />
                                                        <InputCheck2 name="Tapones de Rueda" />
                                                        <InputCheck2 name="Molduras" />
                                                        <InputCheck2 name="Tapón de Gasolina" />
                                                        <InputCheck2 name="Limpiadores" />
                                                    </td>
                                                    <td>
                                                        <InputCheck2 name="Instrumentos de Tablero" />
                                                        <InputCheck2 name="Calefacción" />
                                                        <InputCheck2 name="Radio/Estereo" />
                                                        <InputCheck2 name="Bocinas" />
                                                        <InputCheck2 name="Encendedor" />
                                                        <InputCheck2 name="Espejo Retrovisor" />
                                                        <InputCheck2 name="Ceniceros" />
                                                        <InputCheck2 name="Cinturones de Seguridad" />
                                                        <InputCheck2 name="Manijas de Puerta" />
                                                        <InputCheck2 name="Tapetes" />
                                                        <InputCheck2 name="Vestiduras" />
                                                    </td>
                                                    <td>
                                                        <InputCheck2 name="Gato" />
                                                        <InputCheck2 name="Maneral de Gato" />
                                                        <InputCheck2 name="Llave de Rueda" />
                                                        <InputCheck2 name="Birlo de Seguridad" />
                                                        <InputCheck2 name="Bastón de Seguridad" />
                                                        <InputCheck2 name="Llanta de Refacción" />
                                                        <InputCheck2 name="Estuche de Herramienta" />
                                                        <InputCheck2 name="Triángulo de seguridad" />
                                                        <InputCheck2 name="Extinguidor" />
                                                        <InputCheck2 name="Llave del Vehículo" />
                                                        <InputCheck2 name="Duplicado de Llave" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">INFORME DEL ESTADO DEL VEHÍCULO</h5>
                                            <div className="form-group">
                                                <textarea
                                                    name="info_estado"
                                                    id="info_estado"
                                                    rows="3"
                                                    className={`form-control`}
                                                    value={primeraConsulta.info_estado}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <h5 className="card-title">EVIDENCIA DEL ESTADO DEL VEHÍCULO</h5>
                                    <img
                                        src={primeraConsulta.evidencia ? Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                            + id + "/evi/" + primeraConsulta.evidencia : ""}
                                        class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                                        alt=""
                                    />
                                </Row>
                                <Row>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Ejecutado:</label>
                                            <img
                                                src={primeraConsulta.firma_soli ? Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                                    + id + "/fir/" + primeraConsulta.firma_soli : ""}
                                                class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} w-100"
                                                style={{ height: "12vw" }}
                                                alt=""
                                            />
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.usuario}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Verificado:</label>
                                            {
                                                (primeraConsulta.id_verificado === userID && ((primeraConsulta.firma_verificada == null || primeraConsulta.firma_verificada == "") && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature}>Guardar Firma</button>
                                                                <button onClick={clearSignature}>Borrar Firma</button>
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={primeraConsulta.firma_verificada ? Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                                                    + id + "/fir/" + primeraConsulta.firma_verificada : ""}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_verificado}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div
                                            className="mb-3 vo"
                                        >
                                            <label for="formFile" className="form-label">Visto bueno</label>
                                            {
                                                (primeraConsulta.id_vo === userID && ((primeraConsulta.firma_vo == null || primeraConsulta.firma_vo == "") && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas2}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature2}>Guardar Firma</button>
                                                                <button onClick={clearSignature2}>Borrar Firma</button>
                                                            </div>
                                                        </>

                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={primeraConsulta.firma_vo ? Api + process.env.REACT_APP_LINK_IMG_REP_EST_ACT + primeraConsulta.folio + "/"
                                                                    + id + "/fir/" + primeraConsulta.firma_vo : ""}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_vo}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {verPDF ? (
                                        <PDFViewer style={{ width: "100%", height: "90vh" }}>
                                            <PDF id={id} user={"" + userID + ""} />
                                        </PDFViewer>
                                    ) : null}
                                </Row>
                            </div>
                            <div className="card-footer text-muted">
                                <Row>
                                    <Col>
                                        <h5>Acciones</h5>
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {
                                                (primeraConsulta.id_verificado === userID || primeraConsulta.id_vo === userID) &&
                                                    (primeraConsulta.estatus_reporte != 'Concluido') &&
                                                    (
                                                        (
                                                            (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null || primeraConsulta.firma_verificada == ""))
                                                            ||
                                                            (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null || primeraConsulta.firma_vo == ""))
                                                        ) ||
                                                        (primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido")
                                                    )
                                                    ? (
                                                        <button type="button"
                                                            className="btn btn-warning btn-block"
                                                            onClick={(e) => {
                                                                sendData(e); // Pasa el evento como argumento
                                                            }}
                                                        >Confirmar</button>
                                                    )
                                                    :
                                                    null // Cuando el lienzo aparece, no mostramos nada en lugar del botón
                                            }
                                            <Link
                                                className="btn btn-danger btn-block text-white"
                                                to={"/flotilla/reporte/tecnico"}
                                            >Regresar</Link>
                                        </div>

                                    </Col>
                                    <Col>
                                        {isVisible && (<h5>Exportación</h5>)}
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {isVisible && (<button type="button"
                                                className="btn btn-primary btn-block"
                                                onClick={() => {
                                                    setVerPDF(!verPDF);
                                                }}
                                            >PDF</button>)}

                                            {isVisible && (
                                                verExcel ? (
                                                    <Excel id={id} />
                                                ) : null
                                            )}

                                            {isVisible && (
                                                !verExcel && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-block"
                                                        onClick={() => {
                                                            setVerExcel(!verExcel);
                                                        }}
                                                    >EXCEL</button>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>)}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Technician_view_flotilla;