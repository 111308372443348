// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from "@/components/general/Loading_papu";
import PDF from '@/components/Flotilla/components/Bitacora/PDF_bitacora';
import Excel from '@/components/Flotilla/components/Bitacora/Excel_bitacora';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

// Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';
import { Link, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';


// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"
function Maintenance_view_flotilla() {

    const [verPDF, setVerPDF] = React.useState(false);
    const [verExcel, setVerExcel] = React.useState(false);
    const [loadedData, setLoadedData] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState(null);
    const [segundaConsulta, setSegundaConsulta] = useState([]);
    const [terceraConsulta, setTerceraConsulta] = useState([]);
    const [estatusReporte, setEstatusReporte] = useState(null);
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    const [firma_ver, setFirmaVer] = useState(null);
    const [firma_vo, setFirmaVo] = useState(null);
    const sigCanvas = useRef({});
    const sigCanvas2 = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [isVisible, setVisible] = useState(true);

    const serviciosEspeciales = [
        "Cambio de Aceite al Motor",
        "Cambio de Filtro de Aceite al Motor",
        "Cambio de Filtro de Aire",
        "Cambio de Filtro de Combustible",
        "Cambio de Aceite de Transmisión",
        "Cambio de Filtro de Transmisión",
        "Cambio de Anticongelante",
        "Cambio de Plumillas de Limpia-Parabrisas",
        "Cambio de Amortiguadores",
        "Cambio de Neumáticos",
        "Cambio de Batería",
        "Cambio de Balatas",
        "Alineación y Balanceo",
        "Cambio de Bujías",
        "Limpieza de Inyectores",
        "Cambio de luces",
        "Cambio de Líquido de Frenos",
        "Alineación de Ruedas",
        "null"
    ];

    // Función asincrona Firma verificado
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_verif" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVer(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirmaVer(null);
    };

    // Función asincrona Firma visto bueno
    const saveSignature2 = () => {
        if (!sigCanvas2.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas2.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_vo" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirmaVo(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature2 = () => {
        sigCanvas2.current.clear();
        setFirmaVo(null);
    };

    useEffect(() => {
        //console.log(id);

        fetch(Api + "reportes.php?repvehvis=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setPrimeraConsulta(responseData.primera_consulta[0]);
                setSegundaConsulta(responseData.segunda_consulta);
                setTerceraConsulta(responseData.tercera_consulta);
            })
            .catch(console.log);

    }, []);

    const registroEspecial = terceraConsulta.find(item => !serviciosEspeciales.includes(item.rem_nombre_servicio));

    const sendData = (e) => {

        e.preventDefault();
        /*
        console.log(userID);
        console.log(primeraConsulta.folio);
        console.log(primeraConsulta.id);
        console.log(firma_ver);
        console.log(firma_vo);
        console.log(estatusReporte);
        */

        if (
            userID &&
            primeraConsulta && primeraConsulta.folio &&
            primeraConsulta && primeraConsulta.id
        ) {
            // verificar parametros necesarios
            if (btnCheck) {
                // Firma Verificado
                if (firma_vo == null && firma_ver == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {
                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("id", primeraConsulta.id);
                    formData.append("folio", primeraConsulta.folio);
                    formData.append("firma_ver", firma_ver);
                    formData.append("firma_vo", firma_vo);

                    fetch(Api + "/reportes.php?firmavover=1", {
                        method: "POST",
                        body: formData,
                    }).then(response => response.json())

                        .then((responseData) => {
                            console.log(responseData);
                            if (responseData[0].success === 1) {
                                window.alert('Firma actualizada.');
                            } else {
                                window.alert('Su firma no ha podido ser actualizada');
                            }
                        }).catch(console.log);
                }
            } else if (
                (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null && primeraConsulta.estatus_reporte != 'Concluido'))
                ||
                (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null && primeraConsulta.estatus_reporte != 'Concluido'))
            ) {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
            // Estado del reporte
            if (primeraConsulta.id_vo === userID) {
                if (estatusReporte != null) {
                    if (estatusReporte != 'Pendiente') {
                        var formData2 = new FormData();
                        formData2.append("userID", userID);
                        formData2.append("id", primeraConsulta.id);
                        formData2.append("folio", primeraConsulta.folio);
                        formData2.append("estatus", estatusReporte);
                        /*
                            Termina por acá y despues sigue con PDF y EXCEL
                        */
                        fetch(Api + "/reportes.php?repostatus=1", {
                            method: "POST",
                            body: formData2,
                        }).then(response2 => response2.json())
                            .then((responseData2) => {
                                console.log(responseData2);
                                if (responseData2[0].success === 1) {
                                    window.alert('Estatus actualizado del reporte.');
                                } else {
                                    window.alert('El estatus del reporte o ha podido ser actualizado.');
                                }
                            }).catch(console.log);
                    }
                }
            }
            window.location.reload();
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
    };

    const { pagFlotilla, userID } = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];


    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }
    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        {CRUDUser[2] === 3 && (<div className="card">
                            <div className="card-header">
                                <h4>{primeraConsulta.vehiculo} | FOLIO: {primeraConsulta && primeraConsulta.folio ? primeraConsulta.folio : "Cargando..."}</h4>
                            </div>
                            <div className="card-body">

                                <Row>
                                    <Col sm>
                                        {primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido" &&
                                            (<div className="form-group">
                                                <label htmlFor="">Estatus del reporte</label>
                                                <select className={`custom-select form-control ${primeraConsulta.estatus_reporte == '' ? "is-invalid" : ""}`}
                                                    required onChange={e => setEstatusReporte(e.target.value)}
                                                >
                                                    <option value={null} >Seleccione el estado del reporte</option>
                                                    <optgroup label="Estado del reporte">
                                                        <option value="Concluido">Concluido</option>
                                                        <option value="Pendiente">Pendiente</option>
                                                    </optgroup>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    * Seleccione el estado del reporte
                                                </small>
                                            </div>)}
                                    </Col>
                                    <Col sm={4}>
                                        <div className="form-group">
                                            <label htmlFor="" >Folio:</label>
                                            <input type="text"
                                                className={`form-control ${primeraConsulta.folio == '' ? "is-invalid" : ""}`}
                                                value={primeraConsulta.folio}
                                                placeholder="Escriba el folio del documento"
                                            />
                                            <small id="helpId" className="form-text text-muted">*Crea el folio</small>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="">Fecha de modificación</label>
                                            <p className="card-text">{primeraConsulta.Fecha_generada}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <h3>DATOS GENERALES</h3>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Transporte:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Marca:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Modelo:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Placa: </label>
                                        </div>
                                        <div className="form-group d-inline">
                                            <label htmlFor="">
                                                NIV:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Odómetro: </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Número económico: </label>
                                        </div>
                                    </Col>
                                    <Col sm={8}>
                                        <h3 className='text-white'>DATOS GENERALES</h3>

                                        <div className="form-group d-flex align-items-center">
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Vehículo</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta &&
                                                        (primeraConsulta.transporte === "Compacto" || primeraConsulta.transporte === "Motocicleta"
                                                            || primeraConsulta.transporte === "Pick-Up" || primeraConsulta.transporte === "SUV"
                                                            || primeraConsulta.transporte === "Sedan" || primeraConsulta.transporte === "Vehículo ligero"
                                                            || primeraConsulta.transporte === "Pick-up")}
                                                />
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Maquinaria o vehículo pesado</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta &&
                                                        (primeraConsulta.transporte === "Vehículo pesado" || primeraConsulta.transporte === "Camión"
                                                            || primeraConsulta.transporte === "Furgoneta" || primeraConsulta.transporte === "Plataforma"
                                                            || primeraConsulta.transporte === "Remolque")}
                                                />
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">&nbsp;Otros</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={primeraConsulta.transporte}
                                                    checked={primeraConsulta && (primeraConsulta.transporte === "Otros" || primeraConsulta.transporte === "otros")}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.marca : ""}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.modelo + " " + primeraConsulta.modelo : ""}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.placas : ""}</p>
                                        </div>
                                        <div className="form-group d-inline">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.niv : ""}</p>
                                        </div>

                                        <div className="form-group d-inline">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.odometro_actual : ""}</p>
                                        </div>

                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {primeraConsulta ? primeraConsulta.num_economico : ""}</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <div className="table-responsive">
                                        <br />
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>MANTENIMIENTO</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr className='table-light'>
                                                    <td colSpan={1}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                checked={primeraConsulta.tipo_mantenimiento === "Preventivo"}
                                                            />

                                                            <label className="form-check-label" htmlFor="">
                                                                <b>
                                                                    &nbsp;Preventivo
                                                                </b>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                checked={primeraConsulta.tipo_mantenimiento === "Correctivo"}
                                                            />
                                                            <label className="form-check-label" htmlFor="">
                                                                <b>
                                                                    &nbsp;Correctivo
                                                                </b>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Aceite al Motor
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Aceite al Motor")}
                                                            />
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Aceite al Motor
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Filtro de Aceite al Motor")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Aire
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Filtro de Aire")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Combustible
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Filtro de Combustible")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Aceite de Transmisión
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Aceite de Transmisión")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Transmisión
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Filtro de Transmisión")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Anticongelante
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Anticongelante")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Plumillas de Limpia-Parabrisas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Plumillas de Limpia-Parabrisas")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Amortiguadores
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Amortiguadores")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Neumáticos
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Neumáticos")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Batería
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Batería")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Balatas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Balatas")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Alineación y Balanceo
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Alineación y Balanceo")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Bujías
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Bujías")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Limpieza de Inyectores
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Limpieza de Inyectores")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de luces
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de luces")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Líquido de Frenos
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Cambio de Líquido de Frenos")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Alineación de Ruedas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={terceraConsulta.some(item => item.rem_nombre_servicio === "Alineación de Ruedas")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="input-group mb-3">
                                                            <span class="input-group-text">Otros</span>
                                                            <div className="input-group-text">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={terceraConsulta.some(item => !serviciosEspeciales.includes(item.rem_nombre_servicio))}
                                                                />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control inline w-50"
                                                                placeholder='Descripción'
                                                                value={registroEspecial ? registroEspecial.rem_nombre_servicio : ''}
                                                                disabled={!registroEspecial}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="table-responsive">
                                        <br />
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary text-center'>
                                                <tr>
                                                    <th className='text-center'>
                                                        N
                                                    </th>
                                                    <th className='text-center'>
                                                        PIEZA, REFACCIÓN, COMBUSTIBLE, ETC.
                                                    </th>
                                                    <th className='text-center'>
                                                        SISTEMA
                                                    </th>
                                                    <th className='text-center'>
                                                        DESCRIPCIÓN
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                {segundaConsulta.map((row, index) => (
                                                    <tr key={row.id_repmanrefacciones}>
                                                        <td>
                                                            <p className="card-text text-center align-middle">{index + 1}</p>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <input
                                                                    className={`form-control`}
                                                                    value={row.rem_nombre_pieza}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <input
                                                                    className={`form-control`}
                                                                    value={row.rem_sistema_pieza}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <textarea
                                                                    className={`form-control`}
                                                                    value={row.Descripcion}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <br />
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>PRÓXIMO CONTROL</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="fechaMantenimiento">Fecha</label>
                                                            <br></br>
                                                            <input
                                                                type="text"
                                                                value={primeraConsulta.fecha_mant}
                                                                className="form-control"
                                                                placeholder="Indique las horas de uso (Opcional)"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="">Horas de uso</label>
                                                            <input
                                                                type="number"
                                                                value={primeraConsulta.horas_mant}
                                                                className="form-control"
                                                                placeholder="Indique las horas de uso (Opcional)"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="">Odómetro</label>
                                                            <input
                                                                type="number"

                                                                value={primeraConsulta.odo_mant}
                                                                className="form-control"
                                                                placeholder="Indique la próxima revisión en Km (Opcional)"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Ejecutado:</label>
                                            <img
                                                src={Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_soli}
                                                class="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|} w-100"
                                                style={{ height: "12vw" }}
                                                alt=""
                                            />
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.usuario}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="mb-3">
                                            <label for="formFile" className="form-label">Verificado:</label>
                                            {
                                                (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature}>Guardar Firma</button>
                                                                <button onClick={clearSignature}>Borrar Firma</button>
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_verificada}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_verificado}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div
                                            className="mb-3 vo"
                                        >
                                            <label for="formFile" className="form-label">Visto bueno</label>
                                            {
                                                (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null && primeraConsulta.estatus_reporte != 'Concluido'))
                                                    ? (
                                                        // Código cuando las condiciones anteriores no se cumplen
                                                        <>
                                                            <div className="text-center">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas2}
                                                                    canvasProps={{
                                                                        className: 'signature-canvas',
                                                                    }}
                                                                />
                                                                <br />
                                                                <button onClick={saveSignature2}>Guardar Firma</button>
                                                                <button onClick={clearSignature2}>Borrar Firma</button>
                                                            </div>
                                                        </>

                                                    )
                                                    : (
                                                        // Código cuando la imagen se encuentra y el estatus no es 'Concluido' y id_verificado coincide con userID
                                                        <>
                                                            <img
                                                                src={Api + process.env.REACT_APP_LINK_IMG_REP_MANT_FIR + primeraConsulta.folio + "/fir/" + primeraConsulta.firma_vo}
                                                                className="img-fluid w-100"
                                                                style={{ height: "12vw" }}
                                                                alt=""
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="w-100 border-top text-center border-dark border-2">
                                                <p className="form-text text-muted">
                                                    Firma <br></br> {primeraConsulta.nom_vo}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {verPDF ? (
                                        <PDFViewer style={{ width: "100%", height: "90vh" }}>
                                            <PDF id={id} />
                                        </PDFViewer>
                                    ) : null}
                                </Row>
                            </div>
                            <div className="card-footer text-muted">
                                <Row>
                                    <Col>
                                        <h5>Acciones</h5>
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {
                                                (primeraConsulta.id_verificado === userID || primeraConsulta.id_vo === userID) &&
                                                    (primeraConsulta.estatus_reporte != 'Concluido') &&
                                                    (
                                                        (
                                                            (primeraConsulta.id_verificado === userID && (primeraConsulta.firma_verificada == null && primeraConsulta.estatus_reporte != 'Concluido'))
                                                            ||
                                                            (primeraConsulta.id_vo === userID && (primeraConsulta.firma_vo == null && primeraConsulta.estatus_reporte != 'Concluido'))
                                                        ) ||
                                                        (primeraConsulta.id_vo === userID && primeraConsulta.estatus_reporte != "Concluido")
                                                    )
                                                    ? (
                                                        <button type="button"
                                                            className="btn btn-warning btn-block"
                                                            onClick={(e) => {
                                                                sendData(e); // Pasa el evento como argumento
                                                            }}
                                                        >Confirmar</button>
                                                    )
                                                    :
                                                    null // Cuando el lienzo aparece, no mostramos nada en lugar del botón
                                            }
                                            <Link
                                                className="btn btn-danger btn-block text-white"
                                                to={"/flotilla/bitacora-de-mantenimiento"}
                                            >Regresar</Link>
                                        </div>

                                    </Col>
                                    <Col>
                                        {isVisible && (<h5>Exportación</h5>)}
                                        <div className="btn-group w-100" role="group" aria-label="">
                                            {isVisible && (<button type="button"
                                                className="btn btn-primary btn-block"
                                                onClick={() => {
                                                    setVerPDF(!verPDF);
                                                }}
                                            >PDF</button>)}

                                            {isVisible && (
                                                verExcel ? (
                                                    <Excel id={id} />
                                                ) : null
                                            )}

                                            {isVisible && (
                                                !verExcel && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-block"
                                                        onClick={() => {
                                                            setVerExcel(!verExcel);
                                                        }}
                                                    >EXCEL</button>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>)}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Maintenance_view_flotilla;