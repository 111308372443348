//Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css"; // Estilos de react-datepicker
import SignatureCanvas from 'react-signature-canvas';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '../../../general/Loading_papu';
import Error404 from '@Components/general/Error404';
import DatePicker from "react-datepicker";

//Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";

function getDateTime() {
    const now = new Date();

    // Obtenemos las partes de la fecha y hora
    const año = now.getFullYear();
    const mes = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque los meses en JavaScript comienzan desde 0
    const dia = String(now.getDate()).padStart(2, '0');
    const hora = String(now.getHours()).padStart(2, '0');
    const minutos = String(now.getMinutes()).padStart(2, '0');
    const segundos = String(now.getSeconds()).padStart(2, '0');

    // Formateamos la fecha y hora
    const fechaYHora = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

    return fechaYHora;
}




function Report_maintenance_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [Fecha, setFecha] = useState(getDateTime());
    const [mantenimiento, setMantenimiento] = useState(null);
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [problema, setProblema] = useState(null);
    const [fechaMantenimiento, setFechaMantenimiento] = useState(null);
    const [hrUso, setHrUso] = useState(null);
    const [odoActual, setOdoActual] = useState(null);
    const [km, setKM] = useState(null);
    const [firma, setFirma] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const sigCanvas = useRef({});
    const [btnCheck, setBtnCheck] = useState(false);
    const [listaFirma, setListaFirma] = useState([]);
    const [verificado, setVerificado] = useState(null);
    const [vistoBueno, setVistoBueno] = useState(null);

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;


    // Función asincrona
    const saveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Obtener el lienzo como Blob
            sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                // Convertir el Blob en un objeto File
                const signatureFile = new File([blob], "firma_" + userID + ".png", {
                    type: "image/png",
                    lastModified: new Date()
                });

                // Guardar el objeto File en el estado
                setFirma(signatureFile);
            });
            setBtnCheck(true);
        } else {
            alert("Por favor, firma antes de guardar.");
            setBtnCheck(false);
        }
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFirma(null);
    };

    useEffect(() => {
        fetch(Api + "automovil-flotilla.php/?todos")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setLoadedData(true);
                setVehiculo(responseData);
            })
            .catch(console.log)

        fetch(Api + "usuarios.php/?listaus")
            .then(response2 => response2.json())
            .then((responseData2) => {
                //console.log(responseData2);
                setListaFirma(responseData2.data);
            })
            .catch(console.log)


    }, []);


    const [rows, setRows] = useState([
        {
            id: 1,
            pieza: '',
            sistema: '',
            descripcion: '',
        },
    ]);

    const addRow = () => {
        const newRow = {
            id: rows.length + 1,
            pieza: '',
            sistema: '',
            descripcion: '',
        };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        if (rows.length > 1) {
            const updatedRows = rows.filter((row) => row.id !== id);
            setRows(updatedRows);
        }
    };

    const handleInputChange = (id, name, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            // Si el checkbox está marcado, agrega el valor al array
            setCheckboxValues([...checkboxValues, value]);
        } else {
            // Si el checkbox se desmarca, elimina el valor del array
            setCheckboxValues(checkboxValues.filter((item) => item !== value));
        }
    };

    const handleCheckboxChanges = (e) => {
        if (!e.target.checked) {
            setProblema('');  // Si el checkbox se deselecciona, borra el contenido del input
        }
        setIsChecked(e.target.checked);
    }

    const handleFileChange = (event) => {
        const imagenFile = event.target.files[0];
        setFirma(imagenFile);
    };

    const valueChange = (e) => {
        const newState = { ...selectedVehicle }; // Crear una copia del estado vehículo
        newState[e.target.name] = e.target.value;
        setSelectedVehicle(newState); // Actualizar el estado con la nueva información
        setOdoActual(newState.odometro); // Actualizar la variable odoActual con el nuevo valor
    }

    const sendData = (e) => {

        e.preventDefault();

        /*
        console.log(selectedVehicle.id);
        console.log(selectedVehicle); // it's ok
        console.log(folio); // it's ok
        console.log(Fecha); // it's ok
        console.log(mantenimiento); // it's ok
        console.log(checkboxValues); // Array it's ok
        console.log(problema); // it's ok
        console.log(fechaMantenimiento); // it's ok
        console.log(hrUso); // it's ok
        console.log(km); // it's ok
        console.log(firma); // it's ok
        console.log(rows); // it's ok
        console.log(userID);
        console.log(selectedVehicle.odometro); //OdoActual
        console.log(verificado);
        console.log(vistoBueno);
        */

        // Validación de las entradas en 'rows'
        const areRowsValid = rows.every(row => row.pieza && row.sistema && row.descripcion);

        if (!areRowsValid) {
            window.alert("Por favor, asegúrese de que todas las entradas de la tabla no estén vacías.\nEn especial la tabla de Piezas y refacciones.");
            return; // Salimos del método aquí si hay algún campo de 'rows' que está vacío
        }

        if (
            userID &&
            selectedVehicle && selectedVehicle.id &&
            fechaMantenimiento &&
            mantenimiento &&
            checkboxValues.length > 0 && // Verificamos que el array no esté vacío
            hrUso &&
            km &&
            selectedVehicle && selectedVehicle.odometro &&
            verificado &&
            vistoBueno
        ) {
            // verificar parametros necesarios

            if (btnCheck) {
                if (firma == null) {
                    window.alert('Vuelva a presionar el botón "Guardar firma" de nuevo');
                    return;
                } else {


                    var formData = new FormData();
                    formData.append("userID", userID);
                    formData.append("id", selectedVehicle.id);
                    formData.append("Fecha", fechaMantenimiento);
                    formData.append("mantenimiento", mantenimiento);
                    formData.append("checkboxValues", JSON.stringify(checkboxValues));
                    formData.append("problema", problema);
                    formData.append("fechaMantenimiento", fechaMantenimiento);
                    formData.append("hrUso", hrUso);
                    formData.append("km", km);
                    formData.append("rows", JSON.stringify(rows));
                    formData.append("firma", firma);
                    formData.append("odoActual", selectedVehicle.odometro);
                    formData.append("id_verificado", verificado);
                    formData.append("id_vistobueno", vistoBueno);

                    // No convertir FormData a objeto JSON
                    // Dejar que FormData maneje el archivo de firma-

                    fetch(Api + "/reportes.php?insrepormanten=1", {
                        method: "POST",
                        body: formData,
                    }).then(response => response.json())
                        .then((responseDataArray) => {
                            console.log(responseDataArray);

                            let hasSuccess3 = responseDataArray.some(response => response.success === 1);

                            if (hasSuccess3) {
                                window.alert('Su reporte ha sido cargado');
                                window.location.href = "/flotilla/bitacora-de-mantenimiento";
                            } else {
                                window.alert('Lo lamentamos, no se ha podido subir su reporte. Inténtelo de nuevo');
                            }
                        }).catch(console.log);

                }
            } else {
                window.alert('Presione primero el botón "Guardar firma"');
                return;
            }
        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
    };

    const { pagFlotilla, userID } = useUser();
    // Page we are looking for
    let paginaBuscada = 'Reportes';
    // Page status (if we found it)
    let paginaEncontrada = false;
    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    let CRUDUser = [0, 0, 0, 0];


    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            if (pagFlotilla[i][0] === 1 || pagFlotilla[i][0] === '1') {
                CRUDUser[0] = 1;
            } else if (pagFlotilla[i][0] === 2 || pagFlotilla[i][0] === '2') {
                CRUDUser[1] = 2;
            } else if (pagFlotilla[i][0] === 3 || pagFlotilla[i][0] === '3') {
                CRUDUser[2] = 3;
            } else if (pagFlotilla[i][0] === 4 || pagFlotilla[i][0] === '4') {
                CRUDUser[3] = 4;
            } else { }
        }
    }
    if (paginaEncontrada) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );

        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className='container'>
                        <h2>Agrega un nuevo reporte de mantenimiento</h2>
                        {CRUDUser[3] === 4 && (<div className="card">
                            <div className="card-header">
                                <h4>Reporte de mantenimiento</h4>
                            </div>
                            <div className="card-body">
                                <Row>
                                    <Col sm>
                                        <div className="form-group">
                                            <label htmlFor="">Vehículo o plataforma</label>
                                            <select
                                                className="custom-select form-control"
                                                value={selectedVehicle ? selectedVehicle.id : ""}
                                                onChange={(e) => {
                                                    const selectedId = e.target.value;
                                                    const selected = vehiculo.find((v) => v.id === selectedId);
                                                    setSelectedVehicle(selected);
                                                }}
                                                name="vehiculo"
                                                id="vehiculo"
                                                required >
                                                <option value="">Seleccione un vehículo o plataforma</option>
                                                <optgroup label="Vehículos o plataformas">
                                                    {vehiculo.map(
                                                        (vehiculo) => (
                                                            <option key={vehiculo.id} value={vehiculo.id}>
                                                                {vehiculo.vehiculo}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                                <option value="Otros">Otros</option>
                                            </select>
                                            <small id="helpId" className="form-text text-muted">
                                                *Seleccione la categoría del vehículo
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm></Col>
                                    <Col sm={4}>
                                        <div className="form-group">
                                            <label htmlFor="">Fecha de modificación</label>
                                            <p className="card-text">{Fecha}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <h3>DATOS GENERALES</h3>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Transporte:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Marca:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Modelo:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Placa: </label>
                                        </div>
                                        <div className="form-group d-inline">
                                            <label htmlFor="">
                                                NIV:</label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Odómetro: </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Número económico: </label>
                                        </div>
                                    </Col>
                                    <Col sm={8}>
                                        <h3 className='text-white'>DATOS GENERALES</h3>

                                        <div className="form-group d-flex align-items-center">
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Vehículo</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Compacto"
                                                    checked={selectedVehicle &&
                                                        (selectedVehicle.categoria === "Compacto" || selectedVehicle.categoria === "Motocicleta"
                                                            || selectedVehicle.categoria === "Pick-Up" || selectedVehicle.categoria === "SUV"
                                                            || selectedVehicle.categoria === "Sedan" || selectedVehicle.categoria === "Vehículo ligero"
                                                            || selectedVehicle.categoria === "Pick-up")}
                                                />
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label">&nbsp;Maquinaria o vehículo pesado</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Vehículo pesado inline"
                                                    checked={selectedVehicle &&
                                                        (selectedVehicle.categoria === "Vehículo pesado" || selectedVehicle.categoria === "Camión"
                                                            || selectedVehicle.categoria === "Furgoneta" || selectedVehicle.categoria === "Plataforma"
                                                            || selectedVehicle.categoria === "Remolque")}
                                                />
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">&nbsp;Otros</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="Otros"
                                                    checked={selectedVehicle && (selectedVehicle.categoria === "Otros" || selectedVehicle.categoria === "otros")}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.fabricante : ""}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.modelo + " " + selectedVehicle.ano : ""}</p>
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.placas : ""}</p>
                                        </div>
                                        <div className="form-group d-inline">
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.niv : ""}</p>
                                        </div>

                                        <div className="input-group input-group-sm mb-3">
                                            <span className="input-group-text" id="inputGroup-sizing-sm">Odómetro</span>
                                            <input
                                                type="number"
                                                name="odometro"
                                                id="odoActual"
                                                value={selectedVehicle ? selectedVehicle.odometro : ""}
                                                className="form-control w-50 h-100"
                                                placeholder="Indique la lectura de su odómetro"
                                                aria-describedby="helpId"
                                                min={0}
                                                max={9999999}
                                                onChange={valueChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <p className="card-text d-inline">
                                                {selectedVehicle ? selectedVehicle.neconomico : ""}</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <div className="table-responsive">
                                        <br />
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>MANTENIMIENTO</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr className='table-light'>
                                                    <td colSpan={1}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mantenimiento"
                                                                id="mantenimiento"
                                                                value="Preventivo"
                                                                checked={mantenimiento === "Preventivo"}
                                                                onChange={(e) => setMantenimiento(e.target.value)}
                                                            />

                                                            <label className="form-check-label" htmlFor="">
                                                                <b>
                                                                    &nbsp;Preventivo
                                                                </b>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td colSpan={2}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="mantenimiento"
                                                                id="mantenimiento"
                                                                value="Correctivo"
                                                                checked={mantenimiento === "Correctivo"}
                                                                onChange={(e) => setMantenimiento(e.target.value)}
                                                            />
                                                            <label className="form-check-label" htmlFor="">
                                                                <b>
                                                                    &nbsp;Correctivo
                                                                </b>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Aceite al Motor
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Aceite al Motor"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Aceite al Motor")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Aceite al Motor
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Filtro de Aceite al Motor"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Filtro de Aceite al Motor")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Aire
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Filtro de Aire"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Filtro de Aire")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Combustible
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Filtro de Combustible"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Filtro de Combustible")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Aceite de Transmisión
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Aceite de Transmisión"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Aceite de Transmisión")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Filtro de Transmisión
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Filtro de Transmisión"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Filtro de Transmisión")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Anticongelante
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Anticongelante"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Anticongelante")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Plumillas de Limpia-Parabrisas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Plumillas de Limpia-Parabrisas"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Plumillas de Limpia-Parabrisas")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Amortiguadores
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Amortiguadores"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Amortiguadores")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Neumáticos
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Neumáticos"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Neumáticos")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Batería
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Batería"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Batería")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Balatas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Balatas"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Balatas")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Alineación y Balanceo
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Alineación y Balanceo"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Alineación y Balanceo")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Bujías
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Bujías"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Bujías")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Limpieza de Inyectores
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Limpieza de Inyectores"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Limpieza de Inyectores")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de luces
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de luces"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de luces")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Cambio de Líquido de Frenos
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Cambio de Líquido de Frenos"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Cambio de Líquido de Frenos")}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <label className="card-text" htmlFor="">
                                                                &nbsp;Alineación de Ruedas
                                                            </label>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="Alineación de Ruedas"
                                                                onChange={handleCheckboxChange}
                                                                checked={checkboxValues.includes("Alineación de Ruedas")}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div className="input-group mb-3">
                                                            <span class="input-group-text">Otros</span>
                                                            <div className="input-group-text">
                                                                <input
                                                                    className="form-check-input mt-0"
                                                                    type="checkbox"
                                                                    value=""
                                                                    checked={isChecked}
                                                                    onChange={handleCheckboxChanges}
                                                                    maxLength={45}
                                                                    minLength={1}
                                                                />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control inline w-50"
                                                                placeholder='Descripción'
                                                                aria-label="Text input with checkbox"
                                                                name="descripcion"
                                                                id="descripcion"
                                                                value={problema}
                                                                onChange={(e) => setProblema(e.target.value)}
                                                                disabled={!isChecked} // Input habilitado si Checkbox Otros es marcado
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="table-responsive">
                                        <br />
                                        <table className="table table table-bordered table-hover">
                                            <thead className='table-secondary text-center'>
                                                <tr>
                                                    <th className='text-center'>
                                                        N
                                                    </th>
                                                    <th className='text-center'>
                                                        PIEZA, REFACCIÓN, COMBUSTIBLE, ETC.
                                                    </th>
                                                    <th className='text-center'>
                                                        SISTEMA
                                                    </th>
                                                    <th className='text-center'>
                                                        DESCRIPCIÓN
                                                    </th>
                                                    <th className='text-center'>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" className="btn btn-primary" onClick={addRow}>
                                                                Agregar
                                                            </button>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                {rows.map((row) => (
                                                    <tr key={row.id}>
                                                        <td>
                                                            <p className="card-text text-center align-middle">{row.id}</p>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <input
                                                                    className={`form-control`}
                                                                    value={row.pieza}
                                                                    name="pieza"
                                                                    id={`pieza-${row.id}`}
                                                                    required
                                                                    placeholder="*Escriba el nombre del objeto"
                                                                    onChange={(e) => handleInputChange(row.id, 'pieza', e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <input
                                                                    className={`form-control`}
                                                                    value={row.sistema}
                                                                    name="sistema"
                                                                    id={`sistema-${row.id}`}
                                                                    required
                                                                    placeholder="*Escriba el nombre del sistema"
                                                                    onChange={(e) => handleInputChange(row.id, 'sistema', e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="">
                                                                <textarea
                                                                    className={`form-control`}
                                                                    value={row.descripcion}
                                                                    name="descripcion"
                                                                    id={`descripcion-${row.id}`}
                                                                    required
                                                                    placeholder="*Escriba o indique la necesidad del objeto o características de este"
                                                                    onChange={(e) => handleInputChange(row.id, 'descripcion', e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="btn-group" role="group" aria-label="">
                                                                <button type="button" className="btn btn-secondary" onClick={() => removeRow(row.id)}>
                                                                    Quitar
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <br />
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className='table-secondary'>
                                                <tr>
                                                    <th colSpan={3} className='text-center'>PRÓXIMO CONTROL</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbodyy'>
                                                <tr>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="fechaMantenimiento">Fecha</label>
                                                            <br></br>
                                                            <input
                                                                type="date"
                                                                name="fechaMantenimiento"
                                                                id="fechaMantenimiento"
                                                                value={fechaMantenimiento}
                                                                className="form-control"
                                                                onChange={(e) => setFechaMantenimiento(e.target.value)}
                                                                min={formattedDate}
                                                            />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="">Horas de uso</label>
                                                            <input
                                                                type="number"
                                                                name="hrUso"
                                                                id="hrUso"
                                                                value={hrUso}
                                                                className="form-control"
                                                                placeholder="Indique las horas de uso (Opcional)"
                                                                aria-describedby="helpId"
                                                                min={0}
                                                                max={999999}
                                                                onChange={(e) => setHrUso(e.target.value)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <label htmlFor="">Odómetro</label>
                                                            <input
                                                                type="number"
                                                                name="km"
                                                                id="km"
                                                                value={km}
                                                                className="form-control"
                                                                placeholder="Indique la próxima revisión en Km (Opcional)"
                                                                aria-describedby="helpId"
                                                                min={0}
                                                                max={9999999}
                                                                onChange={(e) => setKM(e.target.value)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <div className="form-group">
                                            <label htmlFor="firmaVer">Firma de verificado</label>
                                            <select
                                                className="custom-select form-control"
                                                required
                                                onChange={e => setVerificado(e.target.value)}  // Agregar esta línea
                                            >
                                                <option value="">Seleccione una persona que pueda firmar</option>
                                                <optgroup label="Firmante para verificar el reporte">
                                                    {listaFirma.map(
                                                        (listaFirma) => (
                                                            <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                {listaFirma.nombre_completo}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                            </select>
                                            <small id="helpId" className="form-text text-muted">
                                                *Seleccione a la persona que firmará de verificado.
                                            </small>
                                        </div>
                                    </Col>
                                    <Col sm>
                                        <div className="form-group">
                                            <label htmlFor="firmaVO">Firma de visto bueno</label>
                                            <select
                                                className="custom-select form-control"
                                                required
                                                onChange={e => setVistoBueno(e.target.value)}  // Agregar esta línea
                                            >
                                                <option value="">Seleccione una persona que pueda firmar</option>
                                                <optgroup label="Firmante para visto bueno el reporte">
                                                    {listaFirma.map(
                                                        (listaFirma) => (
                                                            <option key={listaFirma.id_usuario} value={listaFirma.id_usuario}>
                                                                {listaFirma.nombre_completo}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                            </select>
                                            <small id="helpId" className="form-text text-muted">
                                                *Seleccione a la persona que firmará de visto bueno.
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <br />
                                    <div className="mb-3 text-center">
                                        <label className="form-label">Firma</label>
                                        <br />
                                        <SignatureCanvas
                                            ref={sigCanvas}
                                            canvasProps={{
                                                className: 'signature-canvas'
                                            }}

                                        />
                                        <br />
                                        <button onClick={saveSignature}>Guardar Firma</button>
                                        <button onClick={clearSignature}>Borrar Firma</button>
                                        <p className="form-text text-muted">
                                            *Dibuja tu firma en el espacio de arriba.
                                        </p>
                                    </div>

                                </Row>
                            </div>
                            <div className="card-footer text-muted">
                                <div className="btn-group" role="group" aria-label="">
                                    <button type="button"
                                        className="btn btn-success"
                                        onClick={(e) => {
                                            sendData(e); // Pasa el evento como argumento
                                        }}>
                                        Agregar
                                    </button>
                                    <Link className="btn btn-danger" to="/flotilla/bitacora-de-mantenimiento">Regresar</Link>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu reporte vehicular</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default Report_maintenance_flotilla;