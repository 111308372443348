//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";


const Collapse_bar_flotilla = ({ categoria }) => {

    const [vehiculos, setVehiculos] = useState([]);
    const { pagFlotilla } = useUser();

    useEffect(() => {
        fetch(Api + "usuarios.php?auto=" + categoria)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setVehiculos(responseData);
            })
            .catch(console.log)


    }, []);

    return (
        <Row>
            <Col sm>
                <span className='bar-Category'>
                    Unidad
                </span>
                <br />
                {(existsInPagFlotilla(pagFlotilla, 1, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 2, "Vehiculo") ||
                    existsInPagFlotilla(pagFlotilla, 4, "Vehiculo") || existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) && (
                        <>
                            {vehiculos.map((vehicle) => (
                                <>
                                    <a href={"/flotilla/vehiculo/"+vehicle.id}>
                                        <span className='bar-Subcategory' key={vehicle.id}>
                                            {vehicle.auto}
                                        </span>
                                    </a>
                                    <br />
                                </>
                            ))}
                        </>
                    )}
            </Col>
        </Row>
    );
}

export default Collapse_bar_flotilla;