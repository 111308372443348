import React from 'react';

import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading from '../../general/Loading_papu.js';
import { Link } from 'react-router-dom';

class List_vehicle_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        loadedData: false,
        users: []
    }

    loadData() {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                this.setState({ loadedData: true, users: responseData })
            })
            .catch(console.log)
    }

    componentDidMount() {
        this.loadData();
    }

    render() {

        {/* Retrieve loaded data */ }
        const { loadedData, users } = this.state

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla/>
                    <Loading></Loading>
                </div>
            );
        } else {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Vehículos disponibles</h2>

                    {/* Navigatión search */}
                    <div className="row">
                        <div className="col-lg-9">
                            <form className="form-inline my-2 my-lg-0">
                                <input className="form-control mr-sm-2" type="text"
                                    placeholder="Escribe un nombre o cualquier dato que referente a los encabezados de la tabla" />
                                <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Buscar</button>
                            </form>
                        </div>
                        <Link to={"/flotilla/vehiculo/acciones/agregar-nuevo-vehiculo"}>
                            Agrega un nuevo vehículo
                        </Link>
                        <div className="col-lg-3">
                        </div>
                    </div>

                    {/* Table of all users */}
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>ID</th>
                                <th>Marca</th>
                                <th>Modelo y año</th>
                                <th>Empresa</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(
                                (users) => (
                                    <tr key={users.id}>
                                        <td>{users.company.name}</td>
                                        <td>{users.id}</td>
                                        <td>{users.name}</td>
                                        <td>{users.company.name}</td>
                                        <td>{users.address.street}</td>
                                        <td>
                                            <div className="btn-group" role="group" aria-label="">
                                                <Link className="btn btn-warning" to={"/flotilla/vehiculo/acciones/editar-vehiculo"}>Editar</Link>
                                                <Link className="btn btn-danger">Borrar</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )
                            }

                        </tbody>
                    </table>
                </div>

            );
        }
    }
}

export default List_vehicle_flotilla;