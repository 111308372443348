import React from 'react';
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
    VictoryBar,
    VictoryAxis,
    VictoryChart
} from 'victory';
import es from "date-fns/locale/es";

import ReactDOM from 'react-dom';
import * as V from 'victory';

import Type_calendar from '../components/Type_Calendar';

registerLocale("es", es);

class Dashboard_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        startDate: null,
        finalDate: null
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    };

    handleFinalDateChange = (date) => {
        this.setState({ finalDate: date });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        // Lógica para enviar los datos del formulario
    };

    render() {
        const { startDate, finalDate } = this.state;
        const data = [
            { quarter: 1, earnings: 13000 },
            { quarter: 2, earnings: 16500 },
            { quarter: 3, earnings: 14250 },
            { quarter: 4, earnings: 19000 }
        ];

        return (
            <div className='container'>
                <Nav_bar_flotilla />
                <h2>Gráficos</h2>
                <div className='row'>
                    <form onSubmit={this.handleSubmit}>

                        <div class="col">

                            <label htmlFor="">Fecha de inicio</label>
                            <DatePicker
                                selected={startDate}
                                onChange={this.handleStartDateChange}
                                className="form-control"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                placeholderText="Seleccione una fecha y hora"
                                locale='es'
                            />

                        </div>
                        <div class="col">
                            <label htmlFor="">Fecha de termino</label>
                            <DatePicker
                                selected={finalDate}
                                onChange={this.handleFinalDateChange}
                                className="form-control"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                placeholderText="Seleccione una fecha y hora"
                                locale='es'
                            />
                        </div>
                        <div class="col">

                            <button type="submit" className="btn btn-primary">
                                Consultar periodo
                            </button>
                        </div>
                    </form>
                    <div className='row row-cols-2'>
                        <div className='col'>
                            <VictoryBar
                                data={data}
                                // data accessor for x values
                                x="quarter"
                                // data accessor for y values
                                y="earnings"
                            />
                        </div>
                        <div className='col'>
                            <VictoryChart
                                // domainPadding will add space to each side of VictoryBar to
                                // prevent it from overlapping the axis
                                domainPadding={20}
                            >
                                <VictoryAxis
                                    // tickValues specifies both the number of ticks and where
                                    // they are placed on the axis
                                    tickValues={[1, 2, 3, 4]}
                                    tickFormat={["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"]}
                                />
                                <VictoryAxis
                                    dependentAxis
                                    // tickFormat specifies how ticks should be displayed
                                    tickFormat={(x) => (`$${x / 1000}k`)}
                                />
                                <VictoryBar
                                    data={data}
                                    x="quarter"
                                    y="earnings"
                                />
                            </VictoryChart>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <VictoryBar
                                data={data}
                                // data accessor for x values
                                x="quarter"
                                // data accessor for y values
                                y="earnings"
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Gráfico</th>
                                        <th>Inserción</th>
                                        <th>Información</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Circular
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Anillo
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Barras
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Histograma
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Escala discontinua
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Barras horizontales
                                                    <br />
                                                    <input type="radio" class="form-check-input" name="" id="" value="checkedValue" checked />
                                                    Interpolación
                                                </label>

                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-group">
                                                <label for="">Leyenda</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Titulos de la categorías</small>
                                                <br />
                                                <label for="">Valores</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Valores dentro de la gráfica circular</small>
                                                <br />
                                                <label for="">Eje X</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Valores dentro del eje X</small>
                                                <br />
                                                <label for="">Eje Y</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Valores dentro del eje Y</small>
                                                <br />
                                                <label for="">Multiplos pequeños</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Esta opción permite desglosar una misma visualización en varias versiones de la misma</small>
                                                <br />
                                                <label for="">Información sobre herramienta</label>
                                                <input type="text"
                                                    class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
                                                <small id="helpId" class="form-text text-muted">Proporciona información contextual y detalles a los puntos de datos de un objeto visual.</small>
                                            </div>
                                        </td>
                                        <td>
                                            Take mi pollo malevolon
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard_flotilla;