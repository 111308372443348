//Librarys
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//Services
import Api from "@/services/api";

//Components
import Loading_papu from "@/components/general/Loading_papu";
import AnyTable from '@/components/Flotilla/components/ProcUser/AnyTable';

const ListDocument = ({ view }) => {
    // Inicializar 'variable' como un objeto con las claves para cada input
    const [loadedData, setLoadedData] = useState(false);
    const titulo = ['Nombre', 'Tipo', 'Fecha expedido', 'Fecha vigencia', 'Estado', 'Acción']; // Define los títulos de las columnas aquí

    const columnFunctions = [
        // Puedes añadir más funciones si tienes más columnas
        (item) => <td className='text-center'>
            {item.usuario_nombre_documento}
        </td>,
        (item) => <td>{item.usu_tipo_documento}</td>,
        (item) => <td>{item.usu_fecha_expedido}</td>,
        (item) => <td>{item.usu_fecha_vencimiento}</td>,
        (item) => <td>{item.usu_estado_documento}</td>,
        (item) => (
            <td>
                <div className="btn-group" role="group" aria-label="">
                    <Link
                        className="btn btn-warning"
                        to={'editar-documento/' + item.id_usu_doc}
                    >
                        Editar privilegios
                    </Link>
                    <button
                        className="btn btn-danger"
                        type='button'
                        onClick={(e) => {
                            e.preventDefault();
                            eliminateData(item.id_usu_doc, item.usuario_id_usuario);
                        }}
                    >Eliminar docummento</button>
                </div>
            </td>
        ),
    ];

    //Metodos
    const eliminateData = (id, user) => {
        // Preguntar al usuario si realmente desea eliminar el vehículo
        const confirmar = window.confirm("¿Realmente desea eliminar el documento?");
        if (confirmar) {
            fetch(Api + "/usuarios.php?id_usuario=" + user + "&borrar-doc=" + id)
                .then(response => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.success === 1) {
                        console.log(responseData);
                        window.alert('Documento eliminado');
                        window.location.reload();
                    } else {
                        window.alert('Error.\nEl documento no ha podido ser eliminado.');
                    }

                })
                .catch(console.log)

        } else {
            // Si el usuario hizo clic en "Cancelar" (No se hace nada)
        }
    }

    useEffect(() => {
        setLoadedData(true);
    }, [view]);


    if (loadedData) {

        return (
            <div className="card-body">
                <Row>
                    <Col>
                        <Link className="btn btn-success text-white" to={"crear-nuevo-documento/"}>
                            Agrega un nuevo documento
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AnyTable lt={titulo} lc={view} col={columnFunctions} />
                    </Col>
                </Row>

            </div>

        );
    } else {
        return (
            <div className='container'>
                <Loading_papu></Loading_papu>
            </div>
        );
    }
};
export default ListDocument;