//Librarys
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import InputInfoUser from '@/components/Flotilla/components/ProcUser/InputInfoUser';
import InputPrivUser from '@/components/Flotilla/components/ProcUser/InputPrivUser';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";
import { Link, useParams } from 'react-router-dom';

function User_priv() {
    //Variables externas
    const { id } = useParams(); // Obtenemos el valor "id" de la URL
    const { pagFlotilla, userID, } = useUser();
    //Variables internas
    const [inputValue, setInputValue] = useState('');
    const [check, setCheck] = useState('');
    const [loadedData, setLoadedData] = useState([false, false]);
    const [info, setInfo] = useState([]);
    const [priv, setPriv] = useState([]);
    const [datos, setDatos] = useState([]);
    const [datos2, setDatos2] = useState([]);
    const [estado, setEstado] = useState(true);

    const loadData = () => {
        fetch(Api + "usuarios.php/?listemp")
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setInfo(responseData.primera_consulta);
                setPriv(responseData.segunda_consulta);
                setLoadedData([true, loadedData[1]]);
            })
            .catch(console.log)
    };

    const loadData2 = () => {
        fetch(Api + "usuarios.php/?doc_import=" + id)
            .then(response => response.json())
            .then((responseData) => {
                //console.log(responseData);
                setDatos(responseData.tercera_consulta[0]);
                setDatos2(responseData.cuarta_consulta);
                setLoadedData([loadedData[0], true]);
            })
            .catch(console.log)
    };

    useEffect(() => {
        loadData();
        loadData2();
    }, []);

    // Metodos CallBack
    // Manejador de cambio para InputInfoUser
    const handleInfoChange = (value) => {
        setInputValue(value);
    };

    // Manejador de cambio para InputPrivUser
    const handlePrivChange = (value) => {
        setCheck(value);
    };

    const sendData = (e) => {

        e.preventDefault();
        
        console.log(inputValue); // Ahora inputValue contiene el valor del input del componente hijo
        console.log(check);     // Ahora check contiene el valor del input del componente hijo
        console.log("Orginal id_puesto: " + datos.id_pue);
        console.log("Original id_departamento: " + datos.id_depa);
        console.log("Original nom_empresa: " + datos.emp_nombre);
        
        if (!id) {
            window.alert("Error 69.\nUsuario inexistente.");
            return;
        }
        
        if (
            inputValue ||
            check
        ) {
            // Creación de variable que guardara los datos a enviar al servidor
            var formData = new FormData();
            formData.append("userID", userID);
            formData.append("idusuario", id);
            //Primer IF - Actualización de información del usuario
            if (inputValue &&
                userID &&
                id &&
                inputValue.nombre &&
                inputValue.apellidoPaterno &&
                inputValue.email &&
                inputValue.empresa &&
                inputValue.pass &&
                inputValue.jerarquia &&
                datos.id_empresa &&
                datos.id_pue &&
                datos.id_depa
            ) {
                
                if (inputValue.empresa == 'otros') {
                    if (inputValue.nombreEmpresa) {
                        // Valor del cuadro de texto nombre empresa - Selección 'Escriba la empresa'
                        formData.append("empresanueva", "0");
                        formData.append("empresa", inputValue.nombreEmpresa);
                    } else {
                        window.alert('Error.\nNo se ha podido cargar la empresa');
                        return;
                    }
                } else {
                    // Valor al seleccionar alguna empresa existente
                    formData.append("empresanueva", "1");
                    formData.append("empresa", inputValue.empresa);
                }
                // Verificación que exista Apellido Materno
                if (inputValue.apellidoMaterno) {
                    formData.append("apellidoMaterno", inputValue.apellidoMaterno);
                }
                // Seguimos guardando los demas valores
                formData.append("nombre", inputValue.nombre);
                formData.append("apellidoPaterno", inputValue.apellidoPaterno);
                formData.append("email", inputValue.email);
                formData.append("alias", inputValue.apodo);
                formData.append("pass", inputValue.pass);
                formData.append("jerarquia", inputValue.jerarquia);
                formData.append("oriEmpresa", datos.id_empresa);
                formData.append("oriPuesto", datos.id_pue);
                formData.append("oriDepa", datos.id_depa);
                formData.append("cambPuesto", inputValue.pue);
                formData.append("cambDepa", inputValue.depa);
                //Insercción API REST
                fetch(Api + "/usuarios.php?actusuario=1", {
                    method: "POST",
                    body: formData,
                }).then(response => response.json())
                    .then((responseDataArray) => {

                        console.log(responseDataArray);

                        let successMessages = '';
                        let errorMessages = '';

                        // Iterar sobre cada objeto en la respuesta
                        responseDataArray.forEach((responseItem) => {
                            if (responseItem.success == 1) {
                                successMessages += responseItem.message + '\n';
                            } else if (responseItem.success == 0) {
                                errorMessages += responseItem.message + '\n';
                            }
                        });

                        // Mostrar mensajes
                        if (successMessages) {
                            window.alert(successMessages);
                        }
                        if (errorMessages) {
                            window.alert('Lo lamentamos, no se ha podido realizar algunas operaciones:\n' + errorMessages);
                        }

                    }).catch(console.log);
            } // No lleva un else ya que existe la posibilidad de que solo quieran modificar los privilegios del usuario
            
            //Segundo IF - Actualización de privilegios del usuario
            if (
                check.length > 0
            ) {
                
                // Seguimos guardando los demas valores
                formData.append("privilegios", JSON.stringify(check));

                //Insercción API REST
                fetch(Api + "/usuarios.php?actusuariopriv=1", {
                    method: "POST",
                    body: formData,
                }).then(response => response.json())
                    .then((responseDataArray) => {

                        console.log(responseDataArray);

                        let successMessages = '';
                        let errorMessages = '';

                        // Iterar sobre cada objeto en la respuesta
                        responseDataArray.forEach((responseItem) => {
                            if (responseItem.success == 1) {
                                successMessages += responseItem.message + '\n';
                            } else if (responseItem.success == 0) {
                                errorMessages += responseItem.message + '\n';
                            }
                        });

                        // Mostrar mensajes
                        if (successMessages) {
                            window.alert(successMessages);
                        }
                        if (errorMessages) {
                            window.alert('Lo lamentamos, no se ha podido realizar algunas operaciones:\n' + errorMessages);
                        }

                    }).catch(console.log);
            }
            window.location.reload();
        } else {
            window.alert('Nota.\nNo modificado algún cuadro de texto o selector.');
            return;
        }
        
    };

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */
    if (existsInPagFlotilla(pagFlotilla, 1, "Control de usuarios") && datos.jerarquia != 'Root') {
        if (!loadedData[0] && !loadedData[1]) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {
            return (
                <Container className='Fluid'>
                    <Row>
                        <Nav_bar_flotilla></Nav_bar_flotilla>
                        <h2>Usuario:&nbsp;
                            {datos && (
                                <>
                                    {datos.nombre && `${datos.nombre} `}
                                    {datos.apaterno && `${datos.apaterno} `}
                                    {datos.amaterno && datos.amaterno}
                                </>
                            )}
                        </h2>

                    </Row>
                    <Row>
                        <form onSubmit={sendData}>
                            <Col>
                                <div className="card-columns">
                                    <div className="card">
                                        <div className="card-header">
                                            Información del usuario
                                        </div>
                                        <InputInfoUser disable={estado} onInputChange={handleInfoChange} data={info} view={datos} />
                                    </div>
                                    {datos.jerarquia != 'Root' &&
                                        (
                                            <div className="card">
                                                <div className="card-header">
                                                    Privilegios del usuario
                                                </div>
                                                <InputPrivUser disable={estado} onInputChange={handlePrivChange} data={priv} view={datos2} />
                                            </div>
                                        )
                                    }
                                    <div className="card">
                                        <div className="card-footer text-muted text-center">

                                            {estado ? (
                                                <div className="btn-group" role="group" aria-label="">
                                                    <button
                                                        onClick={() => setEstado(false)}
                                                        type="button"
                                                        className="btn btn-info">
                                                        Modificar información del usuario
                                                    </button>
                                                    <Link className="btn btn-danger text-white" to="/flotilla/control-de-usuarios">Regresar</Link>
                                                </div>
                                            ) : (
                                                <div className="btn-group" role="group" aria-label="">
                                                    <button type="submit"
                                                        className="btn btn-warning">
                                                        Actualizar información del usuario
                                                    </button>
                                                    <button
                                                        onClick={() => setEstado(true)}
                                                        type="button"
                                                        className="btn btn-danger">
                                                        Cancelar
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </form>
                    </Row>
                </Container>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Usted no tiene permitido estar en esta página</h2>
                <Error404></Error404>
            </div>
        );
    }
}
export default User_priv;