import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DatePicker from 'react-datepicker';


import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '../../general/Loading_papu';
import Car_Bar_Flotilla from '../components/Car_Bar_Flotilla';
import Form_calendar from '../components/Form_calendar';

class Calendar_flotilla extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        loadedData: false,
        vehicless: [],
        showForm: false
    }

    loadData() {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                this.setState({
                    loadedData: true,
                    vehicless: responseData
                })
            })
            .catch(console.log)
    }

    componentDidMount() {
        this.loadData();
    }

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            showForm: !prevState.showForm
        }));
    }

    render() {
        {/* Retrieve loaded data */ }
        const { loadedData, vehicless, showForm } = this.state

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {

            const localizer = momentLocalizer(moment);
            {/*
            {vehicless.map(
                    (vehicless) => (
                        {
                            start: new Date({vehicless.ano}, {vehicless.mes}, {vehicless.dia}, {vehicless.hora}, {vehicless.minuto}), // Meses en JavaScript comienzan desde 0 (0 = enero, 1 = febrero, etc.)
                            end: new Date({vehicless.ano}, {vehicless.mes}, {vehicless.dia}, {vehicless.hora}, {vehicless.minuto}),
                            title: '+{vehicless.titulo}+'
                        }
                    )
                )
                }
            */}
            const events = [

                {
                    start: new Date(2023, 7, 9, 13, 58), // Meses en JavaScript comienzan desde 0 (0 = enero, 1 = febrero, etc.)
                    end: new Date(2023, 7, 9, 14, 30),
                    title: 'Evento 1'
                }

            ];

            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Calendario</h2>

                    {/* Rigth here is the calendar */}
                    <div className='row'>
                        <div className='col-lg-12'>
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4>Eventos próximos</h4>
                        </div>
                        <div className="card-body">
                            {/* Rigth here is the famous table in da world*/}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Color</th>
                                        <th>Descripción</th>
                                        <th>Tipo</th>
                                        <th>Fecha de inicio</th>
                                        <th>Fecha de termino</th>
                                        <th>Vehículo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vehicless.map(
                                        (vehicless) => (
                                            <tr key={vehicless.id}>
                                                <td>{vehicless.company.name}</td>
                                                <td>{vehicless.name}</td>
                                                <td>{vehicless.id}</td>
                                                <td>{vehicless.company.name}</td>
                                                <td>{vehicless.address.street}</td>
                                                <td>{vehicless.name}</td>
                                            </tr>
                                        )
                                    )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="btn-group" data-toggle="buttons">
                        <label className="btn btn-primary">
                            <input type="checkbox" name="" id="" autoComplete="off" onChange={this.handleCheckboxChange} />
                            ¿Deseas agregar un nuevo evento?
                        </label>
                    </div>
                    {/*  
                    <div className="form-group">
                        <h3>Agrega un nuevo evento</h3>
                        <label for="">Titulo evento</label>
                        <input type="text" name="" id="" className="form-control" placeholder="" aria-describedby="helpId" />
                        <label for="">Fecha de inicio</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => this.setState({ startDate: date })}
                            className="form-control"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            placeholderText="Seleccione una fecha y hora"
                        />
                        <label for="">Fecha de termino</label>
                        <DatePicker
                            selected={finalDate}
                            onChange={(date2) => this.setState({ finalDate: date2 })}
                            className="form-control"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            placeholderText="Seleccione una fecha y hora"
                        />

                        <label for="">Asunto del evento</label>
                        <input type="text" name="" id="" className="form-control" placeholder="" aria-describedby="helpId" />
                        <label for="">Descripción del evento</label>
                        <textarea class="form-control" name="" id="" rows="3"></textarea>
                        <label for="">Descripción del evento</label>
                        <Car_Bar_Flotilla></Car_Bar_Flotilla>
                        <button type="submit" class="btn btn-primary">Agregar evento</button>
                    </div>
                    */}
                    {showForm && <Form_calendar />}
                </div>
            );
        }
    }
}

export default Calendar_flotilla;