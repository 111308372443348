import React, { Component } from "react";

class Car_Bar_Flotilla extends Component {
  state = {
    searchQuery: ""
  };

  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  render() {
    const { searchQuery } = this.state;

    const options = [
      "Nissan Frontier 2012",
      "Hyundai TLC 2008",
      "Mack Pinnacle 2011",
      "Chevrolet Silverado 2023"
    ];

    return (
      <div>
        
        <select
          className="form-control"
          value={searchQuery}
          onChange={this.handleSearchChange}
        >
          <option value="">Selecciona una opción...</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <p>Seleccionaste: {searchQuery}</p>
      </div>
    );
  }
}

export default Car_Bar_Flotilla;