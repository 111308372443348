// Librarys
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

// Services
import { useUser } from '@/services/UserContext';
import Api from "@/services/api";
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';

function Documents_flotilla() {
    // Variables externas
    const { pagFlotilla, userID, jerarquia } = useUser();
    const { id } = useParams(); // Obtenemos el valor "id" de la URL

    // Variables internas
    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);

    useEffect(() => {
        if (jerarquia == 'Root') {
            fetch(Api + "automovil-flotilla.php?viewdoc=" + id)
                .then(response => response.json())
                .then((responseData) => {
                    //console.log(responseData);
                    setLoadedData(true);
                    setDocumentss(responseData.primera_consulta);
                })
                .catch(console.log)
        } else {
            fetch(Api + "automovil-flotilla.php?viewdocper=" + id + "&id_u=" + userID)
                .then(response => response.json())
                .then((responseData) => {
                    //console.log(responseData);
                    setDocumentss(responseData);
                    setLoadedData(true);
                })
                .catch(console.log)
        }
    }, []);

    const [filters, setFilters] = useState({
        nombre: "",
        tipo: "",
        expedido: "",
        vencimiento: "",
        estado: ""
    });

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value
        });
    };

    // Utiliza un condicional para verificar si `documentss` es un arreglo antes de filtrar
    const filteredDocuments = documentss && Array.isArray(documentss) ? documentss.filter(doc => {
        return (
            (doc.document ? doc.document.includes(filters.nombre) : true) &&
            (doc.tipo_doc ? doc.tipo_doc.includes(filters.tipo) : true) &&
            (doc.fecha_expedido ? doc.fecha_expedido.includes(filters.expedido) : true) &&
            (doc.estado_doc ? doc.estado_doc.includes(filters.estado) : true) &&
            (doc.fecha_vencimiento ? doc.fecha_vencimiento.includes(filters.vencimiento) : true)
        );
    }) : [];
    



    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Reportes")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <h2>Documentos</h2>
                    <div className="card">
                        <div className="card-header">
                            {existsInPagFlotilla(pagFlotilla, 1, "Reportes") && (
                                <Link className="btn btn-success text-white" to={"/flotilla/documentos/" + id + "/crear"}>
                                    Agrega un nuevo documento del vehículo
                                </Link>)}
                        </div>
                        <div className="card-body">
                            <h4>Documentos disponibles</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        {documentss && (<tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "nombre")} placeholder='Filtro nombre' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "tipo")} placeholder='Filtro tipo' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "expedido")} placeholder='Filtro fecha de expedido' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "vencimiento")} placeholder='Filtro fecha de vigencia' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "estado")} placeholder='Filtro estado' /></th>
                                            <th></th>
                                        </tr>)}
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Tipo</th>
                                            <th>Fecha expedido</th>
                                            <th>Fecha vigencia</th>
                                            <th>Estado</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    {existsInPagFlotilla(pagFlotilla, 3, "Documentos") && (
                                        <tbody>
                                            {filteredDocuments.map((documentss) => (
                                                <tr key={documentss.id}>
                                                    <td>{documentss.document}</td>
                                                    <td>{documentss.tipo_doc}</td>
                                                    <td>{documentss.fecha_expedido}</td>
                                                    <td>{documentss.fecha_vencimiento}</td>
                                                    <td>{documentss.estado_doc}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/documentos/" + id + "/consultar/" + documentss.id_doc}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Consulta o crea tu Reporte Actual del Estado del Vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Documents_flotilla;