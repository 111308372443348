//Librarys
import { useParams, Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';

//Services
import { useUser } from '@/services/UserContext';
import { existsInPagFlotilla } from '@/components/Flotilla/components/Validador';
import Api from "@/services/api";

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Error404 from '@Components/general/Error404';
import Loading_papu from "@/components/general/Loading_papu";
import MapComponent from '@/components/Flotilla/components/ViajesUbi/MapComponent';


function Trips_flotilla() {

    const [loadedData, setLoadedData] = useState(false);
    const [documentss, setDocumentss] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    //Coordenadas EPSG:3857
    const [coordenadas, setCoordenadas] = useState([]);

    const { id } = useParams(); // Obtenemos el valor "id" de la URL
    const { pagFlotilla, userID } = useUser();

    const [filters, setFilters] = useState({
        numero: "",
        folio: "",
        titulo: "",
        creador: "",
        vehiculo: "",
        fechaCreacion: "",
        ubicacionA: "",
        fechaCreacion2: "",
        ubicacionB: ""
    });

    const handleFilterChange = (event, field) => {
        setFilters({
            ...filters,
            [field]: event.target.value
        });
    };

    const filteredDocuments = documentss.filter(doc => {
        return (
            doc.usuario.includes(filters.creador) &&
            doc.fecha_generadaA.includes(filters.fechaCreacion) &&
            doc.ubicacionA.includes(filters.ubicacionA) &&
            doc.fecha_generadaB.includes(filters.fechaCreacion2) &&
            doc.ubicacionB.includes(filters.ubicacionB)
        );
    });

    const loadData = () => {
        fetch(Api + "automovil-flotilla.php?consultatrip=" + userID + "&veh=" + id)
            .then(response => response.json())
            .then((responseData) => {
                console.log(responseData);
                setDocumentss(responseData.primera_consulta);
            })
            .catch(console.log)
    }

    useEffect(() => {
        loadData();
        setLoadedData(true);
        const fetchData = async () => {
            try {
                const response = await fetch(Api + "automovil-flotilla.php?coordenadas=" + id);
                const responseData = await response.json();
                // Procesando datos para 'ubicaciones'
                const ubicacionesActualizadas = responseData.segunda_consulta.map(item => item.lugares);
                setUbicaciones(ubicacionesActualizadas);

                const coordsPromises = ubicacionesActualizadas.map(async ubicacion => {
                    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${ubicacion}`);
                    const data = await response.json();
                    if (data[0]) {
                        return fromLonLat([parseFloat(data[0].lon), parseFloat(data[0].lat)], 'EPSG:3857');
                    }
                    return null;
                });

                const newCoords = await Promise.all(coordsPromises);
                console.log(newCoords);
                const filteredCoords = newCoords.filter(coord => coord !== null);

                setCoordenadas(filteredCoords);

            } catch (error) {
                // Manejar el error y mostrar un mensaje de alerta
                console.error('Error en la solicitud:', error);
                window.alert('Servicio de mapa no disponible por el momento');
            }
            
        };
        fetchData();
    }, []);

    // Privileges we are looking for
    /* It's CRUD but the DB is in this order
        *   1 = Insertar    / CREATE
        *   2 = Modificar   / UPDATE
        *   3 = Consultar   / READ
        *   4 = Eliminar    / DELETE
    */

    if (existsInPagFlotilla(pagFlotilla, 3, "Vehiculo")) {

        if (!loadedData) {
            return (
                <div className='container'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <Loading_papu></Loading_papu>
                </div>
            );
        } else {
            return (
                <div className='container-fluid'>
                    <Nav_bar_flotilla></Nav_bar_flotilla>
                    <div className="card">
                        <h2 className="card-title">Viajes y ubicaciones</h2>
                        <Row>
                            <MapComponent markers={coordenadas} />
                        </Row>
                        <div className="card-body">
                            <h4 className="card-title">Historial de ubicaciones</h4>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "numero")} placeholder='Filtro de numero' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "creador")} placeholder='Filtro de creador' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "ubicacionA")} placeholder='Filtro de ubicación' /></th>
                                            <th></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "fechaCreacion2")} placeholder='Filtro de fecha de creación' /></th>
                                            <th><input type="text" className='form-control' onChange={e => handleFilterChange(e, "ubicacionB")} placeholder='Filtro de ubicación' /></th>
                                        </tr>
                                        <tr>
                                            <th>Número</th>
                                            <th>Conductor asignado</th>
                                            <th>Fecha de creación</th>
                                            <th>Ubicación de inicio</th>
                                            <th>Estatus</th>
                                            <th>Fecha de creación</th>
                                            <th>Ubicación finalizada</th>
                                            <th>Estatus</th>
                                        </tr>
                                    </thead>
                                    {existsInPagFlotilla(pagFlotilla, 3, "Reportes") && (
                                        <tbody>
                                            {filteredDocuments.map((documentss, index) => (
                                                <tr key={documentss.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{documentss.usuario}</td>
                                                    <td>{documentss.fecha_generadaA}</td>
                                                    <td>{documentss.ubicacionA}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link className="btn btn-info" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'A'}>Consultar</Link>
                                                        </div>
                                                    </td>
                                                    <td>{documentss.fecha_generadaB}</td>
                                                    <td>{documentss.ubicacionB}</td>
                                                    <td>
                                                        {documentss.folio + 'B' === documentss.id_reporteB ? (
                                                            <div className="btn-group w-100" role="group" aria-label="">
                                                                <Link className="btn btn-info w-100" to={"/flotilla/reporte/tecnico/" + documentss.folio + 'B'}>Consultar</Link>
                                                            </div>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Viajes y ubicaciones</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Trips_flotilla;