//librarys
import React from 'react';
import { Link } from 'react-router-dom';

//Images
import error404 from "@/images/404.svg";

class Error404 extends React.Component {
    
    render() {
        return (
                <div className="card text-white text-center">
                    <br />
                    <h2 className="card-title text-danger">Advertencia</h2>
                    <p className="card-text text-dark">No cuenta con los privilegios necesarios.</p>
                    <img
                        src={error404}
                        className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                        alt="No se encuentra disponible la página."
                    />
                    <div className="card-body">
                        <div className="btn-group w-100" role="group" aria-label="">
                            <Link className="btn btn-primary " to={'/flotilla'}>Regresar</Link>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Error404;