//Images
import coche from "@/images/coche.png";

//Components
import Nav_bar_flotilla from '@/components/Flotilla/components/BarraNavegacion/Nav_bar_flotilla';
import Loading_papu from '@Components/general/Loading_papu';
import Error404 from '@Components/general/Error404';

//Librarys
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Services
import Api from "@/services/api";
import { useUser } from '@/services/UserContext';

const Vehicles_new_flotilla = () => {

    //Variables externas
    const { pagFlotilla } = useUser();
    const { userID } = useUser();

    // Variables internas
    const [selectedMarca, setSelectedMarca] = useState([]);
    const [marcaInput, setMarcaInput] = useState([]);
    const [modelo, setModelo] = useState([]);
    const [ano, setAno] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const [imagen, setImagen] = useState(null);
    const [odometro, setOdometro] = useState([]);
    const [placas, setPlacas] = useState([]);
    const [neconomico, setNeconomico] = useState([]);
    const [niv, setNiv] = useState([]);
    const [ltcombustible, setLtcombustible] = useState([]);
    const [tipocombustible, setTipocombustible] = useState([]);
    const [ccarga, setCcarga] = useState([]);
    const [mccarga, setMccarga] = useState([]);
    const [descripcion, setDescripcion] = useState([]);

    //Componente imagen Input
    const handleImageChange = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // Aquí guardamos el archivo directamente
        }
    };

    const InputImage = ({ nomVar, nomSet, img }) => {
        return (
            <td>
                {
                    nomVar ?
                        <img
                            src={URL.createObjectURL(nomVar)} // Creamos una URL temporal para mostrar la imagen
                            className="img-fluid rounded"
                            alt="Imagen cargada"
                        />
                        :
                        <img
                            src={img}
                            className="img-fluid rounded"
                            alt="No se ha podido cargar su imagen"
                        />
                }

                <input
                    type="file"
                    onChange={(e) => handleImageChange(e, nomSet)}
                    name="nomVar"
                    id="nomVar"
                    accept="image/*"
                    value={nomSet}
                    className={`form-control ${nomVar == null || nomVar == '' ? "is-invalid" : ""}`}
                />
            </td>
        );
    };

    const sendData = (e) => {
        e.preventDefault();

        /*
            console.log("Fabricante -> " + marcaInput);
            console.log("Modelo -> " + modelo);
            console.log("Año -> " + ano);
            console.log("Categoría -> " + categoria);
            console.log(imagen);
            console.log("Odometro -> " + odometro);
            console.log("Placas -> " + placas);
            console.log("Num Eco -> " + neconomico);
            console.log("NIV -> " + niv);
            console.log("Litros Combustible -> " + ltcombustible);
            console.log("Tipo Combustible -> " + tipocombustible);
            console.log("Capacidad carga -> " + ccarga);
            console.log("Medida Carga -> " + mccarga);
            console.log("Descripción -> " + descripcion);
            console.log("Id Usuario -> " + userID);
        */

        if (imagen == null || imagen == "") {
            window.alert("Agregue una imagen del Vehículo.");
            return;
        }

        if (
            userID &&
            marcaInput &&
            modelo &&
            ano &&
            categoria &&
            imagen &&
            odometro &&
            placas &&
            neconomico &&
            niv &&
            ltcombustible &&
            tipocombustible &&
            ccarga &&
            mccarga &&
            descripcion
        ) {
            var formData = new FormData();
            formData.append("marcaInput", marcaInput);
            formData.append("modelo", modelo);
            formData.append("ano", ano);
            formData.append("categoria", categoria);
            formData.append("odometro", odometro);
            formData.append("placas", placas);
            formData.append("neconomico", neconomico);
            formData.append("niv", niv);
            formData.append("ltcombustible", ltcombustible);
            formData.append("tipocombustible", tipocombustible);
            formData.append("ccarga", ccarga);
            formData.append("mccarga", mccarga);
            formData.append("descripcion", descripcion);
            formData.append("userID", userID);
            formData.append("imagen", imagen);
            formData.append("niv", niv);

            fetch(Api + "/automovil-flotilla.php?insertar=1", {
                method: "POST",
                body: formData,
            }).then(response => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.success === 1) {

                        window.alert('El vehículo ha sido añadido');
                        window.location.href = "/flotilla/procedimiento";

                    } else {
                        window.alert('La información del formulario no ha podido ser subida./nIntentelo de nuevo.');
                    }

                }).catch(console.log);

        } else {
            window.alert('Por favor, asegúrese de haber completado todos los campos requeridos.');
        }
            
    };

    // Page we are looking for
    let paginaBuscada = 'Vehiculo';
    // Page status (if we found it)
    let paginaEncontrada = false;

    //Searcher
    for (let i = 0; i < pagFlotilla.length; i++) {
        if (pagFlotilla[i][1] === paginaBuscada) {
            // We mark that the page was found
            paginaEncontrada = true;
            // We exit the loop once the page is found
            break;
        }
    }
    if (paginaEncontrada) {
        return (
            <div className='container'>
                <Nav_bar_flotilla></Nav_bar_flotilla>
                <h2>Agrega un nuevo vehículo</h2>
                <div className="card">
                    <div className="card-header">
                        <h4>Flotilla vehicular</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={sendData}>
                            <Row>
                                <Col sm={7}>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Fabricante</label>
                                                <input type="text" required
                                                    className="form-control" name="fabricante" id="fabricante" aria-describedby="helpId"
                                                    placeholder="Seleccione el fabricante o escríbalo"
                                                    value={marcaInput}
                                                    onChange={(e) => setMarcaInput(e.target.value)} />
                                                <small id="helpId" className="form-text text-muted">*Ingrese el fabricante del vehículo</small>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Puede seleccionar un fabricante</label>
                                                <select className="custom-select form-control" name="marcaSelect" id="marcaSelect"
                                                    value={marcaInput}
                                                    onChange={(e) => setMarcaInput(e.target.value)}>
                                                    <option value="">Seleccione un fabricante</option>
                                                    <option value="Altamirano">Altamirano</option>
                                                    <option value="Bobcat">Bobcat</option>
                                                    <option value="Chevrolet">Chevrolet</option>
                                                    <option value="Ford">Ford</option>
                                                    <option value="Fravaz">Fravaz</option>
                                                    <option value="Freightliner">Freightliner</option>
                                                    <option value="Fruehauf">Fruehauf</option>
                                                    <option value="General Motors">General Motors</option>
                                                    <option value="Hyundai">Hyundai</option>
                                                    <option value="Honda">Honda</option>
                                                    <option value="International">International</option>
                                                    <option value="JCB">JCB</option>
                                                    <option value="John Deere">John Deere</option>
                                                    <option value="Kenworth">Kenworth</option>
                                                    <option value="Load King">Load King</option>
                                                    <option value="Mack">Mack</option>
                                                    <option value="Mazda">Mazda</option>
                                                    <option value="Mini">Mini</option>
                                                    <option value="Mitsubishi">Mitsubishi</option>
                                                    <option value="Nissan">Nissan</option>
                                                    <option value="RAM">RAM</option>
                                                    <option value="Reinke">Reinke</option>
                                                    <option value="Renault">Renault</option>
                                                    <option value="TTSA">TTSA</option>
                                                    <option value="Toyota">Toyota</option>
                                                    <option value="Volkswagen">Volkswagen</option>
                                                    <option value="YTO">YTO</option>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    Seleccione el fabricante del vehículo (Opcional)
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Modelo</label>
                                                <input type="text" required
                                                    className="form-control" name="modelo" id="modelo" aria-describedby="helpId"
                                                    placeholder="Escriba el modelo del vehículo"
                                                    value={modelo}
                                                    onChange={(e) => setModelo(e.target.value)}
                                                />
                                                <small id="helpId" className="form-text text-muted">*Ingrese el modelo del vehículo</small>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Año</label>
                                                <input type="number" required
                                                    className="form-control" name="ano" id="ano" aria-describedby="helpId"
                                                    placeholder="Ingrese el año del vehículo" min={1930} max={2023} maxLength={4}
                                                    value={ano}
                                                    onChange={(e) => setAno(e.target.value)}
                                                />
                                                <small id="helpId" className="form-text text-muted">
                                                    *Ingrese el año del vehículo (entre 1930 y el año actual)
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label htmlFor="">Categoría</label>
                                                <select className="custom-select form-control" name="categoria"
                                                    id="categoria" required
                                                    value={categoria}
                                                    onChange={(e) => setCategoria(e.target.value)}>
                                                    <option value="">Seleccione una categoría</option>
                                                    <optgroup label="Vehículos ligeros">
                                                        <option value="Vehículo ligero">Vehículo ligero</option>
                                                        <option value="Compacto">Compacto</option>
                                                        <option value="Motocicleta">Motocicleta</option>
                                                        <option value="Pick-up">Pick-up</option>
                                                        <option value="SUV">SUV</option>
                                                        <option value="Sedan">Sedan</option>
                                                    </optgroup>
                                                    <optgroup label="Vehículos pesados">
                                                        <option value="Vehículo pesado">Vehículo pesado</option>
                                                        <option value="Camión">Camión</option>
                                                        <option value="Furgoneta">Furgoneta</option>
                                                        <option value="Maquinaria">Maquinaria</option>
                                                        <option value="Plataforma">Plataforma</option>
                                                        <option value="Remolque">Remolque</option>
                                                    </optgroup>
                                                    <option value="Otros">Otros</option>
                                                </select>
                                                <small id="helpId" className="form-text text-muted">
                                                    *Seleccione la categoría del vehículo
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm>
                                    <InputImage nomVar={imagen} nomSet={setImagen} img={coche} />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Odómetro o kilometraje actual</label>
                                        <input type="number"
                                            className="form-control" name="odometro" id="odometro" aria-describedby="helpId"
                                            placeholder="Escriba el kilometraje que a recorrido el vehículo" min="0" max="999999999999"
                                            value={odometro}
                                            onChange={(e) => setOdometro(e.target.value)}
                                            step={0.1} />
                                        <small id="helpId" className="form-text text-muted">Ingrese el kilometraje actual del vehículo. Número enteros máximo 1 decimal</small>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Placas vehiculares</label>
                                        <input type="text"
                                            className="form-control" asname="placas" id="placas" aria-describedby="helpId"
                                            placeholder="Escriba las siglas de las placas del vehículo" minLength={7} maxLength={10}
                                            value={placas}
                                            onChange={(e) => setPlacas(e.target.value)} />
                                        <small id="helpId" className="form-text text-muted">Ingrese los caracteres de las placas del vehículo (Si tiene)</small>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Número económico</label>
                                        <input type="number"
                                            className="form-control" name="neconomico" id="neconomico" aria-describedby="helpId"
                                            placeholder="Escriba el número económico" step={1} minLength={5} maxLength={6}
                                            value={neconomico}
                                            onChange={(e) => setNeconomico(e.target.value)} />
                                        <small id="helpId" className="form-text text-muted">Ingrese el número económico del vehículo</small>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">NIV</label>
                                        <input type="text"
                                            className="form-control" name="niv" id="niv" aria-describedby="helpId"
                                            placeholder="Escriba el número NIV del vehículo" minLength={10} maxLength={17}
                                            value={niv}
                                            onChange={(e) => setNiv(e.target.value)} />
                                        <small id="helpId" className="form-text text-muted">Ingrese el número NIV del vehículo</small>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Capacidad en el depósito de combustible</label>
                                        <input type="number" required
                                            className="form-control" value={ltcombustible} name="ltcombustible" id="ltcombustible"
                                            aria-describedby="helpId" placeholder="Escriba la capacidad en litros de combustibles máximos del vehículo"
                                            step={1} min={0} max={99999} onChange={(e) => setLtcombustible(e.target.value)} />
                                        <small id="helpId" className="form-text text-muted">*Ingrese la capacidad de combustible en litros del vehículo, si no consume escriba un 0</small>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Tipo de combustible</label>
                                        <select className="custom-select form-control" value={tipocombustible} name="tipocombustible" id="tipocombustible"
                                            onChange={(e) => setTipocombustible(e.target.value)} required>
                                            <option value="">Seleccione un combustible</option>
                                            <option value="No consume">No consume</option>
                                            <option value="Gasolina">Gasolina</option>
                                            <option value="Diesel">Diesel</option>
                                            <option value="Electrico">Eléctrico</option>
                                            <option value="Gas">Gas Natural Vehicular. GNV</option>
                                            <option value="Hibrido">Híbrido</option>
                                        </select>
                                        <small id="helpId" className="form-text text-muted">
                                            *Seleccione el tipo de combustible del vehículo
                                        </small>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Capacidad de carga</label>
                                        <input type="number"
                                            className="form-control" value={ccarga} name="ccarga" id="ccarga" aria-describedby="helpId"
                                            placeholder="Escriba la capacidad de carga del vehículo" step={1} min={0} max={9999999999}
                                            onChange={(e) => setCcarga(e.target.value)} required />
                                        <small id="helpId" className="form-text text-muted">
                                            *Ingrese la capacidad de carga del vehículo (Si no tiene registro indique un 0)
                                        </small>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Medida de capacidad de carga</label>
                                        <select className="custom-select form-control" required
                                            value={mccarga}
                                            name="mccarga"
                                            id="mccarga"
                                            onChange={(e) => setMccarga(e.target.value)}
                                        >
                                            <option value="">Seleccione una medida de capacidad de carga</option>
                                            <option value="1">Container o Contenedor</option>
                                            <option value="2">Gramos</option>
                                            <option value="3">Kilogramos</option>
                                            <option value="4">Toneladas</option>
                                            <option value="5">Libras</option>
                                            <option value="6">Onzas</option>
                                            <option value="7">Palé o Paleta</option>
                                            <option value="8">Quintal</option>
                                            <option value="9">Tonelada corta</option>
                                            <option value="10">Tonelada larga</option>
                                            <option value="11">Unidad no reconocida</option>
                                        </select>
                                        <small id="helpId" className="form-text text-muted">
                                            *Seleccione la medida de la capacidad del vehículo
                                        </small>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="">Descripción del vehículo</label>
                                        <textarea className="form-control" value={descripcion}
                                            name="descripcion" id="descripcion" rows="3"
                                            onChange={(e) => setDescripcion(e.target.value)}
                                            placeholder='Puede usted escribir alguna descripción del vehículo, plataforma u objeto' maxLength={300}></textarea>
                                    </div>
                                    <small id="helpId" className="form-text text-muted">
                                        Describa el vehículo
                                    </small>
                                </Col>
                            </Row>
                            <Row>
                                * Requerido
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success">Agregar</button>
                                        <Link className="btn btn-danger" to="/flotilla/procedimiento">Regresar</Link>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </div>
                    <div className="card-footer text-muted">

                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='container-fluid'>
                {/* Navigation bar for application web "Flotilla vehicular" */}
                < Nav_bar_flotilla ></Nav_bar_flotilla >
                <h2>Agrega un nuevo vehículo</h2>
                <Error404></Error404>
            </div>
        );
    }
}

export default Vehicles_new_flotilla;