import React, { useRef, useEffect } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Icon, Style } from 'ol/style';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import coche from "@/images/4397121.png";


const MapComponent = ({ markers }) => {
    const mapRef = useRef();

    useEffect(() => {
        const vectorSource = new VectorSource();
        markers.forEach(marker => {
            const iconFeature = new Feature({
                geometry: new Point(marker),
            });
            const iconStyle = new Style({
                image: new Icon({
                    anchor: [0.5, 0.5],
                    src: coche,  // Reemplaza con la URL de tu icono
                    scale: 0.1
                }),
            });

            iconFeature.setStyle(iconStyle);
            vectorSource.addFeature(iconFeature);
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const map = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                vectorLayer,
            ],
            view: new View({
                center: [-10991766.330136, 2281735.423113],  // Cambia a las coordenadas que desees
                zoom: 5,
            }),
        });

        return () => map.setTarget(undefined);
    }, [markers]);

    return (
        <div ref={mapRef} style={{ width: '100%', height: '100vh' }}></div>
    );
};

export default MapComponent;
