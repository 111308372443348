import React from 'react';
import cargando from "@/images/cargando.gif";
class Loading_papu extends React.Component {
    state = {}
    render() {
        return (
            <>
                <h1>Espere un momento</h1>
                <img src={cargando}
                    className="img-fluid ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
                    alt='Cargando...'
                />
                <h3>
                    Si persiste este error, envié el problema suscitado al siguiente correo.
                    <a className="d-flex align-self-bottom"
                        href="mailto:jesussandovalmontoya@gmail.com?Subject=Existe%20un%20problema"
                        target="_blank"
                        rel="noreferrer noopener"
                    >CLICK AQUÍ</a>
                </h3>
            </>
        );
    }
}

export default Loading_papu;