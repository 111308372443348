//Components
import Api from "@/services/api";

//Images
import fondo from "@/images/fondopdf.jpeg";

//Librarys
import React, { useEffect, useState } from 'react';
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';


// Asume que el componente de clase está en el archivo "Vehicle_new_flotilla.js"

const PDF_vehicle = ({ id }) => {
    // Variables internas
    const [loadedData, setLoadedData] = useState(false);
    const [vehiculo, setVehiculo] = useState({});
    //Variables externas
    const fechaYHora = getDateTime();

    useEffect(() => {
        // El código aquí se ejecuta cuando el componente se monta
        //console.log('El componente se montó');
        // console.log(id);
        fetch(Api + "automovil-flotilla.php/?consultar=" + id)
            .then(response => response.json())
            .then((responseData) => {
               //console.log(responseData);
               setVehiculo(responseData); 
               setLoadedData(true);
            })
            .catch(console.log)
        return () => {
            // El código aquí se ejecuta cuando el componente se desmonta
            console.log('El componente se desmontó');
        };
    }, []); // El segundo argumento, un array vacío [], indica que este efecto se ejecutará solo una vez, como componentDidMount


    if (!loadedData) {
        return (
            <Document>
                <Page size="A4">
                    <View className='container'>
                        <Text>
                            Cargando
                        </Text>
                    </View>
                </Page>
            </Document>
        );
    } else {
        // Estilos personalizados para el PDF
        const styles = StyleSheet.create({
            page: {
                flexDirection: "column", // Asegura que la imagen de fondo se extienda por toda la página
                width: '100%', // Ajusta el tamaño de la imagen de fondo
                height: '100%',
                position: 'relative',
            },
            headerText: {
                fontSize: 26,
            },
            label: {
                fontWeight: "bold",
                fontSize: 12,
            },
            text: {
                fontSize: 10,
                fontWeight: "light",
            },
            image: {
                width: '100%',
                height: '100%',
                position: 'absolute', // Ajusta la posición de la imagen
                top: 0, // Coloca la imagen en la parte superior izquierda
                left: 0,
                zIndex: -1, // Coloca la imagen detrás de los elementos de texto
            },
            container: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent', // Fondo transparente para ver la imagen de fondo
            },
            card: {
                marginLeft: "5%",
                marginRight: "5%",
            },
            cardheader: {
                top: "5%",
                width: "100%",
                textAlign: "right",
            },
            imgvehicle: {
                width: "100%",
                height: "40vw",
            },
            row: {
                flexDirection: 'row', // Esto establece la dirección de las columnas
                marginBottom: 10, // Espacio entre filas (ajusta según tus necesidades)
            },
            col: {
                flex: 1, // Esto permite que las columnas se ajusten automáticamente
                marginRight: 5, // Espacio entre columnas (ajusta según tus necesidades)
            },
            cardbody: {
                top: "15%",
            },
        });
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.container}>
                        <Image
                            style={styles.image}
                            src={fondo}
                        />
                        <View style={styles.card}>
                            <View style={styles.cardheader}>
                                <Text style={styles.headerText}>Información general del vehículo</Text>
                            </View>
                            <View style={styles.cardheader}>
                                <Text style={styles.headerText}>{vehiculo.primera_consulta[0].vehiculo}</Text>
                            </View>
                            <View style={styles.cardbody}>
                                <View style={styles.row}>
                                    <View style={styles.col}></View>
                                    <View style={styles.col}></View>
                                    <View style={styles.col}>
                                        <View>
                                            <Text style={styles.label}>Folio:
                                                <Text style={styles.text}>&nbsp;{id}</Text>
                                            </Text>
                                        </View>
                                        <View>
                                            <Text
                                                style={styles.label}
                                            >Fecha:
                                                <Text style={styles.text}>&nbsp;{fechaYHora}</Text>
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Fabricante</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].fabricante}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Modelo</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].modelo}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Año</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].ano}</Text>)
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Vehículo</Text>)
                                        <Image
                                            style={styles.imgvehicle}
                                            // Servidor/Carpeta_vehiculo/N_economico/ruta
                                            src={process.env.REACT_APP_LINK_RUTA_IMG_VEHICULO 
                                                + vehiculo.primera_consulta[0].neconomico + "/" + vehiculo.primera_consulta[0].ruta}
                                            
                                        />
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Categoría</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].categoria}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Placas vehiculares</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].placas}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >NIV</Text>
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].niv}</Text>
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Odómetro</Text>
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].odometro}</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Número económico</Text>
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].neconomico}</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Estado de disponibilidad</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].estado}</Text>)
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Descripción del estado de disponibilidad</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.segunda_consulta[0].veh_disponibilidad_descripcion}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Capacidad de carga</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].ucapacidad}</Text>)
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Capacidad en el depósito de combustible (Litros)</Text>)
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].ccombustible}</Text>)
                                    </View>
                                    <View style={styles.col}>
                                        <Text
                                            style={styles.label}
                                        >Tipo de combustible</Text>)
                                        {vehiculo.primera_consulta[0].tcombustible === 'No consume' ? (
                                            <Text
                                                style={styles.text}
                                            >No consume</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === 'Gasolina' ? (
                                            <Text
                                                style={styles.text}
                                            >Gasolina</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === 'Diesel' ? (
                                            <Text
                                                style={styles.text}
                                            >Diesel</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === 'Electrico' ? (
                                            <Text
                                                style={styles.text}
                                            >Eléctrico</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === 'Gas' ? (
                                            <Text
                                                style={styles.text}
                                            >Gas Natural Vehicular. GNV</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === 'Hibrido' ? (
                                            <Text
                                                style={styles.text}
                                            >Híbrido</Text>
                                        ) : vehiculo.primera_consulta[0].tcombustible === '' ? (
                                            <Text
                                                style={styles.text}
                                            >Seleccione un combustible</Text>
                                        ) : null}
                                    </View>
                                    <View className="col">
                                        <Text
                                            style={styles.label}
                                        >Medida de capacidad de carga</Text>)
                                        {vehiculo.primera_consulta[0].mcapadacidad === 't' || vehiculo.primera_consulta[0].mcapadacidad === 'T' ? (
                                            <Text
                                                style={styles.text}
                                            >Tonelada</Text>
                                        ) : vehiculo.primera_consulta[0].mcapadacidad === 'Kg' || vehiculo.primera_consulta[0].mcapadacidad === 'kg' ? (
                                            <Text
                                                style={styles.text}
                                            >Kilogramo</Text>
                                        ) : null}
                                    </View>
                                </View>
                                <View className="row">
                                    <View className="col-8">
                                        <Text
                                            style={styles.label}
                                        >Descripción del vehículo</Text>
                                        <Text
                                            style={styles.text}
                                        >{vehiculo.primera_consulta[0].descripcion}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}
export default PDF_vehicle;


// Función de obtención de Fecha y Hora
function getDateTime() {
    const now = new Date();
    const año = now.getFullYear();
    const mes = String(now.getMonth() + 1).padStart(2, '0');
    const dia = String(now.getDate()).padStart(2, '0');
    const hora = String(now.getHours()).padStart(2, '0');
    const minutos = String(now.getMinutes()).padStart(2, '0');
    const segundos = String(now.getSeconds()).padStart(2, '0');

    const fechaYHora = `${año}-${mes}-${dia} ${hora}:${minutos}:${segundos}`;

    return fechaYHora;
}